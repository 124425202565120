<template>
  <v-card
    class="mx-auto disable-max-width"
    max-width="344"
    outlined
  >
    <!-- Use v-row and v-col to create a flexible layout inside the card -->
    <v-row no-gutters>
      <!-- Image Column -->
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
      >
        <v-img
          :src="resolvedImage"
          height="200px"
          class="white--text align-end mobile-hide"
          gradient="to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.5)"
        ></v-img>
      </v-col>

      <!-- Content Column -->
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
      >
        <v-card-title>{{ $t(title) }}</v-card-title>
        <v-card-actions>
          <v-btn
            color="primary"
            :href="link"
            outlined
          >
            {{ $t('Заказать') }}
          </v-btn>
          <v-btn
            v-if="isScheduleExist === true"
            color="secondary"
            outlined
            :href="scheduleLink"
          >
            {{ $t('Подать график') }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    image: String,
    link: String,
    isScheduleExist: Boolean,
    scheduleLink: String,
  },
  computed: {
    resolvedImage() {
      // Assuming the images are stored in 'src/assets/images/travel-icons/'
      // Adjust the path according to your project structure
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/images/travel-icons/${this.image}`)
    },
  },
}
</script>

<style scoped>
@media (max-width: 767px) {
  .fixed-height {
    height: 200px;
  }
  .mobile-hide {
    display: none;
  }
  .disable-max-width {
    max-width: none !important;
  }
}
</style>
