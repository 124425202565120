<template>
  <section id="faq">
    <v-card-text class="question-section">
      <v-row>
        <v-col
          v-for="subject in subjects"
          :key="subject.title"
          md="10"
          offset-md="2"
          cols="12"
          class="mx-auto mb-8"
        >
          <div class="faq-title d-flex align-center">
            <v-avatar
              color="secondary"
              rounded
              size="40"
              class="v-avatar-light-bg"
            >
              <v-icon
                size="24"
                class="text--primary"
              >
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </v-avatar>

            <div class="ms-4">
              <h2 class="text--primary text-xl font-weight-medium">
                {{ subject.title }}
              </h2>
              <p class="mb-0">
                {{ subject.subtitle }}
              </p>
            </div>
          </div>
          <!-- v-if="question.subject === subject.url" -->
          <div class="faq-question-answers mt-6">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="question in questions.filter(question => question.subject === subject.url)"
                :key="question.title"
              >
                <v-expansion-panel-header class="text-base">
                  {{ question.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ question.text }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="text-center">
      <p class="text-xl font-weight-medium text--primary mb-1">
        {{$t('У вас есть вопросы?')}}
      </p>
      <p>{{$t('Если вы не можете найти ответ на вопрос, вы всегда можете связаться с нами. Мы ответим вам в ближайшее время!')}}</p>
    </v-card-text>

    <v-row class="mx-auto">
      <v-col
        v-for="(contact,index) in contactDetails"
        :key="contact.icon"
        cols="12"
        sm="6"
        md="5"
        :class="index > 0 ? 'me-auto': 'ms-auto'"
      >
        <v-card
          class="faq-contact text-center pa-5"
          flat
        >
          <v-avatar
            rounded
            color="secondary"
            size="38"
            class="v-avatar-light-bg mb-6"
          >
            <v-icon
              class="text--primary"
              size="24"
            >
              {{ contact.icon }}
            </v-icon>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            {{ contact.contact }}
          </p>

          <p class="text-sm mb-0">
            {{ $t(contact.tagLine) }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mdiHelpCircleOutline, mdiPhone, mdiEmailOutline } from '@mdi/js'

import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  setup() {
    const contactDetails = [
      {
        icon: mdiPhone,
        contact: '+ (812) 640 02 28',
        tagLine: 'Мы всегда рады помочь!',
      },
      {
        icon: mdiEmailOutline,
        contact: 'info@avangard-travel.ru',
        tagLine: 'Лучший способ получить ответ быстрее!',
      },
    ]

    const fetchHelp = () => {
      store.dispatch('help/fetchSubjects')
      store.dispatch('help/fetchQuestions')
    }
    fetchHelp()

    return {
      contactDetails,
      icons: { mdiHelpCircleOutline },
      subjects: computed(() => store.getters['help/getSubjects']),
      questions: computed(() => store.getters['help/getQuestions']),
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/faq.scss';
</style>
