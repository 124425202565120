<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="user-edit-info pa-3">
        <v-card-title>
          {{ $t('История платежей') }}
        </v-card-title>
        <v-data-table
          :headers="headers"
          :no-data-text="$t('Платежи отсутствуют')"
          :items="payments.filter((payment) => payment.status !== 'FORM_OPENED')"
          :items-per-page="15"
          item-key="payment_id"
        >
          <template #[`item.payment_date`]="{item}">
            {{ formatToLocalDatetime(item.payment_date) }}
          </template>

          <template #[`item.amount`]="{item}">
            {{ `${item.amount} ₽` }}
          </template>

          <template #[`item.vouchers`]="{item}">
            <span v-if="item.vouchers.length !== 0">
              {{ `${item.vouchers}` }}
            </span>
            <span v-else>–</span>
          </template>

          <template #[`item.orders`]="{item}">
            <span v-if="item.orders.length !== 0">
              {{ `${item.orders}` }}
            </span>
            <span v-else>–</span>
          </template>

          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="statusColor[status[item.status]]"
              :class="`${statusColor[status[item.status]]}--text`"
              class="v-chip-light-bg"
            >
              {{ status[item.status] }}
            </v-chip>
          </template>

          <template #[`item.pdf_invoice`]="{item}">
            <a
              v-if="item.payment_type === 'invoice'"
              :href="'https://backrequests.atg-service.info/media/' + item.pdf_invoice"
              target="_blank"
            >
              <v-icon size="20">
                {{ icons.mdiDownload }}
              </v-icon>
            </a>
          </template>

          <template #[`item.certificate_of_completion`]="{item}">
            <a
              v-if="item.payment_type === 'invoice' && item.status === 'COMPLETE'"
              :href="'https://backrequests.atg-service.info/media/' + item.certificate_of_completion"
              target="_blank"
            >
              <v-icon size="20">
                {{ icons.mdiDownload }}
              </v-icon>
            </a>
            <span
              v-if="item.payment_type === 'invoice' && item.status != 'COMPLETE'"
            >
              —
            </span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { computed } from '@vue/composition-api'
import { formatToLocalDatetime } from '@/utils/filter'
import { mdiDownload } from '@mdi/js'

export default {
    setup() {
        const statusColor = {
            'В процессе': 'info',
            'Ожидает подтверждения': 'info',
            Готово: 'success',
            Ошибка: 'error',
            'Счет выставлен': 'info',

        }
        const getPaymentsHistory = () => {
            const userData = JSON.parse(localStorage.getItem('userData'))
            store.dispatch('payments/getPaymentsHistory', userData.login)
        }
        getPaymentsHistory()

return {
            payments: computed(() => store.getters['payments/getPayments']),
            headers: computed(() => {
                const locale = localStorage.getItem('locale') || 'ru'

                if (locale === 'en') {
                    return [
                        { text: 'Payment ID', value: 'payment_id' },
                        { text: 'Vouchers', value: 'vouchers' },
                        { text: 'Orders', value: 'orders' },
                        { text: 'Payment type', value: 'payment_type' },
                        { text: 'Payment date', value: 'payment_date' },
                        { text: 'Amount', value: 'amount' },
                        { text: 'Status', value: 'status' },
                        { text: 'Bill', value: 'pdf_invoice' },
                        { text: 'Act', value: 'certificate_of_completion' },
                    ]
                } if (locale === 'ru') {
                    return [
                        { text: 'Номер платежа', value: 'payment_id' },
                        { text: 'Ваучеры', value: 'vouchers' },
                        { text: 'Заказы', value: 'orders' },
                        { text: 'Способ оплаты', value: 'payment_type' },
                        { text: 'Время и дата оплаты', value: 'payment_date' },
                        { text: 'Сумма', value: 'amount' },
                        { text: 'Статус', value: 'status' },
                        { text: 'Счет', value: 'pdf_invoice' },
                        { text: 'Акт', value: 'certificate_of_completion' },
                    ]
                } if (locale === 'zh') {
                    return [
                        { text: '付款编号', value: 'payment_id' },
                        { text: '凭证', value: 'vouchers' },
                        { text: '订单', value: 'orders' },
                        { text: '付款方式', value: 'payment_type' },
                        { text: '付款日期', value: 'payment_date' },
                        { text: '金额', value: 'amount' },
                        { text: '状态', value: 'status' },
                        { text: '账单', value: 'pdf_invoice' },
                        { text: '完成证书', value: 'certificate_of_completion' },
                    ]
                } if (locale === 'vi') {
                    return [
                        { text: 'ID thanh toán', value: 'payment_id' },
                        { text: 'Phiếu mua hàng', value: 'vouchers' },
                        { text: 'Đơn hàng', value: 'orders' },
                        { text: 'Hình thức thanh toán', value: 'payment_type' },
                        { text: 'Ngày thanh toán', value: 'payment_date' },
                        { text: 'Số lượng', value: 'amount' },
                        { text: 'Trạng thái', value: 'status' },
                        { text: 'Hóa đơn', value: 'pdf_invoice' },
                        { text: 'Chứng chỉ hoàn thành', value: 'certificate_of_completion' },
                    ]
                } if (locale === 'fa') {
                    return [
                        { text: 'شناسه پرداخت', value: 'payment_id' },
                        { text: 'کوپن', value: 'vouchers' },
                        { text: 'سفارشات', value: 'orders' },
                        { text: 'نوع پرداخت', value: 'payment_type' },
                        { text: 'تاریخ پرداخت', value: 'payment_date' },
                        { text: 'میزان', value: 'amount' },
                        { text: 'وضعیت', value: 'status' },
                        { text: 'صورتحساب', value: 'pdf_invoice' },
                        { text: 'گواهی کامل', value: 'certificate_of_completion' },
                    ]
                }
            }),
            status: {
                PROCESSING: 'В процессе',
                WAITING_FOR_3DS: 'Ожидает подтверждения',
                COMPLETE: 'Готово',
                FAILED: 'Ошибка',
                INVOICE_CREATED: 'Счет выставлен',
            },
            statusColor,
            formatToLocalDatetime,
            icons: {
                mdiDownload,
            },
        }
    },
}
</script>
