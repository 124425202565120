<template>
  <v-menu
    v-if="true"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="$t('Выберите дату')"
        outlined
        readonly
        hide-details="auto"
        :disabled="disabled"
        class="mt-0"
        dense
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <div v-if="getFormatedLocale() === 'ru-ru'">
      <v-date-picker
        v-model="date"
        no-title
        color="primary"
        :min="onPage === 'new-request-date' ? new Date().toISOString().substr(0, 10) : new Date(1995,7,3).toISOString().substr(0, 10) "
        :locale="getFormatedLocale()"
        first-day-of-week="1"
        :weekday-format="getDay"
        :allowed-dates="allowedDates"
        @input="dateMenu = false"
        @change="emitChanges"
      ></v-date-picker>
    </div>
    <div v-else>
      <v-date-picker
        v-model="date"
        no-title
        color="primary"
        :min="onPage === 'new-request-date' ? new Date().toISOString().substr(0, 10) : new Date(1995,7,3).toISOString().substr(0, 10) "
        :locale="getFormatedLocale()"
        :allowed-dates="allowedDates"
        first-day-of-week="1"
        @input="dateMenu = false"
        @change="emitChanges"
      ></v-date-picker>
    </div>
  </v-menu>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    onPage: {
      type: String,
      required: true,
    },
    dayOff: {
        type: Number,
        default: null,
        required: false,
    },
    dateValue: {
      type: String,
      required: false,
      default: new Date().toISOString().substr(0, 10),
    },
  },
  setup(props, { emit }) {
    const date = ref(props.dateValue)
    const dayOff = ref(props.dayOff)
    const dateMenu = ref(false)
    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}.${month}.${year}`
    }
    const getDay = currentDate => {
      const i = new Date(currentDate).getDay(currentDate)

      return daysOfWeek[i]
    }

    let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

    const getFormatedLocale = () => {
        const locale = localStorage.getItem('locale') || 'ru'
        if (locale === 'zh') {
            return 'zh-cn'
        } if (locale === 'en') {
            return 'en-us'
        } if (locale === 'ru') {
            return 'ru-ru'
        } if (locale === 'fa') {
            return 'fa-ir'
        } if (locale === 'vi') {
            return 'vi-vn'
        }

return 'ru-ru'
    }

    const allowedDates = val =>
       new Date(val).getDay() !== dayOff.value

    const parseDate = dates => {
      if (!dates) return null
      const [month, day, year] = dates.split('.')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }

    const computedDateFormatted = computed(() => formatDate(date.value))

    watch(date, () => {
      dateFormatted = formatDate(date.value)
    })

    const emitChanges = () => {
      emit('newDate', { date: date.value, onPage: props.onPage })
    }

    return {
      date,
      dateFormatted,
      dateMenu,
      computedDateFormatted,
      parseDate,
      formatDate,
      icons: {
        mdiCalendar,
      },
      allowedDates,
      emitChanges,
      getDay,
      getFormatedLocale,
    }
  },
}
</script>
