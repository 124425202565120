<template>
  <v-row>
    <v-dialog
      v-model="isInvoiceModalOpen"
      max-width="650px"
      persistent
    >
      <v-card
        class="user-edit-info pa-sm-10 pa-3"
      >
        <v-card-title class="justify-center text-h5">
          {{ $t('Cчет успешно сформирован!') }}
        </v-card-title>
        <v-card-text class="text-center mt-2">
          {{ $t('Вы можете открыть его нажав кнопку ниже') }}
        </v-card-text>

        <v-card-text class="mt-5">
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-center mt-3"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  type="submit"
                  target="_blank"
                  :href="invoiceLink"
                >
                  {{ $t('Открыть счет') }}
                  <v-icon
                    dark
                    right
                  >
                    {{ mdiOpenInNew }}
                  </v-icon>
                </v-btn>
                <v-btn
                  outlined
                  color="secondary"
                  @click.prevent="onPaymentsHistory"
                >
                  {{ $t('История платежей') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-col
      cols="12"
      md="8"
    >
      <v-card
        style="border-radius: 0px;"
        class="pb-2 mb-4"
        variant="outlined"
      >
        <v-card-title class="mb-4">
          Уважаемые пользователи!
        </v-card-title>
        <v-card-subtitle>
          С радостью сообщаем вам о новом обновлении нашего сервиса. Теперь, после успешной оплаты выставленного счета, вы можете немедленно скачать акт к вашей транзакции прямо со страницы <a href="/payments-history">истории платежей.</a>
        </v-card-subtitle>
        <v-card-text>
          Это удобное обновление позволит вам быстро получать официальные документы, подтверждающие ваши финансовые операции. Процесс получения акта стал еще более быстрым и эффективным, предоставляя вам мгновенный доступ к важной информации.
        </v-card-text>
        <v-card-text v-if="edoData.edo_identifier != null">
          Информируем вас о нашей возможности проводить обмен актами через эффективную систему ЭДО (электронного документооборота). Для вашего удобства, наш идентификатор в системе ЭДО составляет: {{ edoData.edo_identifier }}. Также, не менее удобным способом будет использование ИНН: {{ edoData.inn }}, чтобы найти нас в системе.
        </v-card-text>
      </v-card>
      <v-card
        flat
        style="border-radius: 0px;"
        class="pb-2"
      >
        <v-card-title>{{ $t('Оплата заявок') }}</v-card-title>
      </v-card>

      <v-tabs
        v-model="currentTab"
        grow
      >
        <v-tab
          key="Физическим лицам"
        >
          {{ $t('Физическим лицам') }}
        </v-tab>
        <v-tab
          key="Юридическим лицам"
        >
          {{ $t('Юридическим лицам') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item
          key="Физическим лицам"
        >
          <v-card flat>
            <v-card-title></v-card-title>
            <v-form
              ref="payForm"
              v-model="isValid"
              lazy-validation
            >
              <v-card-text>
                <p class="mb-4">
                  {{ $t('Для оплаты введите номера ваучеров или заявок и укажите точную сумму для оплаты:') }}
                </p>
                <v-textarea
                  v-model="paymentData.vouchers"
                  type="text"
                  outlined
                  rows="3"
                  dense
                  required
                  :rules="[v => !!v || $t('Введите номера ваучеров или заявок для оплаты')]"
                  class="mt-2 mb-6"
                  :label="$t('Номера ваучеров или заявок для оплаты (через запятую)')"
                  :placeholder="$t('Номера ваучеров или заявок для оплаты (через запятую)')"
                  hide-details="auto"
                ></v-textarea>

                <v-text-field
                  v-model="paymentData.name"
                  type="text"
                  :rules="[v => !!v || $t('Введите ФИО или название организации')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('ФИО или название организации')"
                  :placeholder="$t('ФИО или название организации')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.email"
                  type="text"
                  outlined
                  dense
                  class="mt-2 mb-6"
                  :label="$t('Email')"
                  :rules="[v => !!v || $t('Введите email'), validators.emailValidator]"
                  :placeholder="$t('Введите ваш email')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.total_price"
                  type="text"
                  outlined
                  dense
                  :rules="[v => !!v || $t('Введите сумму к оплате'), validators.totalPricePositiveValidator]"
                  required
                  class="mt-2 mb-6"
                  :label="$t('Сумма к оплате')"
                  :placeholder="$t('Введите сумму к оплате')"
                  hide-details="auto"
                ></v-text-field>

                <v-checkbox
                  id="policy"
                  key="policy"
                  v-model="paymentData.agree_policy"
                  hide-details
                  class="mt-2 mb-6"
                >
                  <template #label>
                    {{ $t('Согласен с договором оферты') }}
                  </template>
                </v-checkbox>
              </v-card-text>
              <!-- :disabled="!paymentData.agree_policy && !isValid" -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="submitIsDisable"
                  @click="payOrder"
                >
                  {{ $t('Перейти к оплате') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-tab-item>
        <v-tab-item
          key="Юридическим лицам"
        >
          <v-card flat>
            <v-card-title>
            </v-card-title>
            <v-form
              ref="payForm"
              v-model="isValid"
              lazy-validation
            >
              <v-card-text>
                <p class="mb-4">
                  {{ $t('Для формирования счета на оплату введите номера ваучеров или заказов, укажите реквизиты компании и точную сумму:') }}
                </p>
                <v-textarea
                  v-model="paymentData.vouchers"
                  type="text"
                  outlined
                  rows="3"
                  dense
                  required
                  :rules="[v => !!v || $t('Введите номера ваучеров или заявок для оплаты')]"
                  class="mt-2 mb-6"
                  :label="$t('Номера ваучеров или заявок для оплаты (через запятую)')"
                  :placeholder="$t('Номера ваучеров или заявок для оплаты (через запятую)')"
                  hide-details="auto"
                ></v-textarea>

                <v-text-field
                  v-model="paymentData.name"
                  type="text"
                  :rules="[v => !!v || $t('Название организации')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('Название организации')"
                  :placeholder="$t('Название организации')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.inn"
                  type="text"
                  :rules="[v => !!v || $t('ИНН')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('ИНН')"
                  :placeholder="$t('ИНН')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.kpp"
                  type="text"
                  :rules="[v => !!v || $t('КПП')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('КПП')"
                  :placeholder="$t('КПП')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.adress"
                  type="text"
                  :rules="[v => !!v || $t('Юридический адрес')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('Юридический адрес')"
                  :placeholder="$t('Юридический адрес')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.bank_account"
                  type="text"
                  :rules="[v => !!v || $t('Расчетный счет')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('Расчетный счет')"
                  :placeholder="$t('Расчетный счет')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.bank"
                  type="text"
                  :rules="[v => !!v || $t('Банк')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('Банк')"
                  :placeholder="$t('Банк')"
                  hint="Полное название банка (для формирования акта). Например: Филиал 'Центральный' Банка ВТБ (ПАО)."
                  persistent-hint
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.bik"
                  type="text"
                  :rules="[v => !!v || $t('БИК банка')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('БИК банка')"
                  :placeholder="$t('БИК банка')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.correspondent_account"
                  type="text"
                  :rules="[v => !!v || $t('Корреспондентский счет')]"
                  outlined
                  dense
                  required
                  class="mt-2 mb-6"
                  :label="$t('Корреспондентский счет')"
                  :placeholder="$t('Корреспондентский счет')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.email"
                  type="text"
                  outlined
                  dense
                  class="mt-2 mb-6"
                  :label="$t('Email')"
                  :rules="[v => !!v || $t('Введите email'), validators.emailValidator]"
                  :placeholder="$t('Введите ваш email')"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="paymentData.total_price"
                  type="text"
                  outlined
                  dense
                  :rules="[v => !!v || $t('Введите сумму к оплате'), validators.totalPricePositiveValidator]"
                  required
                  class="mt-2 mb-6"
                  :label="$t('Сумма к оплате')"
                  :placeholder="$t('Введите сумму к оплате')"
                  hide-details="auto"
                ></v-text-field>

                <v-checkbox
                  id="policy"
                  key="policy"
                  v-model="paymentData.agree_policy"
                  hide-details
                  class="mt-2 mb-6"
                >
                  <template #label>
                    {{ $t('Согласен с договором оферты') }}
                  </template>
                </v-checkbox>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="createInvoiceIsDisable"
                  @click="createInvoice"
                >
                  {{ $t('Сформировать счет') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { computed, ref, onMounted } from '@vue/composition-api'
import { required, emailValidator, totalPricePositiveValidator } from '@core/utils/validation'
import { mdiOpenInNew } from '@mdi/js'

import { useRouter } from '@core/utils'

export default {
    setup() {
        const { router } = useRouter()
        const payForm = ref(null)
        const isValid = ref(false)
        const isInvoiceModalOpen = ref(false)
        const invoiceLink = ref('')
        const paymentData = ref({
            vouchers: '',
            name: '',
            inn: '',
            kpp: '',
            adress: '',
            bank_account: '',
            bank: '',
            bik: '',
            correspondent_account: '',
            email: '',
            total_price: '',
            agree_policy: false,
            type: '',
        })
        const edoData = ref({
            inn: '',
            edo_identifier: '',
        })
        const currentTab = ref(null)
        const items = ['Физическим лицам', 'Юридическим лицам']

        const prefetchInvoiceData = () => {
            store.dispatch('payments/prefetchInvoiceData').then(prefetchedInvoiceData => {
                if (prefetchedInvoiceData != null) {
                    paymentData.value.name = prefetchedInvoiceData.name
                    paymentData.value.inn = prefetchedInvoiceData.inn
                    paymentData.value.kpp = prefetchedInvoiceData.kpp
                    paymentData.value.adress = prefetchedInvoiceData.adress
                    paymentData.value.bank_account = prefetchedInvoiceData.bank_account
                    paymentData.value.bank = prefetchedInvoiceData.bank
                    paymentData.value.bik = prefetchedInvoiceData.bik
                    paymentData.value.correspondent_account = prefetchedInvoiceData.correspondent_account
                    paymentData.value.email = prefetchedInvoiceData.email
                }
            })
        }
        const prefetchEdoData = () => {
            store.dispatch('payments/prefetchEdoData').then(prefetchedEdoData => {
                if (prefetchedEdoData != null) {
                    edoData.value.inn = prefetchedEdoData.inn
                    edoData.value.edo_identifier = prefetchedEdoData.edo_identifier
                }
            })
        }
        onMounted(() => {
            prefetchInvoiceData()
            prefetchEdoData()
        })

        const payOrder = () => {
            if ((paymentData.value.vouchers === '') || (paymentData.value.total_price === '') || (paymentData.value.name === '') || (paymentData.value.email === '')) {
                isValid.value = false
            } else {
                paymentData.value.type = 'pay'
                store.dispatch('payments/payOrder', paymentData.value).then(paymentContent => {
                    router.push({ name: 'payment-link', query: { items: paymentContent } })
                })
            }
        }

        const createInvoice = () => {
            if ((paymentData.value.vouchers === '') || (paymentData.value.total_price === '') || (paymentData.value.name === '') || (paymentData.value.email === '') || (paymentData.value.inn === '') || (paymentData.value.kpp === '') || (paymentData.value.adress === '') || (paymentData.value.bank_account === '') || (paymentData.value.bank === '') || (paymentData.value.bik === '') || (paymentData.value.correspondent_account === '')) {
                isValid.value = false
            } else {
                paymentData.value.type = 'invoice'
                store.dispatch('payments/createInvoice', paymentData.value).then(invoiceUrl => {
                    isInvoiceModalOpen.value = true
                    invoiceLink.value = invoiceUrl
                })
            }
        }
        const onPaymentsHistory = () => {
            router.push({ name: 'payments-history' })
        }

        return {
            paymentData,
            edoData,
            payOrder,
            createInvoice,
            payForm,
            isValid,
            isInvoiceModalOpen,
            invoiceLink,
            createInvoiceIsDisable: computed(() => {
                if ((paymentData.value.agree_policy === true) && (isValid.value === true) && ((paymentData.value.vouchers !== '') && (paymentData.value.total_price !== '') && (paymentData.value.name !== '') && (paymentData.value.email !== '') && (paymentData.value.inn !== '') && (paymentData.value.kpp !== '') && (paymentData.value.adress !== '') && (paymentData.value.bank_account !== '') && (paymentData.value.bank !== '') && (paymentData.value.bik !== '') && (paymentData.value.correspondent_account !== ''))) { return false }
                if ((paymentData.value.agree_policy === false) && (isValid.value === false)) { return true }
                if ((paymentData.value.agree_policy === true) && (isValid.value === false)) { return true }
                if ((paymentData.value.agree_policy === false) && (isValid.value === true)) { return true }

return true
            }),
            submitIsDisable: computed(() => {
                if ((paymentData.value.agree_policy === true) && (isValid.value === true) && ((paymentData.value.vouchers !== '') && (paymentData.value.total_price !== '') && (paymentData.value.name !== '') && (paymentData.value.email !== ''))) { return false }
                if ((paymentData.value.agree_policy === false) && (isValid.value === false)) { return true }
                if ((paymentData.value.agree_policy === true) && (isValid.value === false)) { return true }
                if ((paymentData.value.agree_policy === false) && (isValid.value === true)) { return true }

return true
            }),
            validators: {
                required,
                emailValidator,
                totalPricePositiveValidator,
            },
            onPaymentsHistory,
            mdiOpenInNew,
            currentTab,
            items,
        }
    },
}
</script>
