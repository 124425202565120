import enMessages from '@/plugins/i18n/locales/en'
import faMessages from '@/plugins/i18n/locales/fa'
import ruMessages from '@/plugins/i18n/locales/ru'
import viMessages from '@/plugins/i18n/locales/vi'
import zhMessages from '@/plugins/i18n/locales/zh'

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'ru',
  fallbackLocale: 'ru',
  silentTranslationWarn: true,
  messages: {
    ru: ruMessages,
    en: enMessages,
    zh: zhMessages,
    fa: faMessages,
    vi: viMessages,
  },
})

export default function setI18nLanguage(lang) {
  i18n.locale = lang
  localStorage.setItem('locale', lang)
}
