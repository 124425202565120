<template>
  <v-card>
    <v-card-title class="text-2xl font-weight-semibold">
      {{ museumName }}
    </v-card-title>
    <v-divider></v-divider>
    <!-- Если лицензия в музей НЕ ДОБАВЛЕНА -->
    <div v-if="!hasLicenseData">
      <v-card-text class="py-2">
        <v-icon
          size="18"
        >
          {{ icons.mdiInformationOutline }}
        </v-icon>
        Для продления лицензии в музей: "{{ museumName }}" добавьте ее в разделе&nbsp;<a href="/licenses">мои лицензии</a>
      </v-card-text>
    </div>
    <!-- Если лицензия в музей ДОБАВЛЕНА -->
    <div v-else>
      <v-card-text
        class="pb-0"
      >
        <!-- НЕТ записи на продление -->
        <v-col
          v-if="!hasProlongationRequest"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          cols="12"
          class="px-0 pb-4 mx-0 py-4 my-0"
        >
          <v-dialog
            v-model="$parent.isDialogVisiblePolongateLicense"
            persistent
            max-width="600px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                outlined
                dark
                block
                v-on="on"
              >
                Записаться на продление
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline">
                Продлить лицензию
              </v-card-title>
              <v-card-text>Хотите оставить заявку на продление?</v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  outlined
                  @click="$parent.isDialogVisiblePolongateLicense = false"
                >
                  Нет
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$parent.prolongateLicense(museumData.museum_data.id, museumData.license_data.license_id)"
                >
                  Да
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <!-- ЕСТЬ запись на продление -->
        <v-col
          v-if="hasProlongationRequest && groupNotSelected"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          cols="12"
          class="px-0 mx-0 py-0 my-0"
        >
          <v-card-text class="px-0 mx- py-0 my-0">
            <v-list>
              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon
                    dark
                    size="26"
                    color="white"
                    v-text="icons.mdiCheckBold"
                  ></v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Заявка принята</v-list-item-title>
                  <v-list-item-subtitle>Вы сможете записаться в группу, когда будут известны даты</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-col>
        <!-- Eсть запись и можно выбрать группу -->
        <v-col
          v-if="hasProlongationRequest && groupAvaliable && groupNotSelected"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          cols="12"
          class="px-0 pb-4 mx-0 py-0 my-0"
        >
          <v-dialog
            v-model="isDialogVisibleChooseGroup"
            persistent
            max-width="600px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                outlined
                block
                dark
                class="mb-2 mt-2"
                v-on="on"
              >
                Выбрать группу
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline">
                Выбрать группу
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    cols="12"
                    class=""
                  >
                    <v-select
                      v-model="selectedGroup"
                      :items="groupsData"
                      item-value="id"
                      item-text="name"
                      label="Группа"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="error"
                  outlined
                  @click="isDialogVisibleChooseGroup = false"
                >
                  Отмена
                </v-btn>
                <v-btn
                  color="primary"
                  @click="chooseGroup(selectedGroup, museumData.license_data.license_id)"
                >
                  Выбрать
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <!-- Есть запись и группа выбрана -->
        <v-col
          v-if="hasProlongationRequest && !groupNotSelected"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          cols="12"
          class="px-0 mx-0 py-0 my-0"
        >
          <v-card-text class="px-0 mx- py-0 my-0">
            <v-list>
              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon
                    dark
                    size="26"
                    color="white"
                    v-text="icons.mdiCheckBold"
                  ></v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Заявка принята</v-list-item-title>
                  <v-list-item-subtitle>Вы записаны в группу <b>{{ museumData.license_data.prolongation_group_name }}</b></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-col>
        <!-- Продление не оплачено -->
        <v-card-text
          v-if="isProlongationPaid"
          class="px-0 mx-0 py-2"
        >
          <v-icon
            class="me-1"
            size="18"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Для продления лицензии в музей: "{{ museumName }}" оплатите продление
        </v-card-text>
        <!-- Договор на продление не заключен -->
        <v-card-text
          v-if="isContractSigned"
          class="px-0 mx-0"
        >
          <v-icon
            class="me-1"
            size="18"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Для продления лицензии в музей: "{{ museumName }}" заключите договор на продление
        </v-card-text>
      </v-card-text>
    </div>
    <div>
      <v-divider>
      </v-divider>
      <v-card-text class="subtitle-1">
        Стоимость продления:
        <!-- {{ museumData['pricelist_data'][] }} -->
        <span v-if="museumData['pricelist_data']">{{ museumData['pricelist_data']['price'] }} ₽</span>
        <span v-else>Не указана</span>
        <!-- {{ museumData['pricelist_data']['price'] }} ₽ -->
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import store from '@/store'
import { mdiCheckBold, mdiInformationOutline } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'

export default {
  components: {},
  props: {
    museumData: {
      type: Object,
      required: true,
    },
    museumName: {
      type: String,
      required: true,
    },
    groupsData: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  computed: {
    hasLicenseData() {
      return this.containsKey(this.museumData, 'license_data')
    },
    hasProlongationRequest() {
      if (this.museumData.license_data) {
        return this.museumData.license_data.prolongation_request_id
      }

      return false
    },
    groupAvaliable() {
      if (this.museumData.groups_data.length > 0) {
        return true
      }

      return false
    },
    groupNotSelected() {
      if (!this.museumData.license_data.prolongation_group_name) {
        return true
      }

      return false
    },
    isContractSigned() {
      if (this.museumData.license_data.is_contract_signed) {
        return false
      }

      return true
    },
    isProlongationPaid() {
      if (this.museumData.license_data.prolongation_paid === 1) {
        return false
      }

      return true
    },
  },
  setup() {
    const isDialogVisibleChooseGroup = ref(false)
    const containsKey = (obj, key) => Object.keys(obj).includes(key)
    const selectedGroup = ref(null)
    const vm = getCurrentInstance().proxy

    const chooseGroup = (groupId, licenseId) => {
      store.dispatch('licenses/setLicenseProlongationGroup', { group_id: groupId, license_id: licenseId }).then(() => {
        isDialogVisibleChooseGroup.value = false
        vm.$parent.fetchProlongationPageData()
      })
    }

    return {
      icons: {
        mdiInformationOutline,
        mdiCheckBold,
      },
      chooseGroup,
      isDialogVisibleChooseGroup,
      selectedGroup,
      containsKey,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';
</style>
