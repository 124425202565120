import axios from '@axios'

export default {
    namespaced: true,
    state: {
        licenses: [],
        liclangs: [],
        licensing_objects: [],
        license_prolongations_groups: {},
        license_pricelist: [],
        prolongation_data: null,

        request_is_success: null,
        request_error_msg: null,
    },
    getters: {
        getLicenses: state => state.licenses,
        getLicensingLangs: state => state.liclangs,
        getLicensingObjects: state => state.licensing_objects,
        getPricelist: state => state.license_pricelist,
        getLicenseProlongationsGroups: state => state.license_prolongations_groups,
        getProlongationData: state => state.prolongation_data,
        getReqStatus: state => state.request_is_success,
        getReqErrorMsg: state => state.request_error_msg,
    },
    mutations: {
        /* Data for prolongation page */
        init_prolongation_page_request(state) {
            state.request_is_success = null
        },
        set_on_request_success_prolongation_page_data(state, payload) {
            state.prolongation_data = payload
            state.request_is_success = true
            state.request_error_msg = null
        },

        /* license_list */
        init_license_state(state) {
            state.licenses = []
            state.request_is_success = null
        },
        set_on_request_success_licenses(state, payload) {
            state.licenses = payload
            state.request_is_success = true
            state.request_error_msg = null
        },

        /* license_languages */
        init_liclangs_state(state) {
            state.liclangs = []
            state.request_is_success = null
        },
        set_on_request_success_liclangs(state, payload) {
            state.liclangs = payload
            state.request_is_success = true
            state.request_error_msg = null
        },

        /* license objects */
        init_licensing_objects(state) {
            state.licensing_objects = []
            state.request_is_success = null
        },
        set_on_request_success_licensing_objects(state, payload) {
            state.licensing_objects = payload
            state.request_is_success = true
            state.request_error_msg = null
        },

        /* add new license */
        init_license_add_state(state) {
            state.request_is_success = null
        },
        set_on_request_success_license_add(state) {
            console.log(state)
            console.log('new license added success')
        },

        /* delete license */
        init_license_delete_state(state) {
            state.request_is_success = null
        },
        set_on_request_success_license_delete(state) {
            console.log(state)
            console.log('new license added success')
        },

        /* license prolongations groups */
        init_license_prolongations_groups(state) {
            state.license_prolongations_groups = []
            state.request_is_success = null
        },
        set_on_request_success_prolongation_groups(state, payload) {
            state.license_prolongations_groups = payload
            state.request_is_success = true
            state.request_error_msg = null
        },
        set_on_request_success_set_prolongation_group(state, payload) {
            console.log(payload)
            console.log(state)
        },

        /* change license langs */
        init_license_change_lang_state(state) {
            state.request_is_success = null
        },
        set_on_request_success_change_license_langs(state, payload) {
            console.log(state)
            console.log(payload)
        },

        /* set license prolongate */
        set_on_request_success_prolongate_license(state, payload) {
            console.log(payload)
            console.log(state)
        },
        prolongate_license(state, payload) {
            console.log(payload)
            console.log(state)
        },

        /* set pricelist */
        set_on_request_success_fetch_pricelist(state, payload) {
            state.license_pricelist = payload
            state.request_is_success = true
            state.request_error_msg = null
        },

        /* set error request state */
        set_on_request_error(state, error) {
            state.request_is_success = false
            state.request_error_msg = error
        },

    },
    actions: {
        /* Получить агрегированные данные для страницы продления лицензий */
        fetchProlongationPageData({ commit }) {
            commit('init_prolongation_page_request')

            return new Promise((resolve, reject) => {
                const data = JSON.stringify({})
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'get_prolongation_page_data')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_prolongation_page_data', response.data.data)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Получить список объектов для лицензий */
        fetchLicobjects({ commit }) {
            commit('init_licensing_objects')

            return new Promise((resolve, reject) => {
                const data = JSON.stringify({})
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'licobjects')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_licensing_objects', response.data.data)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Получить список всех лицензий */
        fetchLicenses({ commit }) {
            commit('init_license_state')

            return new Promise((resolve, reject) => {
                const data = JSON.stringify({})
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'license_list')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_licenses', response.data.data.result)
                            resolve(response.data.data)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Получить прайслист на продление */
        fetchPricelist({ commit }) {
            return new Promise((resolve, reject) => {
                const data = JSON.stringify({})
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'get_pricelists')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_fetch_pricelist', response.data.data.result)
                            resolve(response)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Получить список групп для продления по массиву лицензий */
        fetchProlongationGroups({ commit }, licenses) {
            const licenesesList = []
            licenses.result.forEach(element => {
                licenesesList.push(element.license_id)
            })
            console.log(licenesesList)

            return new Promise((resolve, reject) => {
                const data = JSON.stringify(licenesesList)
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'license_list_prolongation_groups')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_prolongation_groups', response.data.data)
                            resolve(response)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Получить список языков для лицензий */
        fetchLiclangs({ commit }) {
            commit('init_liclangs_state')

            return new Promise((resolve, reject) => {
                const data = JSON.stringify({})
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'liclang')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_liclangs', response.data.data)
                            resolve(response)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Добавить лицензию */
        addLicense({ commit }, newLicense) {
            commit('init_license_add_state')

            return new Promise((resolve, reject) => {
                const data = JSON.stringify(newLicense)
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'add_license')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_license_add', response.data.result)
                            resolve(response)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Удалить лицензию */
        deleteLicense({ commit }, licenseId) {
            commit('init_license_delete_state')

            return new Promise((resolve, reject) => {
                const data = JSON.stringify(licenseId)
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'delete_license')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_license_delete', response.data.result)
                            resolve(response)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            console.log('erorr')
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Выбрать группу для продления лицензии */
        setLicenseProlongationGroup({ commit }, prolongationData) {
            return new Promise((resolve, reject) => {
                const data = JSON.stringify(prolongationData)
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'license_set_prolongation_group')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_set_prolongation_group', response.data.data.result)
                            resolve(response)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Изменить языки для лицензии */
        changeLicenseLanguages({ commit }, languagesData) {
            commit('init_license_change_lang_state')

            return new Promise((resolve, reject) => {
                const data = JSON.stringify(languagesData)
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'license_change_langs')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_change_license_langs', response.data.data.result)
                            resolve(response)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        /* Подать заявку на продление лицензии */
        setLicenseProlongate({ commit }, licenseId) {
            return new Promise((resolve, reject) => {
                const data = JSON.stringify(licenseId)
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'prolongate_license')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.data.success) {
                            commit('set_on_request_success_prolongate_license', response.data.data.result)
                            resolve(response)
                        } else {
                            commit('set_on_request_error', response.data.error)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

    },
}
