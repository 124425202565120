<template>
  <v-row>
    <v-col
      v-for="service in services"
      :key="service.id"
      cols="12"
      sm="6"
      md="4"
      lg="3"
    >
      <ServiceCard
        :title="service.title"
        :image="service.image"
        :link="service.link"
        :is-schedule-exist="service.isScheduleExist"
        :schedule-link="service.scheduleLink"
      />
    </v-col>
  </v-row>
</template>

<script>
import ServiceCard from "./ServiceCard.vue"; // Adjust the path as necessary

export default {
  components: {
    ServiceCard,
  },
  setup() {
    const services = [
      {
        id: 1,
        title: "Билеты в музеи",
        image: "ticket.png",
        link: "/new",
        isScheduleExist: true,
        scheduleLink: "/new-schedule",
      },
      {
        id: 2,
        title: "Радио/Аудиогиды",
        image: "rg.png",
        link: "/new-rg-ag",
        isScheduleExist: false,
        scheduleLink: "",
      },
      {
        id: 3,
        title: "Транспорт",
        image: "van.png",
        link: "/new-transport?tab=0",
        isScheduleExist: true,
        scheduleLink: "/new-transport?tab=1",
      },
      {
        id: 4,
        title: "Гид",
        image: "guide.png",
        link: "/new-guide?tab=0",
        isScheduleExist: true,
        scheduleLink: "/new-guide?tab=1",
      },
    ]

    return { services }
  },
}
</script>

<style>
/* Your global styles */
</style>
