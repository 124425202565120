<template>
  <v-row>
    <v-col
      v-if="!upcomingRequestIsSuccess"
      xs="12"
      sm="12"
      md="12"
      lg="10"
      cols="12"
    >
      <loader></loader>
    </v-col>
    <!-- new request panel -->
    <ServiceSelector v-if="upcomingRequestIsSuccess"></ServiceSelector>
    <!-- upcoming orders empty -->
    <v-col
      v-if="upcomingRequestIsSuccess && upcomingList.length == 0"
      xs="12"
      sm="12"
      md="12"
      lg="11"
      cols="12"
    >
      <h2 class="secondary--text mt-4 mb-8">
        {{ $t("Нет предстоящих заказов") }}
      </h2>
    </v-col>
    <!-- upcoming orders not empty -->
    <v-row
      v-if="upcomingRequestIsSuccess && upcomingList.length > 0"
    >
      <v-col
        v-for="request in combinedList()"
        :key="request.id"
        xs="12"
        sm="12"
        md="12"
        lg="11"
        cols="12"
      >
        <OrderSchedule
          v-if="request.type !== 'ad' &&
            !(
              museumObjectsInfo[request.data.museum_id]['is_radioguide_request'] ||
              museumObjectsInfo[request.data.museum_id]['is_audioguide_request']
            ) && (request.info.museum_schedule_item.includes('График'))
          "
          :key="request.id"
          :order="request"
          :disable-actions="[]"
          :object-info="museumObjectsInfo[request.data.museum_id]"
        ></OrderSchedule>
        <Order
          v-if="request.type !== 'ad' &&
            !(
              museumObjectsInfo[request.data.museum_id]['is_radioguide_request'] ||
              museumObjectsInfo[request.data.museum_id]['is_audioguide_request']
            ) && !(request.info.museum_schedule_item.includes('График'))
          "
          :key="request.id"
          :order="request"
          :disable-actions="[]"
          :object-info="museumObjectsInfo[request.data.museum_id]"
        ></Order>
        <OrderRgAg
          v-if=" request.type !== 'ad' &&
            ( museumObjectsInfo[request.data.museum_id]['is_radioguide_request'] ||
              museumObjectsInfo[request.data.museum_id]['is_audioguide_request'])
          "
          :key="request.id"
          :order="request"
          :disable-actions="[]"
          :object-info="museumObjectsInfo[request.data.museum_id]"
        ></OrderRgAg>
        <Ad
          v-if="request.type === 'ad' && request.is_active"
          :key="request.id"
          :ad="request"
        >
        </Ad>
      </v-col>
    </v-row>
    <!-- <v-dialog
      v-model="isDialogOpen"
      max-width="600px"
    >
      <v-card class="user-edit-info pa-sm-10 pa-3">
        <v-card-title
          class="justify-center text-center text-h5 no-wrap"
          style="line-height: 1.1em;"
        >
          <p>{{ loginNotification.title }}</p>
        </v-card-title>
        <v-img
          v-if="loginNotification.image"
          :src="loginNotification.image"
          class="mb-6 mt-4"
        ></v-img>
        <v-card-text class="justify-center text-center mt-2 text-base">
          {{ loginNotification.content_paragraph_1 }}
        </v-card-text>
        <v-card-text class="justify-center text-center mt-2 text-base">
          {{ loginNotification.content_paragraph_2 }}
        </v-card-text>

        <v-card-text class="mt-5">
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-center mt-3"
              >
                <v-btn
                  color="default"
                  dark
                  class="mx-4"
                  @click="closeDialog"
                >
                  {{ $t("Закрыть") }}
                </v-btn>
                <v-btn
                  v-if="loginNotification.add_close_button"
                  color="primary"
                  dark
                  class="mx-4"
                  target="_blank"
                  :href="`${loginNotification.button_link}`"
                >
                  {{ loginNotification.button_text }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </v-row>
</template>

<script>
import Ad from "@/components/Ad.vue"
import Loader from "@/components/Loader.vue"
import Order from "@/components/Order.vue"
import OrderRgAg from "@/components/OrderRgAg.vue"
import OrderSchedule from "@/components/OrderSchedule.vue"
import ServiceSelector from "@/components/ServiceSelector.vue"
import store from "@/store"
import { computed, onMounted, ref } from "@vue/composition-api"

export default {
  components: {
    Loader,
    Order,
    OrderSchedule,
    OrderRgAg,
    Ad,
    ServiceSelector,
  },
  setup(props, context) {
    const isDialogOpen = ref(true)
    const loginNotification = ref({})
    const fetchAds = () => {
      store.dispatch("ads/fetchAds")
    }
    const fetchNotification = () => {
      store.dispatch("news/fetchNotification").then(data => {
        if (data.length > 0) {
          [loginNotification.value] = data
          isDialogOpen.value = true
        }
      })
    }
    const fetchUpcoming = () => {
      store.dispatch("upcoming/fetchUpcoming")
    }
    fetchAds()
    fetchNotification()
    fetchUpcoming()
    const closeDialog = () => {
      isDialogOpen.value = false
    }

    onMounted(() => {
      if (context.root.$route.query.fromAuth) {
        isDialogOpen.value = true
      } else {
        isDialogOpen.value = false
      }
    })

    return {
      fetchNotification,
      loginNotification,
      fetchUpcoming,
      upcomingList: computed(() => store.getters["upcoming/getUpcomingList"]),
      museumObjectsInfo: computed(() => store.getters["upcoming/getMuseumObjectsInfo"]),
      upcomingRequestIsSuccess: computed(() => store.getters["upcoming/getRequestStatus"]),
      adsList: computed(() => store.getters["ads/getAds"]),
      combinedList() {
        const combinedList = [...this.upcomingList] // Create a copy of upcomingList to avoid modifying it directly

        // Iterate through adsList and insert each element at the specified position in combinedList
        this.adsList.forEach(item => {
          const { position } = item
          combinedList.splice(position, 0, item)
        })

        return combinedList
      },
      isDialogOpen,
      closeDialog,
    }
  },
}
</script>

<style lang="scss" scoped></style>
