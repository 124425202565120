<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <div class="d-flex justify-end mb-8">
        <div class=" d-flex align-center text me-6">
          {{ $t('Выберите язык') }}
        </div>
        <v-menu
          offset-y
          nudge-bottom="22"
          min-width="175"
          left
          :elevation="$vuetify.theme.dark ? 9 : 8"
        >
          <!-- Activator -->
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="d-flex align-center activator-button"
              v-on="on"
            >
              <v-img
                :src="require(`@/assets/images/flags/${$i18n.locale}.webp`)"
                :alt="$i18n.locale"
                height="14px"
                width="22px"
                class="me-2"
              ></v-img>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ locales.find(l => l.locale === $i18n.locale).title }}</span>
            </div>
          </template>

          <!-- Options List -->
          <v-list>
            <v-list-item-group
              :value="$i18n.locale"
              @change="updateActiveLocale"
            >
              <v-list-item
                v-for="locale in locales"
                :key="locale.locale"
                :value="locale.locale"
              >
                <v-img
                  :src="locale.img"
                  height="14px"
                  width="22px"
                  :alt="locale.locale"
                  class="me-2"
                ></v-img>
                <v-list-item-title>{{ locale.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <div class="d-flex align-center justify-center py-7 mb-1">
        <v-img
          :src="appLogo"
          max-height="90px"
          max-width="90px"
          alt="logo"
          contain
        ></v-img>
      </div>
      <div class="d-flex align-center justify-center py-1 mb-4"></div>

      <v-form
        ref="loginForm"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="email"
          outlined
          :label="$t('Логин')"
          placeholder="user@example.com"
          :error-messages="errorMessages.email"
          :rules="[validators.required]"
          class="mb-3"
        ></v-text-field>

        <v-text-field
          v-model="password"
          outlined
          :type="isPasswordVisible ? 'text' : 'password'"
          :label="$t('Пароль')"
          :error-messages="errorMessages.password"
          placeholder="············"
          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          :rules="[validators.required]"
          hide-details
          @click:append="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>

        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-10 mb-3"
        >
          {{ $t("Войти") }}
        </v-btn>
        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <p class="mb-0 me-2 text-lg">
            {{ $t("У вас нет аккаунта?") }}
          </p>
          <router-link to="/registration">
            <b class="text-lg">{{ $t("Зарегистрироваться") }}</b>
          </router-link>
        </v-card-text>
      </v-form>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import setI18nLanguage from "@/plugins/i18n"
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js"

import axios from "@axios"
import { useRouter } from "@core/utils"
import { required } from "@core/utils/validation"
import themeConfig from "@themeConfig"
import { getCurrentInstance, ref } from "@vue/composition-api"

export default {
  setup() {
    const { isRtl } = useAppConfig()

    const locales = [
      {
        title: "Русский",
        img: require("@/assets/images/flags/ru.webp"),
        locale: "ru",
      },
      {
        title: "中文",
        img: require("@/assets/images/flags/zh.webp"),
        locale: "zh",
      },
      {
        title: "English (UK)",
        img: require("@/assets/images/flags/gb.webp"),
        locale: "en",
      },
      {
        title: "Tiếng Việt",
        img: require("@/assets/images/flags/vi.webp"),
        locale: "vi",
      },
      {
        title: "فارسی",
        img: require("@/assets/images/flags/fa.webp"),
        locale: "fa",
      },
    ]
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref("")
    const password = ref("")
    const errorMessages = ref([])

    const handleFormSubmit = () => {
      errorMessages.value = {}
      const isFormValid = loginForm.value.validate()
      if (!isFormValid) return

      const data = JSON.stringify({
        login: email.value,
        password: password.value,
        device_id: "dev45234",
        token: "token-1",
        language: "ru",
        source_website: "True",
        source_mobile_app: "False",
      })
      const loginData = new FormData()
      loginData.append("data", data)

      axios({
        method: "post",
        url: "/api/v1/auth/login",
        data: loginData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(response => {
          const { accessToken } = response.data.data
          localStorage.setItem("accessToken", accessToken)

          return response
        })
        .then(() => {
          axios({
            method: "get",
            url: "/api/v1/auth/me",
          }).then(response => {
            const { user } = response.data.data
            const userAbility = [{ action: "manage", subject: "all" }]

            vm.$ability.update(userAbility)

            localStorage.setItem("userAbility", JSON.stringify(userAbility))

            localStorage.setItem("userData", JSON.stringify(user))

            router.push({ name: "upcoming-requests", query: { fromAuth: true } })
          })
        })
        .catch(() => {
          // errorMessages.value = { email: [error.response.data.error] }
          errorMessages.value = { email: ["Неверные имя пользователя или пароль"] }
        })
    }

    const updateActiveLocale = locale => {
      isRtl.value = locale === "fa"
      setI18nLanguage(locale)
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,

      // Language Switcher
      locales,
      updateActiveLocale,
    }
  },
}
</script>

<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";

.activator-button {
  border: 1px solid rgba(94, 86, 105, 0.47);
  padding: 6px 12px;
  border-radius: 5px;
}
</style>
