import axios from "@axios"

export default {
  namespaced: true,
  state: {
    news: [],
    request_is_success: null,
    request_error_msg: null,
  },
  getters: {
    getNews: state => state.news,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
  },
  mutations: {
    set_on_request_success(state, payload) {
      state.news = payload
      state.request_is_success = true
      state.request_error_msg = null
    },
    set_on_request_error(state, error) {
      state.news = []
      state.request_is_success = false
      state.request_error_msg = error
    },
  },
  actions: {
    fetchNews({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: "/api/v1/news/",
        })
          .then(response => {
            if (response.status === 200) {
              commit("set_on_request_success", response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    disableNewsMark() {
      return new Promise((resolve, reject) => {
        const data = JSON.stringify({})
        const postData = new FormData()
        postData.append("action", "unmark_news")
        postData.append("data", data)
        axios({
          method: "post",
          url: "/api/v1/actions/",
          data: postData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchNotification() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: "/api/v1/login_notification/",
        })
          .then(response => {
            if (response.status === 200) {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
