<template>
  <v-card elevation="2">
    <v-card-title>
      <span class="title pb-4">{{ $t("Гид") }} {{ orderIndex + 1 }}</span>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="guideOrderForm"
        lazy-validation
      >
        <v-row>
          <!-- Дата -->
          <v-col cols="12">
            <v-text-field
              v-model="order.date"
              :label="$t('Дата')"
              type="date"
              required
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Язык -->
          <v-col cols="12">
            <v-select
              v-model="order.language"
              :items="guideTypes"
              label="Выберите язык"
              item-text="system_name"
              item-value="system_id"
              hide-details
              outlined
              required
              @change="emitFetchSchedule(orderIndex)"
            ></v-select>
          </v-col>

          <!-- Время начала -->
          <v-col cols="12">
            <v-text-field
              v-model="order.startTime"
              :label="$t('Время встречи')"
              type="time"
              required
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <!-- Адрес начала -->
          <v-col cols="12">
            <v-text-field
              v-model="order.startAddress"
              label="Место встречи"
              hide-details
              outlined
              required
            ></v-text-field>
          </v-col>

          <!-- Время окончания -->
          <v-col cols="12">
            <v-text-field
              v-model="order.finishTime"
              :label="$t('Время окончания')"
              type="time"
              required
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <!-- Адрес окончания -->
          <v-col cols="12">
            <v-text-field
              v-model="order.finishAddress"
              label="Место окончания"
              hide-details
              outlined
              required
            ></v-text-field>
          </v-col>

          <!-- Заметки -->
          <v-col cols="12">
            <v-textarea
              v-model="order.notes"
              label="Пожелания"
              hide-details
              outlined
            ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            class="d-flex flex-row-reverse"
          >
            <v-btn
              v-if="guideTypes.length > 1 && orderIndex !== 0"
              color="error"
              @click="emitDeleteGroup(orderIndex)"
            >
              <v-icon
                size="19"
                class="mr-2"
              >
                {{ icons.mdiDelete }}
              </v-icon>
              {{ $t("Удалить") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

  <script>
  import { mdiDelete } from "@mdi/js";

  export default {
    props: {
      order: {
        type: Object,
        required: true,
      },
      orderIndex: {
        type: Number,
        required: true,
      },
      guideTypes: {
        type: Array,
        required: true,
      },
    },
    emits: ["deleteGroup", "fetchSchedule"],
    methods: {
      emitDeleteGroup(index) {
        this.$emit('deleteGroup', index);
      },
      emitFetchSchedule(index) {
        this.$emit('fetchSchedule', index);
      },
    },
    setup() {
      return {
        //   languages: ['Английский', 'Китайский', 'Французский', 'Русский', 'Фарси', 'Испанский', 'Немецкий', 'Турецкий'],
          icons: {
          mdiDelete,
        },
      }
    },
  }
  </script>
