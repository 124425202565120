<template>
  <v-row>
    <v-col
      cols="12"
      md="8"
    >
      <payment-confirmed
        :payment-id="paymentId"
        :is-bio-dialog-open="true"
        :is-waiting="isWaiting"
        :is-success="isSuccess"
        :is-error="isError"
        :error-text="errorText"
      ></payment-confirmed>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import PaymentConfirmed from '@/components/PaymentConfirmed.vue'
import { ref, onMounted, computed } from '@vue/composition-api'

export default {
    components: {
        PaymentConfirmed,
    },
    setup(props, context) {
        const paymentId = ref('')
        const transactionId = ref('')

        const checkPaymentStatus = () => {
            store.dispatch('payments/checkPaymentStatus', {
                paymentId: paymentId.value,
                transactionId: transactionId.value,
            }).then(status => {
                if ((status === 'WAITING_FOR_3DS') || (status === 'PROCESSING') || (status === 'FORM_OPENED')) {
                    setTimeout(() => {
                        checkPaymentStatus()
                    }, 2000)
                }
            })
        }
        onMounted(() => {
            paymentId.value = context.root.$route.query.payment_id
            transactionId.value = context.root.$route.query.transaction_id
            checkPaymentStatus()
        })

        return {
            PaymentConfirmed,
            paymentId,
            isWaiting: computed(() => store.getters['payments/getIsWaiting']),
            isSuccess: computed(() => store.getters['payments/getIsSuccess']),
            isError: computed(() => store.getters['payments/getIsError']),
            errorText: computed(() => store.getters['payments/getErrorText']),
            checkPaymentStatus,
        }
    },
}
</script>
