import axios from '@axios'

export default {
  namespaced: true,
  state: {
    upcoming_list: [],
    museums_objects_info: {},
    request_is_success: null,
    request_error_msg: null,
  },
  getters: {
    getUpcomingList: state => state.upcoming_list,
    getMuseumObjectsInfo: state => state.museums_objects_info,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
  },
  mutations: {
    set_on_request_success(state, data) {
      state.upcoming_list = data.result
      if (data.payload !== undefined) {
        state.museums_objects_info = data.payload
      }
      state.request_is_success = true
      state.request_error_msg = null
    },
    set_request_init(state) {
      state.request_is_success = null
      state.upcoming_list = []
      state.museums_objects_info = {}
      state.request_error_msg = null
    },
    set_on_request_error(state, error) {
      state.upcoming_list = []
      state.museums_objects_info = {}
      state.request_is_success = false
      state.request_error_msg = error
    },
  },
  actions: {
    fetchUpcoming({ commit }) {
      commit('set_request_init')

      return new Promise((resolve, reject) => {
        const data = JSON.stringify({
          language: 'ru',
        })
        const postData = new FormData()
        postData.append('data', data)
        postData.append('action', 'get_upcoming')
        axios({
          method: 'post',
          url: '/api/v1/actions/',
          data: postData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.status === 200) {
              commit('set_on_request_success', response.data.data)
              resolve(response)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}
