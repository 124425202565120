<template>
  <v-row>
    <!-- loader -->
    <v-col
      v-if="false"
      xs="12"
      sm="12"
      md="12"
      lg="10"
      cols="12"
    >
      <loader></loader>
    </v-col>
    <!-- степпер для нового заказа -->
    <v-col
      cols="12"
      xs="12"
      sm="12"
      md="9"
    >
      <v-stepper
        v-model="activeStep"
        vertical
        class="custom-header"
      >
        <v-form
          ref="orderForm"
          v-model="valid"
          lazy-validation
        >
          <!-- Step 1 -->
          <v-stepper-step
            :complete="activeStep > 1"
            step="1"
          >
            <div class="d-flex align-center">
              <span class="text--primary text-4xl font-weight-bold me-3">01</span>
              <div class="d-flex flex-column">
                <span class="text--primary text-sm font-weight-semibold">{{ $t('Информация о музее') }}</span>
                <span class="text--secondary text-xs">{{ $t('Выберите категорию группы (РФ или ИГ), музей, дату и время') }}</span>
              </div>
            </div>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="order.type"
                  :items="typeList[getFormatedLocale()]"
                  :rules="[v => !!v || $t('Выберите категорию группы')]"
                  :label="$t('Выберите категорию группы')"
                  required
                  item-text="name"
                  item-value="value"
                  hide-details="auto"
                  class="mb-6 mt-2"
                  outlined
                  dense
                  @change="fetchMuseums"
                >
                </v-select>
                <v-select
                  v-model="order.museum"
                  :items="museumsList"
                  :rules="[v => !!v || $t('Выберите музей')]"
                  :label="$t('Выберите музей')"
                  required
                  :item-text="'name' + getFormatedLocale()"
                  item-value="id"
                  hide-details="auto"
                  class="mb-6 mt-2"
                  outlined
                  dense
                  @change="fetchMuseumObjects"
                >
                  <!-- <template v-slot:item="slotProps">
                    {{ slotProps.item.name }}
                  </template> -->
                </v-select>

                <v-select
                  v-if="order.museum"
                  v-model="order.museumObject"
                  :items="museumObjects"
                  :rules="[v => !!v || $t('Выберите объект')]"
                  :label="$t('Выберите объект')"
                  required
                  :item-text="'name' + getFormatedLocale()"
                  item-value="system_id"
                  hide-details="auto"
                  class="mb-2 mt-2"
                  outlined
                  dense
                  @change="setMuseumObject"
                >
                  <!-- <template v-slot:item="slotProps">
                    {{ slotProps.item.name }}
                  </template> -->
                </v-select>
                <p
                  v-if="selectedMuseumObject.info_message !== ''"
                  class="caption"
                >
                  {{ selectedMuseumObject.info_message }}
                </p>
                <div class="mt-4">
                  <datepicker
                    v-if="order.museumObject"
                    :key="datepickerKey"
                    on-page="new-request-date"
                    :day-off="selectedMuseumObject['day_off']"
                    @newDate="handleDateChanges($event)"
                  ></datepicker>
                </div>

                <v-select
                  v-if="order.date != new Date().toISOString().substr(0, 10)"
                  v-model="order.schedule"
                  :items="schedule"
                  :rules="[v => !!v || $t('Выберите время')]"
                  :label="$t('Выберите время')"
                  required
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  class="mb-4 mt-4"
                  outlined
                  dense
                ></v-select>
                <div class="mt-4">
                  <v-btn
                    :disabled="order.schedule == null"
                    color="primary"
                    class="ms-2"
                    @click="activeStep = 2"
                  >
                    {{ $t('Далее') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-- Step 2 -->
          <v-stepper-step
            :complete="activeStep > 2"
            step="2"
          >
            <div class="d-flex align-center">
              <span class="text--primary text-4xl font-weight-bold me-3">02</span>
              <div class="d-flex flex-column">
                <span class="text--primary text-sm font-weight-semibold">{{ $t('Информация о группе') }}</span>
                <span class="text--secondary text-xs">{{ $t('Выберите кол-во билетов') }}</span>
              </div>
            </div>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-row>
              <v-col
                cols="12"
                md="9"
              >
                <v-select
                  v-model="order.country"
                  :items="selectedMuseumObject.countries"
                  :rules="[v => !!v || $t('Выберите страну')]"
                  :label="$t('Выберите страну')"
                  required
                  hide-details="auto"
                  item-text="name"
                  item-value="system_id"
                  class="mt-2 mb-6"
                  outlined
                  dense
                ></v-select>
                <v-text-field
                  v-for="kindOfTicket in selectedMuseumObject.tickets"
                  :id="kindOfTicket.internal_name"
                  :key="kindOfTicket.id"
                  v-model="order.tickets[kindOfTicket.internal_name]"
                  type="number"
                  outlined
                  dense
                  class="mt-2 mb-2"
                  :label="kindOfTicket['name' + getFormatedLocale()]"
                  :hint="kindOfTicket['helper_text' + getFormatedLocale()]"
                  placeholder="0"
                  :rules="[validators.integerPositiveValidator]"
                  persistent-hint
                >
                </v-text-field>

                <!-- persistent-hint -->
                <!-- hint="До 18 лет" -->
                <div class="mt-4">
                  <v-btn
                    outlined
                    @click="activeStep = 1"
                  >
                    {{ $t('Назад') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ms-2"
                    :disabled="order.country == null || !valid"
                    @click="activeStep = 3"
                  >
                    {{ $t('Далее') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-- Step 3 -->
          <v-stepper-step
            :complete="activeStep > 3"
            step="3"
          >
            <div class="d-flex align-center">
              <span class="text--primary text-4xl font-weight-bold me-3">03</span>
              <div class="d-flex flex-column">
                <span class="text--primary text-sm font-weight-semibold">{{ $t('Доп. услуги') }}</span>
                <span class="text--secondary text-xs">{{ $t('Выберите дополнительные услуги') }}</span>
              </div>
            </div>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-row>
              <v-col
                :key="rerenderKey"
                cols="12"
                md="9"
              >
                <p
                  v-if="selectedMuseumObject.amount_for_rg !== '' && Object.values(order.tickets).reduce((acc, value) => acc + Number(value), 0) >= selectedMuseumObject.amount_for_rg"
                  class="caption"
                  style="color: crimson;"
                >
                  При данном кол-ве туристов РАДИОГИД обязателен!
                </p>
                <v-checkbox
                  v-for="kindOfService in selectedMuseumObject.services"
                  :id="kindOfService.internal_name"
                  :key="kindOfService.internal_name"
                  v-model="order.services[kindOfService.internal_name]"
                  :disabled="(selectedMuseumObject.service_logic && kindOfService.internal_name==='rg' && order.services['ag']===true) || (selectedMuseumObject.service_logic && kindOfService.internal_name==='ag' && order.services['rg']===true)"
                  hide-details
                  class="mt-2 mb-6"
                  @change="updateKey"
                >
                  <template #label>
                    <div>
                      {{ kindOfService['name' + getFormatedLocale()] }}
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            class="ml-2 info--secondary"
                            v-on="on"
                          >
                            {{ icons.mdiHelpCircleOutline }}
                          </v-icon>
                        </template>
                        <span>{{ kindOfService['helper_text' + getFormatedLocale()] }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>

                <v-text-field
                  v-if="selectedMuseumObject.is_additional_guide_field"
                  v-model="order.guides"
                  type="number"
                  outlined
                  dense
                  class="mt-2 mb-6"
                  :label="$t('Кол-во гидов сопровождающих (если нет лицензии)')"
                  :placeholder="$t('Кол-во гидов сопровождающих (если нет лицензии)')"
                  :hint="$t('Необходим если у гида нет лицензии в выбранный музей')"
                  persistent-hint
                  hide-details="auto"
                ></v-text-field>

                <v-btn
                  outlined
                  @click="activeStep = 2"
                >
                  {{ $t('Назад') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ms-2"
                  @click="activeStep = 4"
                >
                  {{ $t('Далее') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-- Step 4 -->
          <v-stepper-step
            :complete="activeStep > 4"
            step="4"
          >
            <div class="d-flex align-center">
              <span class="text--primary text-4xl font-weight-bold me-3">04</span>
              <div class="d-flex flex-column">
                <span class="text--primary text-sm font-weight-semibold">{{ $t('Доп. информация') }}</span>
                <span class="text--secondary text-xs">{{ $t('Информация о гиде и пожелания') }}</span>
              </div>
            </div>
          </v-stepper-step>

          <v-stepper-content step="4">
            <v-row>
              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  v-model="order.refNumber"
                  type="text"
                  outlined
                  dense
                  class="mt-2 mb-6"
                  :label="$t('Ref номер (необязательно)')"
                  :placeholder="$t('Ref номер (необязательно)')"
                  :hint="$t('Уникальный номер группы / вспомогательный номер для ваших целей')"
                  persistent-hint
                  hide-details="auto"
                ></v-text-field>
                <v-text-field
                  v-model="order.guideName"
                  type="text"
                  outlined
                  dense
                  class="mt-2 mb-6"
                  :label="$t('Имя гида')"
                  :placeholder="$t('Имя гида')"
                  hide-details="auto"
                ></v-text-field>
                <v-text-field
                  v-model="order.guidePhone"
                  type="text"
                  outlined
                  dense
                  class="mt-2 mb-6"
                  :label="$t('Телефон гида')"
                  :placeholder="$t('Телефон гида')"
                  hide-details="auto"
                ></v-text-field>
                <!-- v-if="museumsList.filter(museum => museum.id == order.museum).hasOwnProperty('name') === 'Радиогид'" -->
                <div
                  v-if="order.museumObject == 148"
                >
                  <h4>
                    {{ $t('В поле "Пожелания" укажите:') }}<br><ul><li>{{ $t('адрес и время получения') }}</li><li>{{ $t('адрес и приблизительное время возврата оборудования') }}</li></ul>
                  </h4>
                  <br>
                </div>
                <v-textarea
                  v-model="order.comment"
                  :label="$t('Пожелания')"
                  rows="3"
                  outlined
                ></v-textarea>
                <v-btn
                  outlined
                  @click="activeStep = 3"
                >
                  {{ $t('Назад') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ms-2"
                  @click="sendOrder"
                >
                  {{ $t('Отправить') }}
                </v-btn>
                <v-dialog
                  v-model="isSending"
                  overlay
                  persistent
                  width="300"
                >
                  <v-card
                    color="primary"
                    dark
                  >
                    <v-card-text class="pt-3 white--text">
                      {{ $t('Пожалуйста, ожидайте') }}
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0 mt-2"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="isShowMessage"
                  width="500"
                >
                  <v-card>
                    <v-card-title v-if="isSuccess">
                      {{ $t('Успешно') }}
                    </v-card-title>
                    <v-card-title
                      v-else
                    >
                      {{ $t('Ошибка') }}
                    </v-card-title>

                    <v-card-text v-if="isSuccess">
                      {{ successMsg }}
                    </v-card-text>
                    <v-card-text v-else>
                      {{ errorMsg }}
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="isSuccess"
                        color="primary"
                        @click="closeMessageBoxAndRedirect"
                      >
                        {{ $t('Ок') }}
                      </v-btn>
                      <v-btn
                        v-else
                        color="error"
                        @click="closeMessageBox"
                      >
                        {{ $t('Ок') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-form>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import Loader from '@/components/Loader.vue'
import store from '@/store'
import { useRouter } from '@core/utils'
import { integerPositiveValidator, required } from '@core/utils/validation'
import { mdiCalendar, mdiHelpCircleOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  components: {
    Loader,
    Datepicker,
  },
  setup() {
    const { router } = useRouter()
    const datepickerKey = ref(0)
    const order = ref({
      date: new Date().toISOString().substr(0, 10),
      tickets: {},
      services: {},
      guides: '0',
      refNumber: '',
      guideName: '',
      guidePhone: '',
      comment: '',
      type: 'all',
    })
    const rerenderKey = ref(1)
    const valid = ref(true)
    const orderForm = ref(null)
    const activeStep = 1
    const userData = JSON.parse(localStorage.getItem('userData'))

    const fetchMuseums = () => {
      store.dispatch('museums/fetchMuseums', order.value.type)
    }
    fetchMuseums()

    const fetchMuseumObjects = () => {
      store.dispatch('museumObjects/fetchMuseumObjects', [order.value.museum, order.value.type])
    }

    const fetchSchedule = () => {
      const formData = {
        date: order.value.date,
        museumObjectId: order.value.museumObject,
      }
      store.dispatch('schedule/fetchSchedule', formData)
    }

    const getCountryByUserValue = selectedMuseumObject => {
      if (userData.default_guide_country !== '') {
        const countryByName = selectedMuseumObject.countries.filter(
          item => item.name === userData.default_guide_country,
        )

        return countryByName[0].system_id
      }

      return ''
    }

    const setMuseumObject = id => {
        datepickerKey.value += 1
        store.dispatch('museumObjects/setSelectedMuseumObject', id).then(selectedMuseumObject => {
            selectedMuseumObject.tickets.forEach(item => {
                order.value.tickets[item.internal_name] = 0
            })
            selectedMuseumObject.services.forEach(item => {
                order.value.services[item.internal_name] = false
            })
            order.value.persons_per_guide = selectedMuseumObject.persons_per_guide
            order.value.system_id = selectedMuseumObject.system_id
            if (userData.use_default_data) {
            order.value.guideName = userData.default_guide_name
            order.value.guidePhone = userData.default_guide_phone
            order.value.country = getCountryByUserValue(selectedMuseumObject)
            }
        })
    }

    const handleDateChanges = event => {
      order.value.date = event.date
      fetchSchedule()
    }

    const sendOrder = () => {
      store.dispatch('createOrder/createOrder', order)
    }

    const closeMessageBoxAndRedirect = () => {
      store.commit('createOrder/close_message')
      router.push({ name: 'upcoming-requests' })
    }
    const closeMessageBox = () => {
      store.commit('createOrder/close_message')
    }
    store.commit('museumObjects/init_selected_museum_object')

    const updateKey = () => {
      rerenderKey.value += 1
    }

    const getFormatedLocale = () => {
        const locale = localStorage.getItem('locale') || 'ru'
        if (locale === 'zh') {
            return '_zh_cn'
        } if (locale === 'en') {
            return '_en_US'
        } if (locale === 'ru') {
            return '_ru_RU'
        } if (locale === 'fa') {
            return '_fa_IR'
        } if (locale === 'vi') {
            return '_vi'
        }

        return '_ru_RU'
    }

    return {
      order,
      orderForm,
      valid,
      activeStep,
      fetchMuseums,
      fetchMuseumObjects,
      fetchSchedule,
      setMuseumObject,
      sendOrder,
      handleDateChanges,
      closeMessageBoxAndRedirect,
      closeMessageBox,
      datepickerKey,
      validators: {
        required,
        integerPositiveValidator,
      },
      rerenderKey,
      updateKey,
      icons: {
        mdiCalendar,
        mdiHelpCircleOutline,
      },
      getFormatedLocale,
      typeList: computed(() => (localStorage.getItem('locale') === 'zh'
        ? {
            '_zh_cn': [{ name: '中国', value: 'cn' }],
        }
        : {
            '_ru_RU': [
                { name: 'Российские туристы', value: 'ru' },
                { name: 'Иностранные туристы', value: 'en' },
                { name: 'Китайские туристы', value: 'cn' }],
            '_en_US': [
                { name: 'Russian tourists', value: 'ru' },
                { name: 'Foreign tourists', value: 'en' },
                { name: 'Chinese tourists', value: 'cn' }],
            '_fa_IR': [
                { name: 'توریست روسی', value: 'ru' },
                { name: 'گردشگران خارجی', value: 'en' },
                { name: 'گردشگران چینی', value: 'cn' }],
            '_vi': [
                { name: 'Du khách Nga', value: 'ru' },
                { name: 'Du khách nước ngoài', value: 'en' },
                { name: 'Du khách Trung Quốc', value: 'cn' }],
        })),
      museumsList: computed(() => store.getters['museums/getMuseumsList']),
      museumObjects: computed(() => store.getters['museumObjects/getMuseumObjectsList']),
      schedule: computed(() => store.getters['schedule/getSchedule'].filter(scheduleItem => !scheduleItem.name.includes('График'))),
      selectedMuseumObject: computed(() => store.getters['museumObjects/getSelectedMuseumObject']),
      isSending: computed(() => store.getters['createOrder/isSending']),
      isShowMessage: computed(() => store.getters['createOrder/isShowMessage']),
      successMsg: computed(() => store.getters['createOrder/successMsg']),
      errorMsg: computed(() => store.getters['createOrder/errorMsg']),
      isSuccess: computed(() => store.getters['createOrder/isSuccess']),
    }
  },
}
</script>
