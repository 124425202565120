<template>
  <v-row>
    <v-col
      cols="12"
      md="8"
    >
      <form
        id="f"
        ref="paymentContent"
        method="post"
        action="https://pay.modulbank.ru/pay"
      >
        <input
          type="hidden"
          name="testing"
          :value="paymentData['testing']"
        ><input
          type="hidden"
          name="order_id"
          :value="paymentData['order_id']"
        ><input
          type="hidden"
          name="amount"
          :value="paymentData['amount']"
        ><input
          type="hidden"
          name="merchant"
          :value="paymentData['merchant']"
        ><input
          type="hidden"
          name="signature"
          :value="paymentData['signature']"
        ><input
          type="hidden"
          name="description"
          :value="paymentData['description']"
        ><input
          type="hidden"
          name="success_url"
          :value="paymentData['success_url']"
        ><input
          type="hidden"
          name="callback_url"
          :value="paymentData['callback_url']"
        ><input
          type="hidden"
          name="client_email"
          :value="paymentData['client_email']"
        ><input
          type="hidden"
          name="unix_timestamp"
          :value="paymentData['unix_timestamp']"
        ><input
          type="hidden"
          name="receipt_contact"
          :value="paymentData['receipt_contact']"
        ><input
          type="hidden"
          name="callback_with_receipt"
          :value="paymentData['callback_with_receipt']"
        ><input
          type="hidden"
          name="receipt_items"
          :value="paymentData['receipt_items']"
        >
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { useRouter } from '@core/utils'
import { ref, onMounted } from '@vue/composition-api'

export default {
    setup(props, context) {
        const paymentData = ref({})
        const paymentContent = ref(null)

        function executeInlineScripts() {
            paymentContent.value.submit()
        }
        const { router } = useRouter()

        onMounted(() => {
            paymentData.value = context.root.$route.query.items
            if (typeof context.root.$route.query.items === 'string') {
                router.push({ name: 'payment' })
            } else {
                setTimeout(executeInlineScripts, 2)
            }
        })

        return {
            paymentData,
            paymentContent,
        }
    },
}
</script>
