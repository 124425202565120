import { isEmpty } from "./index"

export const required = value => {
  const locale = localStorage.getItem("locale") || "ru"

  if (locale === "ru") {
    if (value && value.length) {
      return true
    } else {
      return "Это поле обязательно"
    }
  } else if (locale === "en") {
    if (value && value.length) {
      return true
    } else {
      return "This field is required"
    }
  } else if (locale === "zh") {
    if (value && value.length) {
      return true
    } else {
      return "此字段为必填项"
    }
  } else if (locale === "fa") {
    if (value && value.length) {
      return true
    } else {
      return "این فیلد الزامی است"
    }
  } else if (locale === "vi") {
    if (value && value.length) {
      return true
    } else {
      return "Trường này là bắt buộc"
    }
  }
}
export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }
  const locale = localStorage.getItem("locale") || "ru"

  if (locale === "ru") {
    return re.test(String(value)) || "Поле должно быть валидным email адресом"
  } else if (locale === "en") {
    return re.test(String(value)) || "Field must be a valid email address"
  } else if (locale === "zh") {
    return re.test(String(value)) || "字段必须是有效的电子邮件地址"
  } else if (locale === "fa") {
    return re.test(String(value)) || "فیلد باید یک آدرس ایمیل معتبر باشد"
  } else if (locale === "vi") {
    return re.test(String(value)) || "Trường phải là một địa chỉ email hợp lệ"
  }
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    "Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars"
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || "The Confirm Password field confirmation does not match"

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || "This field must be an integer"
}

export const integerPositiveValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^[0-9]+$/.test(String(val)))
  }

  return /^[0-9]+$/.test(String(value)) || "Это значение должно быть целым и положительным"
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === "string") {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || "The Regex field format is invalid"
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || "The Alpha field may only contain alphabetic characters"
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || "URL is invalid"
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`
}
export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || "All Character is not valid"
}

export const totalPricePositiveValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^[0-9]+$/.test(String(val)))
  }

  const locale = localStorage.getItem("locale") || "ru"
  if (locale === "ru") {
    return /^[0-9]+$/.test(String(value)) || "Введите корректное значение суммы"
  } else if (locale === "en") {
    return /^[0-9]+$/.test(String(value)) || "Enter correct amount value"
  } else if (locale === "zh") {
    return /^[0-9]+$/.test(String(value)) || "输入正确的金额值"
  } else if (locale === "fa") {
    return /^[0-9]+$/.test(String(value)) || "مقدار صحیح را وارد کنید"
  } else if (locale === "vi") {
    return /^[0-9]+$/.test(String(value)) || "Nhập giá trị số tiền chính xác"
  }
}
