<template>
  <v-menu
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="d-flex align-center"
        v-on="on"
      >
        <v-img
          :src="require(`@/assets/images/flags/${$i18n.locale}.webp`)"
          :alt="$i18n.locale"
          height="14px"
          width="22px"
          class="me-2"
        ></v-img>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ locales.find(l => l.locale === $i18n.locale).title }}</span>
      </div>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group
        :value="$i18n.locale"
        @change="updateActiveLocale"
      >
        <v-list-item
          v-for="locale in locales"
          :key="locale.locale"
          :value="locale.locale"
        >
          <v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.locale"
            class="me-2"
          ></v-img>
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import setI18nLanguage from '@/plugins/i18n'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  setup() {
    const { isRtl } = useAppConfig()

    const locales = [
    {
        title: 'Русский',
        img: require('@/assets/images/flags/ru.webp'),
        locale: 'ru',
      },
      {
        title: '中文',
        img: require('@/assets/images/flags/zh.webp'),
        locale: 'zh',
      },
      {
        title: 'English (UK)',
        img: require('@/assets/images/flags/gb.webp'),
        locale: 'en',
      },
      {
        title: 'Tiếng Việt',
        img: require('@/assets/images/flags/vi.webp'),
        locale: 'vi',
      },
      {
        title: 'فارسی',
        img: require('@/assets/images/flags/fa.webp'),
        locale: 'fa',
      },

    //   {
    //     title: 'Hindi',
    //     img: require('@/assets/images/flags/hi.webp'),
    //     locale: 'hi',
    //   },
    //   {
    //     title: 'Turkish',
    //     img: require('@/assets/images/flags/tr.webp'),
    //     locale: 'tr',
    //   },
    //   {
    //     title: 'Japanese',
    //     img: require('@/assets/images/flags/ja.webp'),
    //     locale: 'ja',
    //   },
    //   {
    //     title: 'Korean',
    //     img: require('@/assets/images/flags/ko.webp'),
    //     locale: 'ko',
    //   },
    ]

    const updateActiveLocale = locale => {
        isRtl.value = locale === 'fa'
        setI18nLanguage(locale)
    }

    return {
      locales,
      updateActiveLocale,
    }
  },
}
</script>
