import axios from "@axios"

export default {
  namespaced: true,
  state: {
    ads: [],
    request_is_success: null,
    request_error_msg: null,
  },
  getters: {
    getAds: state => state.ads,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
  },
  mutations: {
    set_on_request_success(state, payload) {
      state.ads = payload
      state.request_is_success = true
      state.request_error_msg = null
    },
    set_on_request_error(state, error) {
      state.ads = []
      state.request_is_success = false
      state.request_error_msg = error
    },
  },
  actions: {
    fetchAds({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: "/api/v1/adverts/",
        })
          .then(response => {
            if (response.status === 200) {
                if (response.data.length > 0) {
                    // add type: 'ads' to each ad
                    response.data.forEach(ad => {
                        // eslint-disable-next-line no-param-reassign
                        ad.type = 'ad'
                    })
                }
              commit("set_on_request_success", response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
