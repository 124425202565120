<template>
  <v-row>
    <!-- Заголовок -->
    <v-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      cols="12"
    >
      <h2 class="secondary--text mb-4">
        {{$t('Продление лицензий')}}
      </h2>
    </v-col>
    <!-- loader -->
    <v-row v-if="!licensesRequestIsSuccess">
      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="10"
        cols="12"
      >
        <loader></loader>
      </v-col>
    </v-row>
    <!-- Список музеев для продления -->
    <v-row v-else>
      <v-col
        v-for="(museumData, museumName) in prolongationData['aggregated_data']"
        :key="museumName"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        cols="12"
      >
        <prolongation-card
          v-if="museumData.groups_data"
          :key="museumName"
          :museum-data="museumData"
          :groups-data="museumData.groups_data"
          :museum-name="museumName"
        ></prolongation-card>
        <prolongation-card
          v-else
          :key="museumName"
          :museum-data="museumData"
          :museum-name="museumName"
        ></prolongation-card>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Loader from '@/components/Loader.vue'
import ProlongationCard from '@/components/ProlongationCard.vue'
import store from '@/store'
import { mdiCheckBold, mdiInformationOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

import { formatDate } from '@/utils/filter'

export default {
    components: {
        Loader,
        ProlongationCard,
    },
    setup() {
        const isDialogVisiblePolongateLicense = ref(false)

        const fetchProlongationPageData = () => {
            store.dispatch('licenses/fetchProlongationPageData')
        }

        fetchProlongationPageData()

        const prolongateLicense = (museumId, licenseId) => {
            store.dispatch('licenses/setLicenseProlongate', { license_id: licenseId }).then(() => {
                isDialogVisiblePolongateLicense.value = false
                fetchProlongationPageData()
            })
        }

        return {
            icons: {
                mdiInformationOutline,
                mdiCheckBold,
            },
            isDialogVisiblePolongateLicense,
            prolongateLicense,
            fetchProlongationPageData,
            formatDate,
            licensesList: computed(() => store.getters['licenses/getLicenses']),
            licensingObjects: computed(() => store.getters['licenses/getLicensingObjects']),
            licensesPrice: computed(() => store.getters['licenses/getPricelist']),
            prolongationsGroups: computed(() => store.getters['licenses/getLicenseProlongationsGroups']),
            licensesRequestIsSuccess: computed(() => store.getters['licenses/getReqStatus']),
            prolongationData: computed(() => store.getters['licenses/getProlongationData']),
        }
    },
}
</script>
