export default {

    // меню
    'АТГ. Заявки': 'ATG. Orders',
    Заказы: 'Orders',
    'Новый заказ': 'New order',
    Архив: 'Archive',
    Отчеты: 'Reports',
    Сверка: 'Reconciliation',
    Оплата: 'Payment',
    'Оплата заявок': 'Orders payment',
    'История платежей': 'Payment history',
    'Прайс-лист': 'Price list',
    'Продление лицензий': 'License renewal',
    'Мои лицензии': 'My licenses',
    Продление: 'Renewal',
    Информация: 'Information',
    Новости: 'News',
    'О компании': 'About company',
    Договор: 'Contract',
    'Договор оферты': 'Contract offer',
    'Политика возврата': 'Refund policy',
    'Поиск по ref, номеру заявки, дате или объекту': 'Search by ref, order number, date or object',
    Настройки: 'Settings',
    Помощь: 'Help',
    Выйти: 'Logout',
    'Старая версия сайта': 'Old version of the site',
    'ООО «АВАНГАРД ТРАВЕЛ ГРУПП»': 'LLC "AVANGARD TRAVEL GROUP"',

    // заказы
    'Нет предстоящих заказов': 'No upcoming orders',
    Заявка: 'Order',
    'В обработке': 'In processing',
    'Подтврждена': 'Confirmed',
    'Лист ожидания': 'Waiting list',
    'Аннулирована': 'Cancelled',
    'Отклонена': 'Rejected',
    'Подтвержденное время:': 'Confirmed time:',
    'Вход': 'Entrance',
    'ПОДРОБНЕЕ': 'DETAILS',
    'ИЗМЕНИТЬ': 'EDIT',
    'ГДЕ СОТРУДНИК': 'WHERE IS THE EMPLOYEE',
    'АННУЛИРОВАТЬ': 'CANCEL',
    'В АРХИВ': 'TO ARCHIVE',
    'Сотрудник на объекте': 'Employee on site',
    'Сотрудник не на объекте': 'Employee not on site',
    'Но вы можете связаться с нами:': 'But you can contact us:',
    'Аннулировать': 'Cancel',
    'Хотите аннулировать заявку?': 'Do you want to cancel the order?',
    'Аннулированная заявка попадет в архив и восстановить ее будет невозможно': 'The canceled order will go to the archive and it will not be possible to restore it',
    'Отмена': 'No',
    'Дата и время:': 'Date and time:',
    'Имя гида:': 'Guide name:',
    'Не заполнено': 'Not filled',
    'Телефон гида:': 'Guide phone:',
    'Пожелания:': 'Wishes:',
    'Подтвержденное время и вход:': 'Confirmed time and entrance:',
    'Билеты:': 'Tickets:',
    'Дополнительные услуги:': 'Additional services:',
    'Причина отклонения:': 'Reason for rejection:',
    'Информация о группе:': 'Group information:',
    'Доп.услуги:': 'Additional services:',
    'Доп.информация:': 'Additional information:',

    // новый заказ
    'Информация о музее': 'Museum information',
    'Выберите категорию группы (РФ или ИГ), музей, дату и время': 'Select a group category (RF or IG), museum, date and time',
    'Выберите категорию группы': 'Select a group category',
    'Выберите музей': 'Select a museum',
    'Выберите объект': 'Select an object',
    Далее: 'Next',
    'Выберите дату': 'Select a date',
    'Выберите время': 'Select a time',
    'Информация о группе': 'Group information',
    'Выберите кол-во билетов': 'Select the number of tickets',
    'Выберите страну': 'Select a country',
    Назад: 'Back',
    'Доп. услуги': 'Additional services',
    'Выберите дополнительные услуги': 'Select additional services',
    'Кол-во гидов сопровождающих (если нет лицензии)': 'Number of accompanying guides (if no license)',
    'Необходим если у гида нет лицензии в выбранный музей': 'Required if the guide does not have a license in the selected museum',
    'Доп. информация': 'Additional information',
    'Информация о гиде и пожелания': 'Guide information and wishes',
    'Ref номер (необязательно)': 'Ref number (optional)',
    'Уникальный номер группы / вспомогательный номер для ваших целей': 'Unique group number / auxiliary number for your purposes',
    'Имя гида': 'Guide name',
    'Телефон гида': 'Guide phone',
    Пожелания: 'Wishes',
    'В поле "Пожелания" укажите:': 'In the "Wishes" field, specify:',
    'адрес и время получения': 'address and time of receipt',
    'адрес и приблизительное время возврата оборудования': 'address and approximate time of return of equipment',
    Отправить: 'Send',
    'Пожалуйста, ожидайте': 'Please wait',
    Успешно: 'Successfully',
    Ошибка: 'Error',
    Ок: 'Ok',

    // архив
    'Номер заявки или ref': 'Order number or ref',
    Объект: 'Object',
    'Номер заявки': 'Order number',
    Все: 'All',
    'Дата посещения с': 'Visit date from',
    'Дата посещения по': 'Visit date to',
    Найти: 'Find',
    'Заказов согласно фильтру не найдено': 'Orders according to the filter were not found',
    'Ошибка:': 'Error:',
    'Любой номер': 'Any number',

    // сверка
    Сформировать: 'Generate',
    'Файл сверки': 'Reconciliation file',
    'Для скачивания сформированного файла сверки нажмите на конпку "Скачать".': 'To download the generated reconciliation file, click on the "Download" button.',
    'Скачать': 'Download',

    // оплата заявок
    'Оплатить заявки': 'Pay orders',
    'Для оплаты заказов введите номера ваучеров и укажите точную сумму для оплаты:': 'To pay for orders, enter the voucher numbers and specify the exact amount to pay:',
    'Номера ваучеров или заявок для оплаты (через запятую)': 'Voucher numbers or orders to pay (comma separated)',
    'Введите номера ваучеров или заявок для оплаты': 'Enter voucher numbers or orders to pay',
    'ФИО или название организации': 'Full name or organization name',
    'Введите ФИО или название организации': 'Enter full name or organization name',
    Email: 'Email',
    'Введите email': 'Enter Email',
    'Сумма к оплате': 'Amount to pay',
    'Введите сумму к оплате': 'Enter the amount to pay',
    'Согласен с договором оферты': 'I agree with the contract offer',
    'Перейти к оплате': 'Go to payment',
    'Подождите,': 'Wait,',
    'принимаем': 'receive',
    'оплату...': 'payment...',
    'Заказ успешно оплачен!': 'Order successfully paid!',
    'Ваш заказ': 'Your order',
    'успешно оплачен. Спасибо за покупку!': 'successfully paid. Thank you for your purchase!',
    'Что-то пошло не так!': 'Something went wrong!',
    'При оплате заказа': 'When paying for the order',
    'произошла ошибка!': 'an error occurred!',
    'Cчет успешно сформирован!': 'Invoice successfully generated!',
    'Вы можете открыть его нажав кнопку ниже': 'You can open it by clicking the button below',
    'Открыть счет': 'Open invoice',
    'Физическим лицам': 'Individuals',
    'Юридическим лицам': 'Legal entity',
    'Для формирования счета на оплату введите номера ваучеров или заказов, укажите реквизиты компании и точную сумму:': 'To generate an invoice for payment, enter the voucher numbers or orders, specify the company details and the exact amount:',
    'Для оплаты введите номера ваучеров или заявок и укажите точную сумму для оплаты:': 'To pay, enter the voucher numbers or orders and specify the exact amount to pay:',
    'Название организации': 'Organization name',
    'Юридический адрес': 'Legal address',
    'Сформировать счет': 'Generate invoice',

    // история платежей
    'Номер платежа': 'Payment number',
    Ваучеры: 'Vouchers',
    'Способ оплаты': 'Payment method',
    'Время и дата оплаты': 'Payment time and date',
    Сумма: 'Amount',
    Статус: 'Status',
    'Платежи отсутствуют': 'No payments',

    // прайс-лист
    'Прайс-лист для иностранных групп': 'Price list for foreign groups',
    'Актуальный файл с ценами для иностранных групп.': 'Actual file with prices for foreign groups.',
    Открыть: 'Open',
    'Прайс-лист для российских групп': 'Price list for Russian groups',
    'Актуальный файл с ценами для российских групп.': 'Actual file with prices for Russian groups.',

    // лицензии
    'Добавить лицензию': 'Add license',
    'В настоящий момент нет сохраненных лицензий, вы можете добавить их.': 'There are currently no saved licenses, you can add them.',
    'Добавить': 'Add',
    'Музей': 'Museum',
    'Номер лицензии': 'License number',
    'Стаж (лет)': 'Experience (years)',
    'Дата окончания': 'Expiration date',
    'Основной язык': 'Main language',
    'Дополнительные языки': 'Additional languages',

    // о компании
    'ИНН:7841027155': 'ИНН:7841027155',
    'КПП: 784101001': 'КПП: 784101001',
    'ОГРН: 1157847276003': 'ОГРН: 1157847276003',
    'ОКПО: 25885413': 'ОКПО: 25885413',
    'Расчетный счет: 40702810603260000188': 'Расчетный счет: 40702810603260000188',
    'Банк: Филиал "Центральный" Банка ВТБ (ПАО)': 'Банк: Филиал "Центральный" Банка ВТБ (ПАО)',
    'БИК: 044525411': 'БИК: 044525411',
    'Корр. счет: 30101810145250000411': 'Корр. счет: 30101810145250000411',
    'Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405': 'Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405',
    'Email: info@avangard-travel.ru': 'Email: info@avangard-travel.ru',
    'Телефон: (812) 640-02-28': 'Phone: (812) 640-02-28',

    // договор
    'Вы можете посмотреть договор с ООО "Авангард Травел Групп".': 'You can view the contract with Avangard Travel Group LLC.',

    // договор оферты
    'Вы можете посмотреть договор оферты с ООО "Авангард Травел Групп".': 'You can view the contract offer with Avangard Travel Group LLC.',

    // политика возврата
    'Вы можете посмотреть политику возврата.': 'You can view the refund policy.',

    // настройки
    'Имя:': 'Name:',
    'Телефон:': 'Phone:',
    'Страна:': 'Country:',
    'Информация о гиде': 'Guide information',
    'Заполните информацию и подавайте заявки еще быстрее': 'Fill in the information and submit applications even faster',
    Имя: 'Name',
    Телефон: 'Phone',
    'Выберите страницу': 'Select a country',
    'Использовать в заказе?': 'Use in order?',
    Сохранить: 'Save',
    'Являетесь ли вы туристической фирмой / юридическим лицом': 'Are you a travel agency / legal entity',
    'Юридическое лицо': 'Legal entity',
    'Для выставления счетов': 'For invoicing',

    // форма логина
    Логин: 'Login',
    Пароль: 'Password',
    Войти: 'Login',
    'Выберите язык': 'Select language',

    // уведомление
    'Теперь можно оплатить заявки онлайн, включая оплату от юридических лиц!': 'Now you can pay for applications online, including payment from legal entities!',
    'Мы рады сообщить, что теперь вы можете оплатить заявки на нашем сайте, используя банковскую карту или QR-код системы быстрых платежей. Вдобавок к этому, мы расширили наши возможности и теперь доступна оплата и для юридических лиц. Юридические лица могут самостоятельно генерировать счета для оплаты.': 'We are pleased to announce that now you can pay for applications on our website using a bank card or QR code of the fast payment system. In addition to this, we have expanded our capabilities and now payment is also available for legal entities. Legal entities can independently generate invoices for payment.',
    'Никаких задержек и лишних хлопот - оплата проходит мгновенно. Перейдите в раздел "Оплата заявок" и оплачивайте свои заявки быстро и удобно! Наши новые возможности сделают процесс ещё более удобным и эффективным.': 'No delays and unnecessary hassle - payment is instant. Go to the "Payments" section and pay for your applications quickly and conveniently! Our new features will make the process even more convenient and efficient.',
    Закрыть: 'Close',

    // помощь
    'У вас есть вопросы?': 'Do you have any questions?',
    'Если вы не можете найти ответ на вопрос, вы всегда можете связаться с нами. Мы ответим вам в ближайшее время!': 'If you cannot find the answer to your question, you can always contact us. We will answer you as soon as possible!',
    'Мы всегда рады помочь!': 'We are always happy to help!',
    'Лучший способ получить ответ быстрее!': 'The best way to get an answer faster!',
}
