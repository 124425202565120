<template>
  <v-row>
    <v-col
      cols="12"
      md="8"
    >
    </v-col>
  </v-row>
</template>

  <script>

  export default {
      setup() {
          return {}
      },
  }
  </script>
