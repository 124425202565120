var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(!_vm.upcomingRequestIsSuccess)?_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"10","cols":"12"}},[_c('loader')],1):_vm._e(),(_vm.upcomingRequestIsSuccess)?_c('ServiceSelector'):_vm._e(),(_vm.upcomingRequestIsSuccess && _vm.upcomingList.length == 0)?_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"11","cols":"12"}},[_c('h2',{staticClass:"secondary--text mt-4 mb-8"},[_vm._v(" "+_vm._s(_vm.$t("Нет предстоящих заказов"))+" ")])]):_vm._e(),(_vm.upcomingRequestIsSuccess && _vm.upcomingList.length > 0)?_c('v-row',_vm._l((_vm.combinedList()),function(request){return _c('v-col',{key:request.id,attrs:{"xs":"12","sm":"12","md":"12","lg":"11","cols":"12"}},[(request.type !== 'ad' &&
          !(
            _vm.museumObjectsInfo[request.data.museum_id]['is_radioguide_request'] ||
            _vm.museumObjectsInfo[request.data.museum_id]['is_audioguide_request']
          ) && (request.info.museum_schedule_item.includes('График'))
        )?_c('OrderSchedule',{key:request.id,attrs:{"order":request,"disable-actions":[],"object-info":_vm.museumObjectsInfo[request.data.museum_id]}}):_vm._e(),(request.type !== 'ad' &&
          !(
            _vm.museumObjectsInfo[request.data.museum_id]['is_radioguide_request'] ||
            _vm.museumObjectsInfo[request.data.museum_id]['is_audioguide_request']
          ) && !(request.info.museum_schedule_item.includes('График'))
        )?_c('Order',{key:request.id,attrs:{"order":request,"disable-actions":[],"object-info":_vm.museumObjectsInfo[request.data.museum_id]}}):_vm._e(),( request.type !== 'ad' &&
          ( _vm.museumObjectsInfo[request.data.museum_id]['is_radioguide_request'] ||
            _vm.museumObjectsInfo[request.data.museum_id]['is_audioguide_request'])
        )?_c('OrderRgAg',{key:request.id,attrs:{"order":request,"disable-actions":[],"object-info":_vm.museumObjectsInfo[request.data.museum_id]}}):_vm._e(),(request.type === 'ad' && request.is_active)?_c('Ad',{key:request.id,attrs:{"ad":request}}):_vm._e()],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }