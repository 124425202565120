<template>
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    persistent
  >
    <!-- Waiting -->
    <v-card
      v-if="isWaiting"
      class="user-edit-info icons-card  pa-sm-10 pa-3"
    >
      <v-card-title class="justify-center text-h5">
        {{$t('Подождите,')}}&nbsp;<nobr>{{$t('принимаем')}}</nobr>&nbsp;{{$t('оплату...')}}
      </v-card-title>
      <v-col
        cols="12"
        class="d-flex justify-center mt-3"
      >
        <v-icon
          size="30"
          class="icon-spinner"
          x-large
        >
          {{ mdiLoading }}
        </v-icon>
      </v-col>
    </v-card>
    <!-- Success -->
    <v-card
      v-if="!isWaiting && isSuccess"
      class="user-edit-info pa-sm-10 pa-3"
    >
      <v-card-title class="justify-center text-h5">
        {{$t('Заказ успешно оплачен!')}}
      </v-card-title>
      <v-card-text class="text-center mt-2">
        {{$t('Ваш заказ')}}<b>{{ paymentId }}</b>{{$t('успешно оплачен. Спасибо за покупку!')}}
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onPaymentsHistory"
              >
              {{$t('История платежей')}}
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="onNewPayment"
              >
              {{$t('Оплатить заявки')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- Error -->
    <v-card
      v-if="!isWaiting && isError"
      class="user-edit-info pa-sm-10 pa-3"
    >
      <v-card-title class="justify-center text-h5">
        {{$t('Что-то пошло не так!')}}
      </v-card-title>
      <v-card-text class="text-center mt-2">
        {{$t('При оплате заказа')}}<b>{{ paymentId }}</b>{{$t('произошла ошибка!')}}<br><b>{{ errorText }}</b>
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onNewPayment"
              >
              {{$t('Оплатить заявки')}}
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="onPaymentsHistory"
              >
              {{$t('История платежей')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiLoading } from '@mdi/js'
import { useRouter } from '@core/utils'

export default {
  props: {
    paymentId: {
      type: String,
      required: true,
    },
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    isWaiting: {
      type: Boolean,
      required: true,
    },
    isSuccess: {
      type: Boolean,
      required: true,
    },
    isError: {
      type: Boolean,
      required: true,
    },
    errorText: {
        type: String,
        required: true,
    },
  },

  setup() {
    const { router } = useRouter()

    const onPaymentsHistory = () => {
        router.push({ name: 'payments-history' })
    }
    const onNewPayment = () => {
        router.push({ name: 'payment' })
    }

    return {
      onPaymentsHistory,
      onNewPayment,
      mdiLoading,
    }
  },
}
</script>

<style>
    .icon-spinner {
        animation: spin-animation 1s infinite;
        display: inline-block;
    }

    @keyframes spin-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }
</style>
