import axios from "@axios"

function generateFourDigitRefNo() {
  return Math.floor(1000 + Math.random() * 9000)
}

export default {
  namespaced: true,
  state: {
    /**
     * is_sending - отправляется ли в данный момент заявка
     * is_show_message - показывать ли сообщение о результате отправки
     * request_is_success - успешно ли создана заявка
     * request_success_msg - текст сообщения о успешном создании заявки
     * request_error_msg - текст сообщения об ошибке
     */
    is_sending: false,
    is_show_message: false,
    request_is_success: null,
    request_success_msg: null,
    request_error_msg: null,
  },
  getters: {
    isSending: state => state.is_sending,
    isShowMessage: state => state.is_show_message,
    successMsg: state => state.request_success_msg,
    errorMsg: state => state.request_error_msg,
    isSuccess: state => state.request_is_success,
  },
  mutations: {
    start_order_sending(state) {
      state.is_sending = true
    },
    set_on_request_success(state, id) {
      if (state.request_success_msg === null) {
        state.request_success_msg = `№${id}`
      } else {
        state.request_success_msg += `, №${id}`
      }
      state.request_is_success = true
      state.is_sending = false
      state.is_show_message = true
      state.request_error_msg = null
    },
    close_message(state) {
      state.is_show_message = false
    },
    set_on_request_error(state, error) {
      state.request_is_success = false
      state.is_sending = false
      state.is_show_message = true
      state.request_error_msg = error
    },
  },
  actions: {
    createOrder({ commit }, formData) {
      commit("start_order_sending")

      return new Promise((resolve, reject) => {
        const data = JSON.stringify({
          refno: formData.value.refNumber === "" ? new Date().getTime() : formData.value.refNumber,
          museum_id: formData.value.museum,
          system_id: formData.value.system_id,
          pass_date: formData.value.date,
          schedule_item_id: formData.value.schedule,
          persons_per_guide: formData.value.persons_per_guide,
          country_id: formData.value.country,
          guide: formData.value.guideName,
          guide_phone: formData.value.guidePhone,
          tickets: formData.value.tickets,
          services: formData.value.services,
          qty_guide: formData.value.guides,
          notes: formData.value.comment,
          type: formData.value.type,
        })
        const postData = new FormData()
        postData.append("data", data)
        postData.append("action", "post_request")
        axios({
          method: "post",
          url: "/api/v1/actions/",
          data: postData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(response => {
            if (response.status === 200) {
              if (response.data.success) {
                commit("set_on_request_success", response.data.data.result.id)
                resolve(response)
              } else {
                commit("set_on_request_error", response.data.error)
                resolve(response)
              }
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createOrderForRgAg({ commit }, formDataArray) {
      commit("start_order_sending")

      // Function to process each formData item
      const processFormData = formData => {
        const refno = generateFourDigitRefNo()
        let fullNote = ""
        if (formData.receiving !== "") {
          fullNote += `Получение: ${formData.receiving}; `
        }
        if (formData.returning !== "") {
          fullNote += `Возврат: ${formData.returning}; `
        }
        if (formData.comment !== "") {
          fullNote += `${formData.comment}`
        }

        const data = JSON.stringify({
          refno,
          museum_id: formData.museum,
          system_id: formData.museum,
          pass_date: formData.date,
          schedule_item_id: formData.schedule,
          persons_per_guide: formData.persons_per_guide,
          country_id: 100,
          guide: formData.guideName,
          guide_phone: formData.guidePhone,
          tickets: formData.tickets,
          qty_guide: formData.guides,
          notes: fullNote,
          type: formData.type,
        })

        const postData = new FormData()
        postData.append("data", data)
        postData.append("action", "post_request_rg_ag")

        return axios({
          method: "post",
          url: "/api/v1/actions/",
          data: postData,
          headers: { "Content-Type": "multipart/form-data" },
        })
      }

      // Process all form data items
      return Promise.all(formDataArray.map(item => processFormData(item)))
        .then(responses => {
          responses.forEach(response => {
            if (response.status === 200) {
              if (response.data.success) {
                commit("set_on_request_success", response.data.data.result.id)
              } else {
                commit("set_on_request_error", response.data.error)
              }
            }
          })

          return responses
        })
        .catch(error => {
          // Handle the error appropriately
          console.error("An error occurred:", error)
          throw error
        })
    },
    createOrderForSheduleRequest({ commit }, formDataArray) {
      commit("start_order_sending")
      console.log(formDataArray)

      const processFormData = formData => {
        let countryId = 267
        let fullNote = "ГРАФИК. "
        if (formData.groupType === 'cn') {
            countryId = 100
            if (formData.services.ag === true) {
                fullNote += "АГ: Да, "
            } else {
                fullNote += "АГ: Нет, "
            }
            if (formData.services.rg === true) {
                fullNote += "РГ: Да, "
            } else {
                fullNote += "РГ: Нет, "
            }
            fullNote += `Гид c лицензией: ${formData.services.license}, `
            fullNote += `Сопровождающий: ${formData.services.sopr}. `
        }

        const data = JSON.stringify({
          refno: formData.refNumber === "" ? new Date().getTime() : formData.refNumber,
          museum_id: formData.museum,
          system_id: formData.museum,
          pass_date: formData.date,
          schedule_item_id: formData.schedule,
          persons_per_guide: formData.persons_per_guide,
          country_id: countryId,
          guide: formData.guideName,
          guide_phone: formData.guidePhone,
          adult: formData.tickets.adult,
          child: formData.tickets.child,
          qty_guide: formData.guides,
          notes: fullNote,
        })

        const postData = new FormData()
        postData.append("data", data)
        postData.append("action", "post_request_schedule")

        return axios({
          method: "post",
          url: "/api/v1/actions/",
          data: postData,
          headers: { "Content-Type": "multipart/form-data" },
        })
      }

      // Process all form data items
      return Promise.all(formDataArray.map(item => processFormData(item)))
        .then(responses => {
          responses.forEach(response => {
            if (response.status === 200) {
              if (response.data.success) {
                console.log(response.data.data.result.id)
                commit("set_on_request_success", response.data.data.result.id)
              } else {
                commit("set_on_request_error", response.data.error)
              }
            }
          })

          return responses
        })
        .catch(error => {
          // Handle the error appropriately
          console.error("An error occurred:", error)
          throw error
        })
    },
    createOrderForTransportRequest({ commit }, formDataArray) {
      commit("start_order_sending")

      const processFormData = formData => {
        // console.log('formData', formData)
        const countryId = 100
        let fullNote = "Заявка на транспорт. "
        fullNote += `Время начала: ${formData.startTime}, `
        fullNote += `Время окончания: ${formData.endTime}, `
        fullNote += `Адрес начала: ${formData.startAddress}, `
        fullNote += `Адрес окончания: ${formData.endPlace}, `
        fullNote += `Описание маршрута: ${formData.routeDescription}.`

        const data = JSON.stringify({
          refno: new Date().getTime(),
          museum_id: formData.transportType,
          system_id: formData.transportType,
          pass_date: formData.date,
          schedule_item_id: formData.scheduleId,
          persons_per_guide: formData.persons_per_guide,
          country_id: countryId,
          guide: formData.guideName,
          guide_phone: formData.guidePhone,
          adult: formData.tickets.adult,
          child: formData.tickets.child,
          qty_guide: '0',
          notes: fullNote,
        })

        const postData = new FormData()
        console.log('data', data)
        postData.append("data", data)
        postData.append("action", "post_request_transport")

        return axios({
          method: "post",
          url: "/api/v1/actions/",
          data: postData,
          headers: { "Content-Type": "multipart/form-data" },
        })
      }

      // Process all form data items
      return Promise.all(formDataArray.map(item => processFormData(item)))
        .then(responses => {
          responses.forEach(response => {
            if (response.status === 200) {
              if (response.data.success) {
                console.log(response.data.data.result.id)
                commit("set_on_request_success", response.data.data.result.id)
              } else {
                commit("set_on_request_error", response.data.error)
              }
            }
          })

          return responses
        })
        .catch(error => {
          // Handle the error appropriately
          console.error("An error occurred:", error)
          throw error
        })
    },
    createOrderForGuideRequest({ commit }, formDataArray) {
      commit("start_order_sending")

      const processFormData = formData => {
        const countryId = 100
        let fullNote = "Заявка на гида. "
        fullNote += `Время встречи: ${formData.startTime}, `
        fullNote += `Время окончания: ${formData.finishTime}, `
        fullNote += `Место встречи: ${formData.startAddress}, `
        fullNote += `Место окончания: ${formData.finishAddress}, `
        fullNote += `Пожелания: ${formData.notes}.`

        const data = JSON.stringify({
          refno: new Date().getTime(),
          museum_id: formData.language,
          system_id: formData.language,
          pass_date: formData.date,
          schedule_item_id: formData.scheduleId,
          persons_per_guide: formData.persons_per_guide,
          country_id: countryId,
          guide: formData.guideName,
          guide_phone: formData.guidePhone,
          adult: formData.tickets.adult,
          child: formData.tickets.child,
          qty_guide: '0',
          notes: fullNote,
        })

        const postData = new FormData()
        console.log('data', data)
        postData.append("data", data)
        postData.append("action", "post_request_guide")

        return axios({
          method: "post",
          url: "/api/v1/actions/",
          data: postData,
          headers: { "Content-Type": "multipart/form-data" },
        })
      }

      // Process all form data items
      return Promise.all(formDataArray.map(item => processFormData(item)))
        .then(responses => {
          responses.forEach(response => {
            if (response.status === 200) {
              if (response.data.success) {
                console.log(response.data.data.result.id)
                commit("set_on_request_success", response.data.data.result.id)
              } else {
                commit("set_on_request_error", response.data.error)
              }
            }
          })

          return responses
        })
        .catch(error => {
          // Handle the error appropriately
          console.error("An error occurred:", error)
          throw error
        })
    },
  },
}
