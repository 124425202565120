<template>
  <v-card class="overflow-hidden">
    <v-row class="ma-0 h-full">
      <!-- info -->
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        order="1"
        order-lg="1"
      >
        <v-card-title class="align-start">
          <span class="text-xl font-weight-semibold">
            {{ $t('Заявка') }} #{{ order.id }}
          </span>
          <v-spacer></v-spacer>
          <span
            :class="'fix-state-width text-2xl font-weight-bold ' + getOrderStatusColor(order.state, order.is_locked)"
          >
            {{ $t(getOrderStatusTitle(order.state)) }}
          </span>
        </v-card-title>
        <v-card-title class="no-break text-2xl py-4">
          {{ objectInfo.museum['name' + getFormatedLocale()] }}
        </v-card-title>
        <v-card-subtitle class="no-break pt-2 pb-4">
          {{ objectInfo['name' + getFormatedLocale()] }}
        </v-card-subtitle>

        <div v-if="order.state == 2">
          <v-divider class="pb-2"></v-divider>
          <p class="mb-1 pt-1 px-4 order_status-accepted-green">
            {{ $t('Подтвержденное время:') }} {{ order.info.pass_time.substring(1) }}
          </p>
          <p class="pt-1 px-4 order_status-accepted-green">
            {{ $t('Вход:') }}
            {{ order.info.entrance_name }}
          </p>
          <!-- <v-card-subtitle class="no-break py-4 order_status-accepted-green"> -->
          <!-- </v-card-subtitle> -->
        </div>
        <v-divider class="pb-2"></v-divider>

        <v-card-actions class="d-flex justify-center pb-4">
          <v-row>
            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="4"
              xs="12"
              class="py-2"
            >
              <p class="d-flex align-center mb-0">
                <v-icon color="secondary">
                  {{ icons.mdiCalendar }}
                </v-icon>
                <span class="no-break ms-3">{{ formatDate(order.data.pass_date) }}</span>
              </p>
            </v-col>
            <v-divider
              v-if="$vuetify.breakpoint.smAndUp"
              vertical
            >
            </v-divider>
            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="4"
              xs="12"
              class="py-2"
            >
              <p class="d-flex align-center mb-0">
                <v-icon color="secondary">
                  {{ icons.mdiClockOutline }}
                </v-icon>
                <span class="ms-3">{{ order.info.museum_schedule_item }}</span>
              </p>
            </v-col>
            <v-divider
              v-if="$vuetify.breakpoint.smAndUp"
              vertical
            >
            </v-divider>
            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="4"
              xs="12"
              class="py-2"
            >
              <p class="d-flex align-center mb-0">
                <v-icon color="secondary">
                  {{ icons.mdiAccountGroup }}
                </v-icon>
                <span class="ms-3">{{ getQtyTotal(order) }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-col>
      <!-- actions -->
      <v-col
        cols="12"
        sm="12"
        md="3"
        lg="3"
        order="2"
        order-lg="2"
        class="memberpricing-bg"
      >
        <OrderActions
          :order="order"
          :object-info="objectInfo"
          :disabled-actions="disableActions"
          @refetch-upcoming="refetchUpcoming"
        ></OrderActions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { formatDate } from '@/utils/filter'
import { getOrderStatusColor, getOrderStatusTitle, getQtyTotal } from '@/utils/helper'
import { getCurrentInstance } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import OrderActions from '@/components/OrderActions.vue'
// eslint-disable-next-line object-curly-newline
import { mdiAccountGroup, mdiCalendar, mdiCheckboxMarked, mdiClockOutline, mdiCloseBox } from '@mdi/js'

export default {
  components: {
    OrderActions,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    objectInfo: {
      type: Object,
      required: true,
    },
    disableActions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const refetchUpcoming = () => {
      vm.$parent.fetchUpcoming()
    }
    const getFormatedLocale = () => {
        const locale = localStorage.getItem('locale') || 'ru'
        if (locale === 'zh') {
            return '_zh_cn'
        } if (locale === 'en') {
            return '_en_US'
        } if (locale === 'ru') {
            return '_ru_RU'
        } if (locale === 'fa') {
            return '_fa_IR'
        } if (locale === 'vi') {
            return '_vi'
        }

return '_ru_RU'
    }

    return {
      getQtyTotal,
      formatDate,
      getOrderStatusTitle,
      getOrderStatusColor,
      refetchUpcoming,
      getFormatedLocale,
      icons: {
        mdiCalendar,
        mdiClockOutline,
        mdiAccountGroup,
        mdiCheckboxMarked,
        mdiCloseBox,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(decline-bg) using ($material) {
  background-color: rgba(251, 218, 220, 0.25);
}

// membership pricing
@include theme--child(accept-bg) using ($material) {
  background-color: rgba(247, 250, 240, 0.5);
}
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}
.no-break {
  word-break: normal;
}
.fix-state-width {
  min-width: 190px;
}
.order-status-new {
  color: #8a8d93;
}
.order_status-accepted-green {
  color: #82bb3d;
}
.order-status-accepted-blue {
  color: #33b4ff;
}
.order-status-waiting {
  color: #ffb400;
}
.order-status-decline {
  color: #ff4c51;
}
</style>
