<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <div class="d-flex justify-end mb-8">
        <div class=" d-flex align-center text me-6">
          {{ $t("Выберите язык") }}
        </div>
        <v-menu
          offset-y
          nudge-bottom="22"
          min-width="175"
          left
          :elevation="$vuetify.theme.dark ? 9 : 8"
        >
          <!-- Activator -->
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="d-flex align-center activator-button"
              v-on="on"
            >
              <v-img
                :src="require(`@/assets/images/flags/${$i18n.locale}.webp`)"
                :alt="$i18n.locale"
                height="14px"
                width="22px"
                class="me-2"
              ></v-img>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ locales.find(l => l.locale === $i18n.locale).title }}</span>
            </div>
          </template>

          <!-- Options List -->
          <v-list>
            <v-list-item-group
              :value="$i18n.locale"
              @change="updateActiveLocale"
            >
              <v-list-item
                v-for="locale in locales"
                :key="locale.locale"
                :value="locale.locale"
              >
                <v-img
                  :src="locale.img"
                  height="14px"
                  width="22px"
                  :alt="locale.locale"
                  class="me-2"
                ></v-img>
                <v-list-item-title>{{ locale.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <v-card>
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('Добро пожаловать!') }}
          </p>
          <p class="mb-2">
            {{ $t('Будем рады видеть вас среди наших клиентов! Пожалуйста, уделите немного времени на заполнение формы ниже. Вскоре мы отправим вам все необходимые инструкции на указанный адрес электронной почты.') }}
          </p>
        </v-card-text>
      </v-card>

      <div class="d-flex align-center justify-center py-1 mb-4"></div>
      <v-tabs
        v-model="currentTab"
        fixed-tabs
        centered
      >
        <v-tab>{{ $t('Физ.лицо') }}</v-tab>
        <v-tab>{{ $t('Юр.лицо') }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <v-card
            flat
          >
            <div class="mx-4 my-6">
              <v-form
                ref="registrationForm"
                class="p-2"
                @submit.prevent="handleFormSubmit"
              >
                <v-text-field
                  v-model="registration.secondName"
                  outlined
                  :label="$t('Фамилия')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.firstName"
                  outlined
                  type="text"
                  :label="$t('Имя')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.thirdName"
                  outlined
                  type="text"
                  :label="$t('Отчество')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.email"
                  outlined
                  :label="$t('Email')"
                  placeholder="user@example.com"
                  :error-messages="errorMessages.email"
                  :rules="[validators.required]"
                  class="mb-3"
                  hide-details
                ></v-text-field>

                <v-text-field
                  v-model="registration.phone"
                  outlined
                  type="text"
                  :label="$t('Телефон')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-checkbox
                  id="policy"
                  key="policy"
                  v-model="registration.agree_policy"
                  hide-details
                  class="mt-2 mb-6"
                >
                  <template #label>
                    {{ $t('Согласен с договором оферты') }}
                  </template>
                </v-checkbox>

                <v-btn
                  block
                  color="primary"
                  type="submit"
                  class="mt-5 mb-3"
                  :disabled="!registration.agree_policy"
                >
                  {{ $t("Зарегистрироваться") }}
                </v-btn>
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2 text-lg">
                    {{ $t('У вас есть аккаунт?') }}
                  </p>
                  <router-link to="/login">
                    <b class="text-lg">{{ $t('Войти') }}</b>
                  </router-link>
                </v-card-text>
              </v-form>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <div class="mx-4 my-6">
              <v-form
                ref="registrationForm"
                class="p-2"
                @submit.prevent="handleFormSubmit"
              >
                <v-text-field
                  v-model="registration.companyName"
                  outlined
                  :label="$t('Название фирмы')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.inn"
                  outlined
                  type="text"
                  :label="$t('ИНН')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.kpp"
                  outlined
                  type="text"
                  :label="$t('КПП')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.bik"
                  outlined
                  type="text"
                  :label="$t('БИК')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.bankAccountNumber"
                  outlined
                  type="text"
                  :label="$t('Расчетный счет')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.corrNumber"
                  outlined
                  type="text"
                  :label="$t('Корреспондентский счет')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.email"
                  outlined
                  :label="$t('Email')"
                  placeholder="user@example.com"
                  :error-messages="errorMessages.email"
                  :rules="[validators.required]"
                  class="mb-3"
                  hide-details
                ></v-text-field>

                <v-text-field
                  v-model="registration.phone"
                  outlined
                  type="text"
                  :label="$t('Телефон')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-model="registration.contactPersonName"
                  outlined
                  type="text"
                  :label="$t('ФИО контактного лица')"
                  :rules="[validators.required]"
                  hide-details
                  class="mb-3"
                ></v-text-field>

                <v-checkbox
                  id="policy"
                  key="policy"
                  v-model="registration.agree_policy"
                  hide-details
                  class="mt-2 mb-6"
                >
                  <template #label>
                    {{ $t('Согласен с договором оферты') }}
                  </template>
                </v-checkbox>

                <v-btn
                  block
                  color="primary"
                  type="submit"
                  class="mt-5 mb-3"
                  :disabled="!registration.agree_policy"
                >
                  {{ $t("Зарегистрироваться") }}
                </v-btn>
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2 text-lg">
                    {{ $t('У вас есть аккаунт?') }}
                  </p>
                  <router-link to="/login">
                    <b class="text-lg">{{ $t('Войти') }}</b>
                  </router-link>
                </v-card-text>
              </v-form>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
    <v-dialog
      v-model="isRegistrationResultModalOpen"
      max-width="650px"
      persistent
    >
      <v-card
        class="user-edit-info pa-sm-10 pa-3"
      >
        <v-col
          v-if="isLoading"
          xs="12"
          sm="12"
          md="12"
          lg="10"
          cols="12"
        >
          <loader></loader>
        </v-col>
        <div v-else>
          <v-card-title class="justify-center">
            {{ $t('Заявка принята!') }}
          </v-card-title>
          <v-card-text class="text-center mt-2">
            {{ $t('Спасибо за вашу заявку! В ближайшее время на указанный вами адрес электронной почты мы отправим все необходимые инструкции.') }}
          </v-card-text>
          <v-card-actions class="justify-center mt-2">
            <v-btn
              outlined
              color="secondary"
              @click="closeAndRefresh"
            >
              {{ $t('Закрыть') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import setI18nLanguage from "@/plugins/i18n"
import axios from "@axios"
import useAppConfig from "@core/@app-config/useAppConfig"
import { required } from "@core/utils/validation"
import { ref } from "@vue/composition-api"

export default {
  components: {
    Loader,
  },
  setup() {
    const currentTab = ref(0)
    const { isRtl } = useAppConfig()

    const locales = [
      {
        title: "Русский",
        img: require("@/assets/images/flags/ru.webp"),
        locale: "ru",
      },
      {
        title: "中文",
        img: require("@/assets/images/flags/zh.webp"),
        locale: "zh",
      },
      {
        title: "English (UK)",
        img: require("@/assets/images/flags/gb.webp"),
        locale: "en",
      },
      {
        title: "Tiếng Việt",
        img: require("@/assets/images/flags/vi.webp"),
        locale: "vi",
      },
      {
        title: "فارسی",
        img: require("@/assets/images/flags/fa.webp"),
        locale: "fa",
      },
    ]
    const registrationForm = ref(null)
    const registration = ref({
      secondName: "",
      firstName: "",
      thirdName: "",
      companyName: "",
      inn: "",
      kpp: "",
      bik: "",
      bankAccountNumber: "",
      agree_policy: false,
      corrNumber: "",
      email: "",
      phone: "",
      contactPersonName: "",
    })
    const isLoading = ref(false)
    const isRegistrationResultModalOpen = ref(false)

    const errorMessages = ref([])

    const closeAndRefresh = () => {
      isRegistrationResultModalOpen.value = false
      window.location.reload()
    }

    const handleFormSubmit = () => {
      errorMessages.value = {}
      const isFormValid = registrationForm.value.validate()
      if (!isFormValid) return
      const registrationData = new FormData()
      isLoading.value = true
      isRegistrationResultModalOpen.value = true
      if (currentTab.value === 0) {
        const data = JSON.stringify({
          type: 'individual',
          secondName: registration.value.secondName,
          firstName: registration.value.firstName,
          thirdName: registration.value.thirdName,
          email: registration.value.email,
          phone: registration.value.phone,
        })
        registrationData.append("data", data)
      } else {
        const data = JSON.stringify({
          type: 'legal',
          companyName: registration.value.companyName,
          inn: registration.value.inn,
          kpp: registration.value.kpp,
          bik: registration.value.bik,
          bankAccountNumber: registration.value.bankAccountNumber,
          corrNumber: registration.value.corrNumber,
          email: registration.value.email,
          phone: registration.value.phone,
          contactPersonName: registration.value.contactPersonName,
        })
        registrationData.append("data", data)
      }
      axios({
        method: "post",
        url: "/api/v1/register",
        data: registrationData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(response => {
        if (response.data.success === true) {
          isLoading.value = false
        }
      })
      .catch(() => {
        errorMessages.value = { email: ["Неверные имя пользователя или пароль"] }
      })
    }

    const updateActiveLocale = locale => {
      isRtl.value = locale === "fa"
      setI18nLanguage(locale)
    }

    return {
      handleFormSubmit,
      Loader,
      errorMessages,
      isLoading,
      isRegistrationResultModalOpen,
      validators: {
        required,
      },
      registration,
      currentTab,
      registrationForm,
      locales,
      updateActiveLocale,
      closeAndRefresh,
    }
  },
}
</script>

<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";

.activator-button {
  border: 1px solid rgba(94, 86, 105, 0.47);
  padding: 6px 12px;
  border-radius: 5px;
}
</style>
