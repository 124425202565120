<template>
  <v-row>
    <!-- filter form -->
    <v-col
      md="10"
      lg="11"
      cols="12"
    >
      <v-form
        ref="reviseForm"
        @submit.prevent="getRevise"
      >
        <v-card class="px-8 py-8">
          <v-row>
            <!-- выбор объекта -->
            <v-col
              cols="12"
              md="3"
            >
              <label for="emailHorizontalIcons">{{ $t('Объект') }}</label>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-select
                v-model="reviseFilter.museum_id"
                :items="allMuseumObjects.filter(museum => museum.name !== '')"
                :item-text="'name' + getFormatedLocale()"
                item-value="system_id"
                :label="$t('Выберите объект')"
                :prepend-inner-icon="icons.mdiBankOutline"
                :rules="[v => !!v || $t('Это поле обязательно')]"
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </v-col>

            <!-- дата посещения с -->
            <v-col
              cols="12"
              md="3"
            >
              <label for="mobileHorizontalIcons">{{ $t('Дата посещения с') }}</label>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <datepicker
                on-page="revise-requests-date-from"
                @newDate="handleDateChanges($event)"
              ></datepicker>
            </v-col>

            <!-- дата посещения по -->
            <v-col
              cols="12"
              md="3"
            >
              <label for="passwordHorizontalIcons"> {{ $t('Дата посещения по') }}</label>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <datepicker
                on-page="revise-requests-date-to"
                @newDate="handleDateChanges($event)"
              ></datepicker>
            </v-col>

            <!-- найти -->
            <v-col
              offset-md="3"
              cols="12"
            >
              <v-btn
                color="primary"
                type="submit"
              >
                {{ $t('Сформировать') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-col>
    <!-- loader -->
    <v-col
      v-if="requestIsSending"
      xs="12"
      sm="12"
      md="12"
      lg="10"
      cols="12"
    >
      <loader></loader>
    </v-col>
    <!-- data -->
    <v-col
      v-if="reviseFile"
      xs="12"
      sm="12"
      md="12"
      lg="11"
      cols="12"
    >
      <v-card>
        <v-card-title>{{ $t('Файл сверки') }}</v-card-title>
        <v-card-text>
          {{ $t('Для скачивания сформированного файла сверки нажмите на конпку "Скачать".') }}
        </v-card-text>
        <v-card-actions class="justify-start">
          <v-btn
            color="primary"
            class="ps-4"
            @click="downloadRevies(reviseFile)"
          >
            {{ $t('Скачать') }}
            <v-icon
              dark
              right
            >
              {{ icons.mdiDownload }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <!-- request error -->
    <v-col
      v-if="reviseRequestIsSuccess === false"
      class="mt-6"
      xs="12"
      sm="12"
      md="12"
      lg="11"
      cols="12"
    >
      <v-alert
        border="left"
        color="error"
        text
        dark
      >
        <strong></strong>{{ reviseRequestErrorMsg }}
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
 mdiPound, mdiBankOutline, mdiCalendarToday, mdiDownload,
} from '@mdi/js'
import store from '@/store'
import Loader from '@/components/Loader.vue'
import Datepicker from '@/components/Datepicker.vue'

export default {
  components: {
    Loader,
    Datepicker,
  },
  setup() {
    const reviseForm = ref(null)
    const reviseFilter = ref({
      museum_id: null,
      period_lower: new Date().toISOString().substr(0, 10),
      period_upper: new Date().toISOString().substr(0, 10),
    })
    const menu1 = ref(false)
    const menu2 = ref(false)
    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

    const fetchMuseumObjects = () => {
      store.commit('revise/init_revise')
      store.dispatch('revise/fetchAllMuseumObjects')
    }
    fetchMuseumObjects()

    const getRevise = () => {
      const isFormValid = reviseForm.value.validate()
      if (!isFormValid) return
      store.dispatch('revise/getReviseRequest', reviseFilter.value)
    }
    const downloadRevies = blobXlsx => {
      const url = window.URL.createObjectURL(
        new Blob([blobXlsx], {
          type: 'application/vnd.ms-excel;charset=unicode',
        }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'check.xlsx')
      document.body.appendChild(link)
      link.click()
    }
    const getDay = currentDate => {
      const i = new Date(currentDate).getDay(currentDate)

      return daysOfWeek[i]
    }
    const handleDateChanges = event => {
      if (event.onPage === 'revise-requests-date-from') {
        reviseFilter.value.period_lower = event.date
      }
      if (event.onPage === 'revise-requests-date-to') {
        reviseFilter.value.period_upper = event.date
      }
    }

    const getFormatedLocale = () => {
        const locale = localStorage.getItem('locale') || 'ru'
        if (locale === 'zh') {
            return '_zh_cn'
        } if (locale === 'en') {
            return '_en_US'
        } if (locale === 'ru') {
            return '_ru_RU'
        } if (locale === 'fa') {
            return '_fa_IR'
        } if (locale === 'vi') {
            return '_vi'
        }

return '_ru_RU'
    }

    return {
      menu1,
      menu2,
      reviseFilter,
      getRevise,
      downloadRevies,
      icons: {
        mdiPound,
        mdiBankOutline,
        mdiCalendarToday,
        mdiDownload,
      },
      getDay,
      handleDateChanges,
      reviseForm,
      getFormatedLocale,
      allMuseumObjects: computed(() => store.getters['revise/getAllMuseumObjects']),
      reviseFile: computed(() => store.getters['revise/getFile']),
      reviseRequestIsSuccess: computed(() => store.getters['revise/getRequestStatus']),
      reviseRequestErrorMsg: computed(() => store.getters['revise/getRequestErrorMsg']),
      requestIsSending: computed(() => store.getters['revise/getRequestIsSending']),
    }
  },
}
</script>
