<template>
  <v-card>
    <div class="d-flex flex-column-reverse flex-md-row">
      <div>
        <v-card-title class="px-4">
          {{ ad.title }}
        </v-card-title>
        <v-card-text class="d-flex align-center flex-wrap body-1 px-4">
          <v-chip
            color="secondary"
            outlined
            small
          >
            Реклама
          </v-chip>
        </v-card-text>
        <v-card-text class="px-4">
          {{ ad.content }}
        </v-card-text>

        <v-card-actions
          v-if="ad.link"
          class="dense px-4"
        >
          <v-btn
            depressed
            small
            color="accent"
            :href="ad.link"
            target="_blank"
          >
            Подробнее
          </v-btn>
        </v-card-actions>
      </div>
      <div class="pa-4">
        <v-img
          v-if="ad.image"
          :src="ad.image"
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-lg':'rounded-0'"
          height="100%"
          :max-width="$vuetify.breakpoint.mdAndUp ? '220':'100%'"
        ></v-img>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
props: {
    ad: {
      type: Object,
      required: true,
    },
},
setup() {
    return {
    }
},
}
</script>

<style lang="scss">
</style>
