import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    updateLocalStorage(state, user) {
      localStorage.setItem('userData', JSON.stringify(user))
    },
  },
  actions: {
    updateUserDataOnServer(ctx, userData) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('data', userData)
        axios({
          method: 'put',
          url: '/api/v1/client/',
          data,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateWorkerDataOnServer(ctx, userData) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('data', userData)
        axios({
          method: 'put',
          url: '/api/v1/worker/',
          data,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchUserDataFromServer({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/v1/client/',
        })
          .then(response => {
            const { user } = response.data.data
            commit('updateLocalStorage', user)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchWorkerDataFromServer({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/v1/worker/',
        })
          .then(response => {
            const { user } = response.data.data
            commit('updateLocalStorage', user)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
