<template>
  <v-textarea
    v-model="infoFromNotes"
    :label="$t('Пожелания')"
    rows="3"
    outlined
  ></v-textarea>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    notes: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      infoFromNotes: computed({
        get() {
          const userNote = props.notes.split(' Клиент написал: ')[1]

          return userNote
        },
        set(newValue) {
          const fullNewStr = `${props.notes.split('###')[0]}### Клиент написал: ${newValue}`
          emit('update-notes', fullNewStr)
        },
      }),
    }
  },
}
</script>

<style lang="scss">
</style>
