export default {

    // меню
    'АТГ. Заявки': '申请',
    Заказы: '订单',
    'Новый заказ': '新的订单',
    'Билеты в музеи': '博物馆门票',
    'Подать график': '提交表格',
    'Радио/Аудиогиды': '耳机/讲解器',
    Архив: '草稿',
    Отчеты: '报告',
    Сверка: '对账',
    Оплата: '支付',
    'Оплата Wechat': '微信支付',
    'Оплата заявок': '支付订单',
    'История платежей': '支付记录',
    'Прайс-лист': '价格表',
    'Продление лицензий': '证件延期',
    'Мои лицензии': '我的证件',
    Продление: '延期',
    Информация: '信息',
    Новости: '新闻',
    Транспорт: '交通',
    'О компании': '关于公司',
    Договор: '合同',
    'Договор оферты': '合同价',
    'Политика возврата': '退货政策',
    'Поиск по ref, номеру заявки, дате или объекту': '按ref，订单号，日期或宫殿搜索',
    Настройки: '设置',
    Помощь: '帮助',
    'Обратная связь': '与您联系',
    Выйти: '退出',
    'Старая версия сайта': '网页旧版本',
    'ООО «АВАНГАРД ТРАВЕЛ ГРУПП»': '«АВАНГАРД ТРАВЕЛ ГРУПП»责任有限公司',

    // заказы
    'Нет предстоящих заказов': '没有以前订单',
    'Выберите форму заказа': '选择订单形式',
    'Заказать билеты в музей': '在博物馆里订票',
    'Заказать Радио/Аудиогиды': '预定耳机/讲解器',
    Заявка: '预定',
    'В обработке': '处理中',
    'Подтврждена': '已确定',
    'Лист ожидания': '等待名单',
    'Аннулирована': '已取消',
    'Отклонена': '已拒绝',
    'Подтвержденное время:': '确定时间',
    'Вход': '登陆',
    'ПОДРОБНЕЕ': '详细',
    'ИЗМЕНИТЬ': '更改',
    'ГДЕ СОТРУДНИК': '工作人员在哪',
    'АННУЛИРОВАТЬ': '取消',
    'В АРХИВ': '在草稿箱',
    'Сотрудник на объекте': '工作人员在博物馆',
    'Сотрудник не на объекте': '工作人员不在博物馆',
    'Но вы можете связаться с нами:': '您可以和我们取得联系',
    'Аннулировать': '取消',
    'Хотите аннулировать заявку?': '想要取消订单',
    'Аннулированная заявка попадет в архив и восстановить ее будет невозможно': '取消的申请将在草稿箱且无法被恢复',
    'Отмена': '取消',
    'Дата и время:': '日期和时间',
    'Имя гида:': '导游名字',
    'Не заполнено': '没有填写',
    'Телефон гида:': '导游电话',
    'Пожелания:': '意见',
    'Подтвержденное время и вход:': '确定时间和进入',
    'Билеты:': '门票',
    'Дополнительные услуги:': '其它服务',
    'Причина отклонения:': '取消原因',
    'Информация о группе:': '团队信息',
    'Доп.услуги:': '其它服务',
    'Доп.информация:': '其它信息',
    'График': '表格',
    'Гид': '导游',
    'Заказать': '预定',

    // новый заказ
    'Информация о музее': '博物馆信息',
    'Выберите категорию группы (РФ или ИГ), музей, дату и время': '选择类别（俄罗斯联邦和外籍），博物馆，日期和时间',
    'Выберите категорию группы': '选择团队类别',
    'Выберите музей': '选择博物馆',
    'Выберите объект': '选择设施',
    Далее: '更多',
    'Выберите дату': '选择日期',
    'Выберите время': '选择时间',
    'Информация о группе': '团队信息',
    'Выберите кол-во билетов': '选择门票数量',
    'Выберите страну': '选择国家',
    Назад: '后退',
    'Доп. услуги': '其它服务',
    'Выберите дополнительные услуги': '选择其它服务',
    'Кол-во гидов сопровождающих (если нет лицензии)': '陪同导游数量（如无宫殿讲解证件）',
    'Необходим если у гида нет лицензии в выбранный музей': '如果导游没有所选择博物馆的讲解证则是必要的',
    'Доп. информация': '其它信息',
    'Информация о гиде и пожелания': '关于导游的信息和意见',
    'Ref номер (необязательно)': 'Ref号（非必要）',
    'Уникальный номер группы / вспомогательный номер для ваших целей': '团队的唯一编号',
    'Имя гида': '导游名字',
    'Телефон гида': '导游电话',
    Пожелания: '意见',
    'В поле "Пожелания" укажите:': '在“要求”字段中指定：',
    'адрес и время получения': '取票地址和时间',
    'адрес и приблизительное время возврата оборудования': '归还设备的地址和大约时间',
    Отправить: '发送',
    'Пожалуйста, ожидайте': '请等待',
    'Успешно!': '已成功',
    'Заказ создан': '订单已创建',
    'Ошибка:': '错误',
    Ок: '好的',
    'Подача графика на апрель 2024 закрывается 20 марта 2024.': '提交2024年4月的表格将在2024年3月20日关闭',
    'Кол-во взрослых': '成年人数量',
    'Кол-во детей': '儿童数量',
    'итого:': '总计',
    'чел. (надо': '人（需要',
    'экскурс.обсл.)': '游览服务',
    'гид. или сопровожд.)': '导游或陪同导游',
    'Радиогид': '讲解器',
    'Экскурсия ведется лицензионным гидом': '有讲解证的导游进行讲解',
    'Аудиогид': '耳机接收器',
    'Экскурсия ведется при помощи технических средств на языке группы': '佩戴游览团队语言的讲解设备游览',
    'Кол-во гидов с лицензией': '有讲解证导游的数量',
    'Кол-во сопровождающих': '陪同导游的数量',
    'Удалить': '删除',
    'Отправить заказ': '发送订单',
    'Добавить группу на эту дату': '在这个日期添加团队',
    'Группа': '团队',

    // архив
    'Номер заявки или ref': '订单号或ref',
    Объект: '对象',
    'Номер заявки': '订单号',
    Все: '全部',
    'Дата посещения с': '游览日期从',
    'Дата посещения по': '游览日期到',
    Найти: '搜索',
    'Заказов согласно фильтру не найдено': '未找到符合过滤器的订单',
    Ошибка: '错误',
    'Любой номер': '任何号',

    // сверка
    Сформировать: '生成',
    'Файл сверки': '对账单文件',
    'Для скачивания сформированного файла сверки нажмите на конпку "Скачать".': '要下载生成的对账单文件，请单击“下载”按钮。',
    'Скачать': '下载',

    // оплата заявок
    'Оплатить заявки': '支付订单',
    'Номера ваучеров или заявок для оплаты (через запятую)': '要支付的凭证号或订单号（用逗号分隔）',
    'Введите номера ваучеров или заявок для оплаты': '输入要支付的凭证号或订单号',
    'ФИО или название организации': '姓名或单位名称',
    'Введите ФИО или название организации': '输入姓名或单位名称',
    Email: '邮箱',
    'Введите Email': '输入邮箱',
    'Сумма к оплате': '支付金额',
    'Введите сумму к оплате': '输入支付金额',
    'Согласен с договором оферты': '同意合同',
    'Перейти к оплате': '去付款',
    'Подождите,': '等待',
    'принимаем': '接受',
    'оплату...': '支付',
    'Заказ успешно оплачен!': '成功支付订单',
    'Ваш заказ': '您的订单',
    'успешно оплачен. Спасибо за покупку!': '支付成功！谢谢惠顾！',
    'Что-то пошло не так!': '出了些小问题！',
    'При оплате заказа': '在支付订单时',
    'произошла ошибка!': '出了些小问题！',
    'Cчет успешно сформирован!': '账单已成功生成！',
    'Вы можете открыть его нажав кнопку ниже': '打开订单，按下方按钮',
    'Открыть счет': '打开账单',
    'Физическим лицам': '个人',
    'Юридическим лицам': '法人',
    'Для формирования счета на оплату введите номера ваучеров или заказов, укажите реквизиты компании и точную сумму:': '为支付生成订单，需要输入单号或订单，注明公司账号信息和确认金额',
    'Для оплаты введите номера ваучеров или заявок и укажите точную сумму для оплаты:': '支付请输入单号或订单，输入准确金额支付',
    'Название организации': '单位名称',
    'Юридический адрес': '法律地址',
    'Сформировать счет': '创建账单',
    'Расчетный счет': '结算账户',
    'ИНН': '纳税识别号',
    'КПП': '纳税人登记原因代码',
    'Банк': '银行',
    'Полное название банка (для формирования акта). Например: ': '银行 银行全程。例如：',
    'БИК Банка': '银行统一代码',
    'Корреспондентский счет': '代理银行账户',

    // история платежей
    'Номер платежа': '支付号',
    Ваучеры: '单',
    'Способ оплаты': '谢谢支付',
    'Время и дата оплаты': '支付时间和日期',
    Сумма: '金额',
    Статус: '状态',
    'Платежи отсутствуют': '没有付款',

    // прайс-лист
    'Прайс-лист для иностранных групп': '外国团队价格表',
    'Актуальный файл с ценами для иностранных групп.': '国外团体最新的价格',
    Открыть: '打开',
    'Прайс-лист для российских групп': '俄罗斯团体价目表',
    'Актуальный файл с ценами для российских групп.': '俄罗斯团体最新的价格',

    // лицензии
    'Добавить лицензию': '添加许可证',
    'В настоящий момент нет сохраненных лицензий, вы можете добавить их.': '暂时还没有保存讲解证，您可以添加',
    'Добавить': '添加',
    'Музей': '博物馆',
    'Номер лицензии': '讲解证号',
    'Стаж (лет)': '经验（年）',
    'Дата окончания': '截止日期',
    'Основной язык': '基本语言',
    'Дополнительные языки': '附加语言',

    // о компании
    'ИНН:7841027155': 'ИНН:7841027155',
    'КПП: 784101001': 'КПП: 784101001',
    'ОГРН: 1157847276003': 'ОГРН: 1157847276003',
    'ОКПО: 25885413': 'ОКПО: 25885413',
    'Расчетный счет: 40702810603260000188': 'Расчетный счет: 40702810603260000188',
    'Банк: Филиал "Центральный" Банка ВТБ (ПАО)': 'Банк: Филиал "Центральный" Банка ВТБ (ПАО)',
    'БИК: 044525411': 'БИК: 044525411',
    'Корр. счет: 30101810145250000411': 'Корр. счет: 30101810145250000411',
    'Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405': 'Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405',
    'Email: info@avangard-travel.ru': '邮箱：info@avangard-travel.ru',
    'Телефон: (812) 640-02-28': '电话：(812) 640-02-28 ',

    // договор
    'Вы можете посмотреть договор с ООО "Авангард Травел Групп".': '您可以看到和"Авангард Травел Групп"责任有限公司的合同',

    // договор оферты
    'Вы можете посмотреть договор оферты с ООО "Авангард Травел Групп".': '您可以查看與 Avangard Travel Group LLC 的合同。',

    // политика возврата
    'Вы можете посмотреть политику возврата.': '您可以查看退订政策',

    // настройки
    'Имя:': '名字',
    'Телефон:': '电话',
    'Страна:': '国家',
    'Информация о гиде': '导游信息',
    'Заполните информацию и подавайте заявки еще быстрее': '填写信息提交订单更加快捷',
    Имя: '名字',
    Телефон: '电话',
    'Выберите страницу': '选择国家',
    'Использовать в заказе?': '在订单中使用？',
    Сохранить: '保存',
    'Являетесь ли вы туристической фирмой / юридическим лицом': '您是否是旅行社/法人',
    'Юридическое лицо': '法人',
    'Для выставления счетов': '开发票',

    // форма логина
    Логин: '账号',
    Пароль: '密码',
    Войти: '登陆',
    'Выберите язык': '选择语言',
    'У вас нет аккаунта?': '您没有账户？',
    'Зарегистрироваться': '注册',
    'Добро пожаловать!': '欢迎使用',
    'Будем рады видеть вас среди наших клиентов! Пожалуйста, уделите немного времени на заполнение формы ниже. Вскоре мы отправим вам все необходимые инструкции на указанный адрес электронной почты.': '我们非常高兴您能够在我们的客户群体里！请空出一些时间填写一下下方表格。很快我们将会发送使用说明到您指定的邮箱。',
    'Фамилия': '姓',
    'Физ.лицо': '个人',
    'Юр.лицо': '法人',
    'Отчество': '父称',
    'У вас есть аккаунт?': '您已申请账户？',
    'Название фирмы': '公司名称',
    'БИК': 'БИК',
    'ФИО контактного лица': '联系人姓名',
    'Заявка принята!': '申请已递交',
    'Спасибо за вашу заявку! В ближайшее время на указанный вами адрес электронной почты мы отправим все необходимые инструкции.': '感谢您的申请！ 我们很快就会将所有使用说明发送到您提供的邮件里。',
    'Закрыть': '关闭',

    // уведомление
    'Теперь можно оплатить заявки онлайн, включая оплату от юридических лиц!': '現在您可以在線支付申請費用，包括來自法律實體的付款！',
    'Мы рады сообщить, что теперь вы можете оплатить заявки на нашем сайте, используя банковскую карту или QR-код системы быстрых платежей. Вдобавок к этому, мы расширили наши возможности и теперь доступна оплата и для юридических лиц. Юридические лица могут самостоятельно генерировать счета для оплаты.': '我們很高興地宣布，您現在可以使用銀行卡或快捷支付系統的二維碼在我們的網站上支付申請費用。 除此之外，我們還擴展了我們的能力，現在法人實體也可以付款。 法律實體可以獨立生成付款發票。',
    'Никаких задержек и лишних хлопот - оплата проходит мгновенно. Перейдите в раздел "Оплата заявок" и оплачивайте свои заявки быстро и удобно! Наши новые возможности сделают процесс ещё более удобным и эффективным.': '沒有延誤和不必要的麻煩 - 付款是即時的。 前往“付款”部分，快速方便地為您的申請付款！ 我們的新功能將使該過程更加方便和高效。',

    // помощь
    'У вас есть вопросы?': '您有问题？?',
    'Если вы не можете найти ответ на вопрос, вы всегда можете связаться с нами. Мы ответим вам в ближайшее время!': '如果您没有找到问题的答案，您可以和我们取得联系。我们将在近期回复您！',
    'Мы всегда рады помочь!': '我们非常愿意给您提供帮助',
    'Лучший способ получить ответ быстрее!': '更快地获得答案的最佳方法！',

    // в разработе
    'Готовимся к запуску. Новая функция в пути! 🚀': '準備發射。 新功能即將推出！ 🚀',
    'Мы активно работаем над новой функцией для вас.': '我們正在積極為您開發一項新功能。',
    'Будьте в курсе предстоящих обновлений!': '請繼續關注即將推出的更新！',

    // Обратная связь
    'Мы всегда рады услышать вас!': '我们总是很高兴的收到您的信息',
    'Наши усилия направлены на то, чтобы сделать систему подачи заявок максимально удобной и эффективной для вас. Ваше мнение и предложения играют ключевую роль в этом процессе. Не стесняйтесь делиться своими мыслями, идеями, а также сообщать нам обо всех замеченных ошибках или проблемах в работе системы. Вместе мы сделаем систему подачи заявок лучше!': '我们的努力是为了使申请系统对您来说尽可能方便和高效。 您的意见和建议在这一过程中起着关键作用。 请随时分享您的想法和想法，并告诉我们有关系统中发现的所有错误或问题。 我们将共同使申请系统变得更好！',
    'Как к вам обращаться?': '如何称呼您？',
    'Email для обратной связи': '和您取得联系的邮箱',
    'Опишите ваше предложение, жалобу или замеченную ошибку': '请填写您的建议，投诉和发现的错误',
}
