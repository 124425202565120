<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            size="120"
            rounded
          >
            <span
              class="font-weight-semibold text-5xl"
            >{{ avatarText(userDataLocal.login) }}</span>
          </v-avatar>

          <span class="mb-2">{{ userDataLocal.login }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            {{ $t('Информация') }}
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t('Имя:') }}</span>
              <span class="text--secondary">{{ userDataLocal.default_guide_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('Телефон:') }}</span>
              <span class="text--secondary">{{ userDataLocal.default_guide_phone }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t('Страна:') }}</span>
              <span class="text--secondary">{{ userDataLocal.default_guide_country }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const userDataLocal = { ...props.userData }

    return {
      avatarText,
      userDataLocal,
    }
  },
}
</script>
