import axios from '@axios'

export default {
  namespaced: true,
  state: {
    archive: [],
    museum_objects: [],
    museums_objects_info: {},
    request_is_sending: false,
    request_is_success: null,
    request_error_msg: null,
  },
  getters: {
    getArchive: state => state.archive,
    getMuseumObjectsInfo: state => state.museums_objects_info,
    getAllMuseumObjects: state => state.museum_objects,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
    getRequestIsSending: state => state.request_is_sending,
  },
  mutations: {
    init_archive(state) {
      state.archive = []
      state.request_is_sending = false
      state.request_is_success = null
      state.request_error_msg = null
    },
    set_request_init(state) {
      state.archive = []
      state.request_is_sending = true
      state.request_is_success = null
      state.request_error_msg = null
    },
    archive_request_success(state, data) {
      state.museums_objects_info = data.payload
      state.archive = data.result
      state.request_is_sending = false
      state.request_is_success = true
      state.request_error_msg = null
    },
    archive_request_error(state, errorMsg) {
      state.archive = []
      state.museums_objects_info = {}
      state.request_is_sending = false
      state.request_is_success = false
      state.request_error_msg = errorMsg
    },
    museum_objects_request_success(state, payload) {
        payload.splice(0, 0, {
            museum: {},
            name: 'Все объекты',
            name_ru_RU: 'Все объекты',
            name_en_US: 'All objects',
            name_zh_cn: '所有对象',
            name_fa_IR: 'همه اشیا',
            name_vi: 'Tất cả các đối tượng',
            system_id: null
        })
        state.museum_objects = payload
    },
  },
  actions: {
    fetchAllMuseumObjects({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/v1/museums-objects/',
        })
          .then(response => {
            if (response.status === 200) {
              commit('museum_objects_request_success', response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getArchiveRequest({ commit }, archiveFilter) {
      commit('set_request_init')

      return new Promise((resolve, reject) => {
        const data = JSON.stringify({
          no: ['', 'Любой номер'].includes(archiveFilter.no) ? '' : archiveFilter.no,
          museum_id: archiveFilter.museum_id,
          period_lower: archiveFilter.period_lower,
          period_upper: archiveFilter.period_upper,
          language: 'ru',
        })
        const postData = new FormData()
        postData.append('data', data)
        postData.append('action', 'get_archive')
        axios({
          method: 'post',
          url: '/api/v1/actions/',
          data: postData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.status === 200) {
              if (response.data.success) {
                commit('archive_request_success', response.data.data)
                resolve(response)
              } else {
                commit('archive_request_error', response.data.error)
              }
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}
