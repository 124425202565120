<template>
  <v-checkbox
    v-model="infoFromNotes"
    hide-details
    class="mt-2 mb-2"
  >
    <template #label>
      <div>
        {{ kindOfService.name }}
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              class="ml-2 info--secondary"
              v-on="on"
            >
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </template>
          <span>{{ kindOfService.helper_text }}</span>
        </v-tooltip>
      </div>
    </template>
  </v-checkbox>
</template>

<script>
import { mdiHelpCircleOutline } from '@mdi/js'
import { computed } from '@vue/composition-api'

export default {
  props: {
    notes: {
      type: String,
      required: true,
    },
    kindOfService: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      icons: {
        mdiHelpCircleOutline,
      },
      infoFromNotes: computed({
        get() {
          const removeBeforeValueString = props.notes.split(`${props.kindOfService.name}: `)[1].split('.')[0]
          if (removeBeforeValueString.includes('Да')) {
            return true
          }

          return false
        },
        set(newValue) {
          let setValue = ''
          if (newValue) {
            setValue = 'Да'
          } else {
            setValue = 'Нет'
          }
          const removeBeforeValueString = props.notes.split('###')[0]
          const unescapedPattern = props.kindOfService.name
            .replaceAll(' ', '.')
            .replaceAll('(', '\\(')
            .replaceAll(')', '\\)')
          const pattern = `${unescapedPattern}:.Да.|${unescapedPattern}:.Нет.`
          const re = new RegExp(pattern, '')
          const partNewStr = removeBeforeValueString.replace(re, `${props.kindOfService.name}: ${setValue}.`)
          const fullNewStr = `${partNewStr}### ${props.notes.split('###')[1]}`
          emit('update-notes', fullNewStr)
        },
      }),
    }
  },
}
</script>

<style lang="scss">
</style>
