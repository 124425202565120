import axios from '@axios'

export default {
  namespaced: true,
  state: {
    subjects: [],
    questions: [],
    request_is_success: null,
    request_error_msg: null,
  },
  getters: {
    getSubjects: state => state.subjects,
    getQuestions: state => state.questions,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
  },
  mutations: {
    set_on_request_success_subjects(state, payload) {
      state.subjects = payload
      state.request_is_success = true
      state.request_error_msg = null
    },
    set_on_request_success_questions(state, payload) {
      state.questions = payload
      state.request_is_success = true
      state.request_error_msg = null
    },
    set_on_request_error(state, error) {
      state.subjects = []
      state.questions = []
      state.request_is_success = false
      state.request_error_msg = error
    },
  },
  actions: {
    fetchSubjects({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/v1/help/subjects',
        })
          .then(response => {
            if (response.status === 200) {
              commit('set_on_request_success_subjects', response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchQuestions({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/v1/help/questions',
        })
          .then(response => {
            if (response.status === 200) {
              commit('set_on_request_success_questions', response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
