import axios from '@axios'

export default {
  namespaced: true,
  state: {
    payments: [],
    is_waiting: true,
    is_error: false,
    is_success: false,
    error_text: '',
  },
  getters: {
    getPayments: state => state.payments,
    getIsWaiting: state => state.is_waiting,
    getIsSuccess: state => state.is_success,
    getIsError: state => state.is_error,
    getErrorText: state => state.error_text,
  },
  mutations: {
    set_on_check_request_success(state, status) {
        if (status === 'COMPLETE') {
            state.is_waiting = false
            state.is_success = true
            state.is_error = false
            state.error_text = ''
        } else if ((status === 'WAITING_FOR_3DS') || (status === 'PROCESSING') || (status === 'FORM_OPENED')) {
            state.is_waiting = true
            state.is_success = false
            state.is_error = false
            state.error_text = ''
        } else if (status === 'FAILED') {
            state.is_waiting = false
            state.is_success = false
            state.is_error = true
            state.error_text = 'Ошибка при совершении платежа'
        }
    },
    set_on_history_request_success(state, payload) {
        state.payments = payload
    },
    set_on_history_request_error(state, error) {
        console.log(error)
    },
    set_on_request_error(state, error) {
        console.log(error)
    },
  },
  actions: {
    prefetchInvoiceData() {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify({})
            const postData = new FormData()
            postData.append('data', data)
            postData.append('action', 'prefetch_invoice_data')
            axios({
                method: 'post',
                url: '/api/v1/actions/',
                data: postData,
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'json',
            })
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data.data)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    prefetchEdoData() {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify({})
            const postData = new FormData()
            postData.append('data', data)
            postData.append('action', 'prefetch_edo_data')
            axios({
                method: 'post',
                url: '/api/v1/actions/',
                data: postData,
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'json',
            })
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data.data)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    checkPaymentStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify(payload)
            const postData = new FormData()
            postData.append('data', data)
            postData.append('action', 'check_payment_status')
            axios({
                method: 'post',
                url: '/api/v1/actions/',
                data: postData,
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'json',
            })
                .then(response => {
                    if (response.status === 200) {
                        commit('set_on_check_request_success', response.data.data)
                        resolve(response.data.data)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getPaymentsHistory({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify(payload)
            const postData = new FormData()
            postData.append('data', data)
            postData.append('action', 'get_payments_history')
            axios({
                method: 'post',
                url: '/api/v1/actions/',
                data: postData,
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'json',
            })
                .then(response => {
                    if (response.status === 200) {
                        commit('set_on_history_request_success', response.data.data)
                    } else {
                        commit('set_on_history_request_error', response.data.error)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    payOrder({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify(payload)
            const postData = new FormData()
            postData.append('data', data)
            postData.append('action', 'new_payment')
            axios({
                method: 'post',
                url: '/api/v1/actions/',
                data: postData,
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'json',
            })
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        commit('set_on_request_error', response.data.error)
                        resolve(response)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createInvoice({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify(payload)
            const postData = new FormData()
            postData.append('data', data)
            postData.append('action', 'new_invoice')
            axios({
                method: 'post',
                url: '/api/v1/actions/',
                data: postData,
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'json',
            })
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data)
                        resolve(response.data)
                    } else {
                        commit('set_on_request_error', response.data.error)
                        resolve(response)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
  },
}
