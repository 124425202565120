<template>
  <div>
    <v-row class="mb-2">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="2"
      >
        <v-btn
          color="primary"
          dark
          @click="$router.go(-1);"
        >
          <v-icon
            dark
            left
          >
            {{ icons.mdiArrowLeft }}
          </v-icon>{{ $t('Назад') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="app-invoice-preview">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="pt-0"
      >
        <v-card>
          <v-card-title class="align-start pt-6">
            <span class="text-xl font-weight-semibold">
              {{ $t('Заявка') }} #{{ selectedOrder.id }}
            </span>
            <v-spacer></v-spacer>
            <span
              :class="'text-2xl font-weight-bold ' + getOrderStatusColor(selectedOrder.state, selectedOrder.is_locked)"
            >
              {{ $t(getOrderStatusTitle(selectedOrder.state)) }}
            </span>
          </v-card-title>
          <v-card-subtitle class="mx-0 px-5 pt-0 my-0">
            <v-row class="pb-2">
              <v-col
                class="pb-2 pt-2 pl-4"
                cols="12"
                xs="12"
                sm="6"
                order-xs="2"
              >
                REF #{{ selectedOrder.data.refno }}
              </v-col>
              <!-- <v-col
                class="text-xs-left text-sm-right pt-1 pb-2"
                cols="12"
                xs="12"
                sm="6"
                order-xs="1"
              >
                <v-chip
                  label
                  outlined
                >
                  <v-icon
                    class="me-1"
                    size="18"
                  >
                    {{ icons.mdiAlertOutline }}
                  </v-icon>
                  Заблокирована для изменений
                </v-chip>
              </v-col> -->
            </v-row>
          </v-card-subtitle>
          <!-- <v-card-subtitle>REF #{{ selectedOrder.data.refno }}</v-card-subtitle> -->
          <v-divider></v-divider>
          <v-card-title class="no-break text-2xl py-4">
            {{ objectInfo.museum['name' + getFormatedLocale()] }}
          </v-card-title>
          <v-card-subtitle class="no-break pt-2 pb-4">
            {{ objectInfo['name' + getFormatedLocale()] }}
          </v-card-subtitle>
          <v-card-text>
            <!-- <h3 class="text-xl font-weight-semibold mb-2 mt-6">
              Общее
            </h3>

            <v-divider></v-divider> -->

            <v-list>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">
                  {{ $t('Дата и время:') }}
                </span>
                <span class="text--secondary no-break">{{ formatDate(selectedOrder.data.pass_date) }}, {{ selectedOrder.info.museum_schedule_item }}</span>
              </v-list-item>

              <v-list-item
                v-if="!(objectInfo.is_audioguide_request || objectInfo.is_radioguide_request)"
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">
                  {{ $t('Страна:') }}
                </span>
                <span class="text--secondary text-capitalize">{{ objectInfo.countries.find(country => country.system_id === selectedOrder.data.country_id).name }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">
                  {{ $t('Имя гида:') }}
                </span>
                <span
                  v-if="selectedOrder.data.guide !== 'None' && selectedOrder.data.guide !== '' && selectedOrder.data.guide !== null"
                  class="text--secondary"
                >{{ selectedOrder.data.guide }}</span>
                <span
                  v-else
                  class="not-filled"
                >
                  {{ $t('Не заполнено') }}
                </span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">
                  {{ $t('Телефон гида:') }}
                </span>
                <span
                  v-if="selectedOrder.data.guide_phone !== 'None' && selectedOrder.data.guide_phone !== '' && selectedOrder.data.guide_phone !== null"
                  class="text--secondary"
                >{{ selectedOrder.data.guide_phone }}</span><span
                  v-else
                  class="not-filled"
                >
                  {{ $t('Не заполнено') }}
                </span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">
                  {{ $t('Пожелания:') }}
                </span>
                <span
                  v-if="getUserNotes(selectedOrder.data.notes)"
                  class="text--secondary"
                >{{ getUserNotes(selectedOrder.data.notes) }}</span><span
                  v-else
                  class="not-filled"
                >
                  {{ $t('Не заполнено') }}
                </span>
              </v-list-item>
              <v-list-item
                v-if="selectedOrder.state == 2"
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2 order_status-accepted-green">
                  {{ $t('Подтвержденное время и вход:') }}
                </span>
                <span
                  class="order_status-accepted-green"
                >{{ selectedOrder.info.pass_time.substring(1) }}, {{ selectedOrder.info.entrance_name }}</span>
              </v-list-item>
              <v-list-item
                v-if="selectedOrder.state == 2 && (objectInfo.is_audioguide_request || objectInfo.is_radioguide_request) && objectInfo.contact_person_name"
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">
                  {{ $t('Представитель:') }}
                  {{ objectInfo["contact_person_name"] }},
                  <a
                    :href="`tel:${objectInfo.contact_person_phone}`"
                    class="no-decorate"
                  >{{ objectInfo["contact_person_phone"] }}</a>
                </span>
              </v-list-item>
              <!-- >{{ selectedOrder.info.museum_schedule_item.match(/\d{1,2}:\d{2}/).length > 0 ? `от ${selectedOrder.info.museum_schedule_item.match(/\d{1,2}:\d{2}/)[0]}` : null }}, {{ selectedOrder.info.entrance_name }}</span> -->
            </v-list>
          </v-card-text>

          <!-- <v-divider></v-divider> -->

          <!-- Ticket info -->
          <v-card-text v-if="!(objectInfo.is_audioguide_request || objectInfo.is_radioguide_request)">
            <h3 class="text-xl font-weight-semibold mb-0 mt-6">
              {{ $t('Билеты:') }}
            </h3>

            <!-- <v-divider></v-divider> -->
            <v-list v-if="!(selectedOrder.info.museum_schedule_item.includes('График'))">
              <v-list-item
                v-for="ticketCategory in objectInfo.tickets"
                :key="ticketCategory.id"
                dense
                class="px-0 mb-n2"
              >
                <span v-if="ticketCategory.not_is_new"><span class="font-weight-medium me-2">{{ ticketCategory['name' + getFormatedLocale()] }}:</span> <span class="text--secondary no-break">{{ selectedOrder.data[ticketCategory.system_name] }}</span></span>
                <span v-else><span class="font-weight-medium me-2">{{ ticketCategory['name' + getFormatedLocale()] }}:</span><span class="text--secondary no-break">{{ getInfoFromNotesTickets(selectedOrder.data.notes, ticketCategory.name) }}</span></span>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">Взрослые – количество:</span><span class="text--secondary no-break">{{ selectedOrder.data.qty_common }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">Дети – количество:</span><span class="text--secondary no-break">{{ selectedOrder.data.qty_child }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">Льготные – количество:</span><span class="text--secondary no-break">{{ selectedOrder.data.qty_privileged }}</span>
              </v-list-item>
            </v-list>
          </v-card-text>

          <!-- Services info -->
          <v-card-text v-if="!(objectInfo.is_audioguide_request || objectInfo.is_radioguide_request || selectedOrder.info.museum_schedule_item.includes('График'))">
            <h3 class="text-xl font-weight-semibold mb-0 mt-6">
              {{ $t('Дополнительные услуги:') }}
            </h3>
            <!-- <v-divider></v-divider> -->

            <v-list>
              <v-list-item
                v-for="serviceCategory in objectInfo.services"
                :key="serviceCategory.id"
                dense
                class="px-0 mb-n2"
              >
                <span v-if="serviceCategory.not_is_new"><span class="font-weight-medium me-2">{{ serviceCategory['name' + getFormatedLocale()] }}:</span> <span v-if="selectedOrder.data[serviceCategory.system_name] == true">
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarked }}
                  </v-icon>
                </span>
                  <span v-else>
                    <v-icon color="error">
                      {{ icons.mdiCloseBox }}
                    </v-icon>
                  </span>
                </span>
                <span v-else><span class="font-weight-medium me-2">{{ serviceCategory.name }}:</span> <span v-if="getInfoFromNotesServices(selectedOrder.data.notes, serviceCategory.name) == true">
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarked }}
                  </v-icon>
                </span>
                  <span v-else>
                    <v-icon color="error">
                      {{ icons.mdiCloseBox }}
                    </v-icon>
                  </span>
                </span>
              </v-list-item>
            </v-list>
            <v-alert
              v-if="selectedOrder.reject_reason !== null"
              border="right"
              color="error"
              class="mt-6"
              dark
              text
            >
              {{ $t('Причина отклонения:') }}
              {{ selectedOrder.reject_reason }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        lg="3"
        class="pb-6"
      >
        <v-card>
          <OrderActions
            :order="selectedOrder"
            :object-info="objectInfo"
            :disabled-actions="['info']"
          ></OrderActions>
          <!-- :to="{ name: 'apps-invoice-edit', params: { id: $route.params.id } }" -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { formatDate } from '@/utils/filter'
import {
mdiAlertOutline, mdiArrowLeft,
mdiCheckboxMarked,
// eslint-disable-next-line comma-dangle
mdiCloseBox, mdiMessageTextOutline, mdiSendOutline
} from '@mdi/js'

// eslint-disable-next-line object-curly-newline
import OrderActions from '@/components/OrderActions.vue'
import store from '@/store'
import {
// eslint-disable-next-line comma-dangle
getInfoFromNotesServices, getInfoFromNotesTickets, getOrderStatusColor, getOrderStatusTitle, getUserNotes
} from '@/utils/helper'

export default {
    components: {
        OrderActions,
    },
  setup() {
      const selectedOrder = JSON.parse(localStorage.getItem('selectedOrder'))
      const objectInfo = JSON.parse(localStorage.getItem('objectInfo'))
      const fetchUpcoming = () => {
          store.dispatch('upcoming/fetchUpcoming')
        }
        fetchUpcoming()
        const getFormatedLocale = () => {
            const locale = localStorage.getItem('locale') || 'ru'
            if (locale === 'zh') {
                return '_zh_cn'
            } if (locale === 'en') {
                return '_en_US'
            } if (locale === 'ru') {
                return '_ru_RU'
            } if (locale === 'fa') {
                return '_fa_IR'
            } if (locale === 'vi') {
                return '_vi'
            }

            return '_ru_RU'
        }

        return {
            getOrderStatusTitle,
            getOrderStatusColor,
            formatDate,
            getUserNotes,
            getInfoFromNotesTickets,
            getInfoFromNotesServices,
            getFormatedLocale,

            icons: {
                mdiSendOutline,
                mdiMessageTextOutline,
                mdiArrowLeft,
                mdiCheckboxMarked,
                mdiCloseBox,
                mdiAlertOutline,
            },
            selectedOrder,
            objectInfo,
        }
    },
}
</script>

<style lang="scss">
.no-break {
    word-break: normal;
}
.no-decorate {
    color: inherit;
}
.order-status-new {
  color: #8a8d93;
}
.order_status-accepted-green {
  color: #82bb3d;
}
.order-status-accepted-blue {
  color: #33b4ff;
}
.order-status-waiting {
  color: #ffb400;
}
.order-status-decline {
  color: #ff4c51;
}
.not-filled {
  color: #8a8d939f;
}
</style>
