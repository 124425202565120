<template>
  <v-row>
    <v-col
      cols="12"
      md="5"
      lg="4"
    >
      <v-row class="user-bio-panel">
        <!-- user profile -->
        <v-col cols="12">
          <v-card class="pt-10">
            <v-card-title class="justify-center flex-column">
              <v-avatar
                color="primary"
                class="v-avatar-light-bg primary--text mb-4"
                size="120"
                rounded
              >
                <span
                  class="font-weight-semibold text-5xl"
                >{{ avatarText(userDataLocal.name) }}</span>
              </v-avatar>

              <span class="mb-2">{{ userDataLocal.name }}</span>
            </v-card-title>

            <v-card-text>
              <h2 class="text-xl font-weight-semibold mb-2">
                Информация
              </h2>

              <v-divider></v-divider>

              <v-list>
                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <p><b>Сотрудник АТГ</b></p>
                </v-list-item>
                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <p><b>Номер телефона:</b> {{ userDataLocal.phone }}</p>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="7"
      lg="8"
    >
      <v-alert
        color="info"
        text
      >
        <p class="font-weight-semibold mb-1">
          Обратите внимание!
        </p>
        <p class="text-sm mb-0">
          Выключайте смену после работы
        </p>
      </v-alert>
      <div class="user-tab-security">
        <!-- online toggle -->
        <v-card class="mb-7">
          <v-card-title>
            На смене
          </v-card-title>
          <v-card-subtitle>
            Когда вы на смене, клиенты могут построить машрут до вас
          </v-card-subtitle>
          <v-card-text class="pb-2">
            <v-list
              dense
              class="py-0"
            >
              <v-list-item
                class="px-0"
              >
                <v-btn
                  max-width="35"
                  contain
                  icon
                  color="secondary"
                  class="me-3"
                >
                  <v-icon>
                    {{ icons.mdiPowerStandby }}
                  </v-icon>
                </v-btn>

                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm">
                      На смене
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-0">
                      Получение геопозиции
                    </v-list-item-subtitle>
                  </div>

                  <v-spacer></v-spacer>

                  <v-switch
                    v-model="userDataLocal.online"
                    @change="switchGeolocation"
                  ></v-switch>
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <!-- location info -->
        <v-card class="mb-7">
          <v-card-title>
            <p class="mb-2 mb-sm-0">
              Местоположение
            </p>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <h4 class="mb-1">
                  <span class="font-weight-medium me-1">Широта:</span>
                </h4>
                <p v-if="userDataLocal.latitude !== ''">
                  {{ userDataLocal.latitude }}
                </p>
                <p v-else>
                  Отсутствует
                </p>

                <h4 class="mb-1">
                  <span class="font-weight-medium me-1">Долгота:</span>
                </h4>
                <p v-if="userDataLocal.longitude !== ''">
                  {{ userDataLocal.longitude }}
                </p>
                <p v-else>
                  Отсутствует
                </p>
                <h4 class="mb-1">
                  <span class="font-weight-medium me-1">Время последнего обновления:</span>
                </h4>
                <p v-if="userDataLocal.geoposition_updated_at !== null">
                  {{ formatDateTime(userDataLocal.geoposition_updated_at) }}
                </p>
                <p v-else>
                  Неизвестно
                </p>
                <!-- <h4 class="mb-1">
                  <span class="font-weight-medium me-1">Адрес:</span>
                </h4>
                <p>пр. Медиков, 27</p> -->
              </v-col>
              <v-col
                cols="12"
                class="d-flex align-center flex-wrap"
              >
                <v-btn
                  color="primary"
                  class="me-3 mb-3 mb-sm-0"
                  :disabled="!userDataLocal.online"
                  @click="updateLocation"
                >
                  Обновить
                </v-btn>
                <v-btn
                  icon
                  color="primary"
                  :disabled="!userDataLocal.online"
                  :href="`http://maps.apple.com/?ll=${userDataLocal.latitude},${userDataLocal.longitude}&q=${userDataLocal.name} – сотрудник АТГ`"
                >
                  <v-icon>
                    {{ icons.mdiMapSearchOutline }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-dialog
          v-model="isGettingLocation"
          overlay
          persistent
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text class="pt-3 white--text">
              Пожалуйста, ожидайте
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0 mt-2"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { avatarText, formatDateTime } from '@core/utils/filter'
import { mdiPowerStandby, mdiMapSearchOutline } from '@mdi/js'

import store from '@/store'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isGettingLocation = ref(false)
    const userDataLocal = ref({})
    userDataLocal.value = props.userData

    const getLocation = () =>
      new Promise((resolve, reject) => {
        isGettingLocation.value = true
        navigator.geolocation.getCurrentPosition(
          pos => {
            isGettingLocation.value = false
            userDataLocal.value.latitude = pos.coords.latitude
            userDataLocal.value.longitude = pos.coords.longitude
            userDataLocal.value.geoposition_updated_at = new Date()
            resolve()
          },
          () => {
            isGettingLocation.value = false
            reject()
          },
        )
      })

    const resetLocation = () =>
      new Promise(resolve => {
        userDataLocal.value.latitude = ''
        userDataLocal.value.longitude = ''
        userDataLocal.value.geoposition_updated_at = null
        resolve()
      })

    const onSubmit = () => {
      const stringifyUserData = JSON.stringify(userDataLocal.value)
      store.dispatch('app-user/updateWorkerDataOnServer', stringifyUserData).then(() => {
        store.dispatch('app-user/fetchWorkerDataFromServer').then(() => {
          emit('forceRerender')
        })
      })
    }
    const switchGeolocation = () => {
      if (userDataLocal.value.online) {
        getLocation().then(() => {
          onSubmit()
        })
      } else {
        resetLocation().then(() => {
          onSubmit()
        })
      }
    }

    const updateLocation = () => {
      getLocation().then(() => {
        onSubmit()
      })
    }

    watch(
      () => props.userData,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
      },
    )

    return {
      formatDateTime,
      userDataLocal,
      avatarText,
      onSubmit,
      updateLocation,
      switchGeolocation,
      isGettingLocation,
      icons: {
        mdiPowerStandby,
        mdiMapSearchOutline,
      },
    }
  },
}
</script>
