<template>
  <v-row>
    <!-- степпер для нового заказа -->
    <v-col
      cols="12"
      xs="12"
      sm="12"
      md="9"
    >
      <v-card>
        <v-card-text>
          <v-form
            ref="orderForm"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <!-- РГ / АГ -->
              <v-col
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <v-select
                  v-model="baseOrderInfo.type"
                  :items="typeList[getFormatedLocale()]"
                  :rules="[v => !!v || $t('Выбор услуги')]"
                  :label="$t('Выбор услуги')"
                  required
                  item-text="name"
                  item-value="value"
                  hide-details="auto"
                  outlined
                  dense
                  @change="fetchMuseums"
                  @input="setService"
                >
                </v-select>
                <span class="caption ml-4">
                  Радиогид – Экскурсия ведется лицензионным гидом
                </span>
                <br />
                <span class="caption ml-4">
                  Аудиогид – Экскурсия ведется при помощи технических средств
                </span>
              </v-col>
              <!-- Музей -->
              <v-col
                v-if="baseOrderInfo.type !== 'rg-city'"
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <v-select
                  v-model="baseOrderInfo.museum"
                  :items="getFilteredMuseumList(museumsList)"
                  :rules="[v => !!v || $t('Выберите музей')]"
                  :label="$t('Выберите музей')"
                  required
                  :item-text="'name' + getFormatedLocale()"
                  item-value="system_id"
                  hide-details="auto"
                  outlined
                  dense
                  @change="fetchSchedule"
                >
                </v-select>
              </v-col>
              <!-- ДАТА -->
              <v-col
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <datepicker
                  :key="datepickerKey"
                  on-page="new-request-date"
                  @newDate="handleDateChanges($event)"
                ></datepicker>
              </v-col>
              <v-col
                v-for="(order, index) in orders"
                :key="index"
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <v-card elevation="2">
                  <v-card-title>
                    <span class="title"> {{ $t("Группа") }} {{ index + 1 }} </span>
                  </v-card-title>
                  <v-card-text>
                    <!-- Время -->
                    <v-row>
                      <v-col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-select
                          v-model="order.schedule"
                          :items="schedule"
                          :rules="[v => !!v || $t('Выберите время')]"
                          :label="$t('Выберите время')"
                          required
                          item-text="name"
                          item-value="id"
                          hide-details="auto"
                          class=""
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <!-- КОЛ-ВО аппаратов -->
                    <v-row>
                      <v-col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-text-field
                          id="amount"
                          key="amount"
                          v-model="order.tickets"
                          type="number"
                          outlined
                          dense
                          label="Кол-во аппаратов"
                          hide-details
                          placeholder="0"
                          :rules="[validators.integerPositiveValidator]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Имя гида -->
                    <v-row>
                      <v-col
                        v-if="baseOrderInfo.museum == 148 || userData.show_guide_info_field === true"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-text-field
                          v-model="order.guideName"
                          type="text"
                          outlined
                          dense
                          :label="$t('Имя гида')"
                          :placeholder="$t('Имя гида')"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Телефон гида -->
                    <v-row>
                      <v-col
                        v-if="baseOrderInfo.museum == 148 || userData.show_guide_info_field === true"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-text-field
                          v-model="order.guidePhone"
                          type="text"
                          outlined
                          dense
                          :label="$t('Телефон гида')"
                          :placeholder="$t('Телефон гида')"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- КОЛ-ВО СОПРОВОЖДАЮЩИХ -->
                    <v-row>
                      <v-col
                        v-if="baseOrderInfo.type === 'ag'"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-text-field
                          v-model="order.guides"
                          type="number"
                          outlined
                          dense
                          :label="$t('Кол-во гидов сопровождающих (если нет лицензии)')"
                          :placeholder="$t('Кол-во гидов сопровождающих (если нет лицензии)')"
                          :hint="$t('Необходим если у гида нет лицензии в выбранный музей')"
                          persistent-hint
                          hide-details="auto"
                        ></v-text-field>
                        <span
                          v-if="baseOrderInfo.persons_per_guide !== '0'"
                          class="caption ml-3"
                        >
                          Максимально разрешенное количество человек на одного гида:
                          {{ baseOrderInfo.persons_per_guide }}
                        </span>
                      </v-col>
                    </v-row>
                    <!-- Выдача -->
                    <v-row>
                      <v-col
                        v-if="baseOrderInfo.museum == 148"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-text-field
                          v-model="order.receiving"
                          type="text"
                          outlined
                          dense
                          :label="$t('Выдача: место, время')"
                          :placeholder="$t('Выдача: место, время')"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Возврат -->
                    <v-row>
                      <v-col
                        v-if="baseOrderInfo.museum == 148"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-text-field
                          v-model="order.returning"
                          type="text"
                          outlined
                          dense
                          :label="$t('Возврат: место, время')"
                          :placeholder="$t('Возврат: место, время')"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Пожелания -->
                    <v-row>
                      <v-col
                        class="pb-0"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-textarea
                          v-model="order.comment"
                          :label="$t('Пожелания')"
                          rows="3"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <!-- Удалить  -->
                    <v-row>
                      <v-col
                        v-if="orders.length > 1 && index !== 0"
                        class="pt-0 pb-0"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-btn
                          small
                          color="error"
                          @click="removeOrder(index)"
                        >
                          <v-icon
                            size="19"
                            class="mr-2"
                          >
                            {{ icons.mdiDelete }}
                          </v-icon>
                          Удалить
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Отправить -->
              <v-col
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <v-btn
                  color="primary"
                  class="mr-2 mb-2"
                  @click="sendOrders"
                >
                  {{ $t("Отправить заказ") }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  class="mr-2 mb-2"
                  @click="addOrder"
                >
                  {{ $t("Добавить группу на эту дату") }}
                </v-btn>
              </v-col>
              <!-- Отправка заявки -->
              <v-dialog
                v-model="isSending"
                overlay
                persistent
                width="300"
              >
                <v-card
                  color="primary"
                  dark
                >
                  <v-card-text class="pt-3 white--text">
                    {{ $t("Пожалуйста, ожидайте") }}
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0 mt-2"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Результат отправки -->
              <v-dialog
                v-model="isShowMessage"
                width="500"
              >
                <v-card>
                  <v-card-title v-if="isSuccess">
                    {{ $t("Успешно") }}
                  </v-card-title>
                  <v-card-title v-else>
                    {{ $t("Ошибка") }}
                  </v-card-title>

                  <v-card-text v-if="isSuccess">
                    {{ successMsg }}
                  </v-card-text>
                  <v-card-text v-else>
                    {{ errorMsg }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="isSuccess"
                      color="primary"
                      @click="closeMessageBoxAndRedirect"
                    >
                      {{ $t("Ок") }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="error"
                      @click="closeMessageBox"
                    >
                      {{ $t("Ок") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Datepicker from "@/components/Datepicker.vue"
import store from "@/store"
import { useRouter } from "@core/utils"
import { integerPositiveValidator, required } from "@core/utils/validation"
import {
  mdiCalendar, mdiDelete, mdiHelpCircleOutline, mdiPlus,
} from "@mdi/js"
import { computed, ref } from "@vue/composition-api"

export default {
  components: {
    Datepicker,
  },
  watch: {
    // eslint-disable-next-line func-names
    "order.guideName": function (newValue) {
      localStorage.setItem("guideName", newValue)
    },
    // eslint-disable-next-line func-names
    "order.guidePhone": function (newValue) {
      localStorage.setItem("guidePhone", newValue)
    },
  },
  created() {
    if (localStorage.getItem("guideName")) {
      this.baseOrderInfo.guideName = localStorage.getItem("guideName")
    }
    if (localStorage.getItem("guidePhone")) {
      this.baseOrderInfo.guidePhone = localStorage.getItem("guidePhone")
    }
  },
  setup() {
    const { router } = useRouter()
    const datepickerKey = ref(0)
    function createEmptyOrder() {
      return {
        tickets: null,
        guides: "0",
        receiving: "",
        returning: "",
        comment: "",
        guidePhone: "",
        guideName: "",
      }
    }
    const baseOrderInfo = ref({
      museum: null,
      date: new Date().toISOString().substr(0, 10),
      tickets: null,
      type: null,
      guidePhone: "",
      guideName: "",
      persons_per_guide: "0",
    })
    const orders = ref([createEmptyOrder()])
    createEmptyOrder()
    const addOrder = () => {
      orders.value.push(createEmptyOrder())
    }
    const removeOrder = index => {
      orders.value.splice(index, 1)
    }
    const sendOrders = () => {
      const resultedObjectList = []
      orders.value.forEach(order => {
        const mergedObject = { ...baseOrderInfo.value, ...order }
        resultedObjectList.push(mergedObject)
      })
      store.dispatch("createOrder/createOrderForRgAg", resultedObjectList)
    }
    const valid = ref(true)
    const orderForm = ref(null)
    const userData = JSON.parse(localStorage.getItem("userData"))

    const fetchMuseums = () => {
      baseOrderInfo.value.persons_per_guide = "0"
      store.dispatch("museums/fetchMuseumsForRgAg")
    }
    fetchMuseums()

    const fetchSchedule = () => {
      // get museumsList
      const selectedMuseum = store.getters["museums/getMuseumsList"].find(
        museum => museum.system_id === baseOrderInfo.value.museum,
      )
      baseOrderInfo.value.persons_per_guide = selectedMuseum.persons_per_guide
      const formData = {
        date: baseOrderInfo.value.date,
        museumObjectId: baseOrderInfo.value.museum,
      }
      store.dispatch("schedule/fetchSchedule", formData)
      store.dispatch("museums/setMuseum", selectedMuseum)

      // datepickerKey.value += 1
    }
    const setService = () => {
      baseOrderInfo.value.museum = null
      if (baseOrderInfo.value.type === "rg-city") {
        const rgCity = store.getters["museums/getMuseumsList"].find(
          museum => museum.is_radioguide_request === true && museum.is_radioguide_request_in_city === true,
        )
        baseOrderInfo.value.museum = rgCity.system_id
        store.dispatch("museums/setMuseum", rgCity)
        fetchSchedule()
      }
    }

    const handleDateChanges = event => {
      baseOrderInfo.value.date = event.date
      fetchSchedule()
    }

    //   const sendOrder = () => {
    //     store.dispatch('createOrder/createOrderForRgAg', baseOrderInfo)
    //   }

    const closeMessageBoxAndRedirect = () => {
      store.commit("createOrder/close_message")
      router.push({ name: "upcoming-requests" })
    }
    const closeMessageBox = () => {
      store.commit("createOrder/close_message")
    }
    store.commit("museumObjects/init_selected_museum_object")

    const getFormatedLocale = () => {
      const locale = localStorage.getItem("locale") || "ru"
      if (locale === "zh") {
        return "_zh_cn"
      }
      if (locale === "en") {
        return "_en_US"
      }
      if (locale === "ru") {
        return "_ru_RU"
      }
      if (locale === "fa") {
        return "_fa_IR"
      }
      if (locale === "vi") {
        return "_vi"
      }

      return "_ru_RU"
    }
    const getFilteredMuseumList = museumList => {
      if (!baseOrderInfo.value.type) {
        return museumList
      }
      switch (baseOrderInfo.value.type) {
        case "ag":
          return museumList.filter(museum => museum.is_audioguide_request === true)
        case "rg-museum":
          return museumList.filter(
            museum => museum.is_radioguide_request === true && museum.is_radioguide_request_in_city === false,
          )
        case "rg-city":
          return museumList.filter(
            museum => museum.is_radioguide_request === true && museum.is_radioguide_request_in_city === true,
          )
        default:
          return museumList
      }
    }

    return {
      baseOrderInfo,
      orderForm,
      valid,
      userData,
      fetchMuseums,
      fetchSchedule,
      handleDateChanges,
      closeMessageBoxAndRedirect,
      closeMessageBox,
      datepickerKey,
      validators: {
        required,
        integerPositiveValidator,
      },
      icons: {
        mdiPlus,
        mdiDelete,
        mdiCalendar,
        mdiHelpCircleOutline,
      },
      orders,
      addOrder,
      removeOrder,
      sendOrders,
      getFormatedLocale,
      getFilteredMuseumList,
      setService,
      typeList: computed(() => ({
        "_zh_cn": [
          { name: "无线电导览城市", value: "rg-city" },
          { name: "无线电导览博物馆", value: "rg-museum" },
          { name: "音频导览", value: "ag" },
        ],
        "_ru_RU": [
          { name: "Радиогид в город", value: "rg-city" },
          { name: "Радиогид в музей", value: "rg-museum" },
          { name: "Аудиогид", value: "ag" },
        ],
        "_en_US": [
          { name: "Radioguide City", value: "rg-city" },
          { name: "Radioguide Museum", value: "rg-museum" },
          { name: "Audioguide", value: "ag" },
        ],
        "_fa_IR": [
          { name: "راهنمای رادیویی شهر", value: "rg-city" },
          { name: "راهنمای رادیویی موزه", value: "rg-museum" },
          { name: "راهنمای صوتی", value: "ag" },
        ],
        "_vi": [
          { name: "Hướng dẫn bằng sóng vô tuyến thành phố", value: "rg-city" },
          { name: "Hướng dẫn bằng sóng vô tuyến", value: "rg-museum" },
          { name: "Hướng dẫn âm thanh", value: "ag" },
        ],
      })),
      museumsList: computed(() => store.getters["museums/getMuseumsList"]),
      schedule: computed(() => store.getters["schedule/getSchedule"]),
      isSending: computed(() => store.getters["createOrder/isSending"]),
      isShowMessage: computed(() => store.getters["createOrder/isShowMessage"]),
      successMsg: computed(() => store.getters["createOrder/successMsg"]),
      errorMsg: computed(() => store.getters["createOrder/errorMsg"]),
      isSuccess: computed(() => store.getters["createOrder/isSuccess"]),
      selectedMuseum: computed(() => store.getters["museums/getSelectedMuseum"]),
    }
  },
}
</script>
