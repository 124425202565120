<template>
  <div id="user-view">
    <atg-panel
      v-if="userData.atg"
      :key="rerenderKey"
      :user-data="userData"
      @forceRerender="forceRerender"
    ></atg-panel>
    <v-row v-else>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-panel
          :key="rerenderKey"
          :user-data="userData"
        ></user-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <user-settings
          :user-data="userData"
          @forceRerender="forceRerender"
        ></user-settings>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'

import store from '@/store'

import UserPanel from './UserPanel.vue'
import userStoreModule from './userStoreModule'
import UserSettings from './UserSetitngs.vue'
import AtgPanel from './AtgPanel.vue'

export default {
  components: {
    UserPanel,
    UserSettings,
    AtgPanel,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const rerenderKey = ref(1)
    const userData = ref({})

    userData.value = JSON.parse(localStorage.getItem('userData'))

    const forceRerender = () => {
      userData.value = JSON.parse(localStorage.getItem('userData'))
      rerenderKey.value += 1
    }

    return {
      userData,
      forceRerender,
      rerenderKey,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
