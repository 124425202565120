<template>
  <v-row>
    <v-col
      xs="12"
      sm="12"
      md="12"
      lg="10"
      cols="12"
    >
      <h2 class="secondary--text mb-6">
       {{$t('Мои лицензии')}}
      </h2>
      <v-dialog
        v-model="isDialogVisible"
        persistent
        max-width="600px"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              small
              class="me-2"
            >
              {{ icons.mdiPlus }}
            </v-icon>{{$t('Добавить лицензию')}}
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <span class="headline">{{$t('Добавить лицензию')}}</span>
          </v-card-title>
          <v-card-text>
            <v-container class="mt-4">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="newLicense.lic_object_id"
                    item-value="id"
                    item-text="name"
                    :items="licensingObjects"
                    :label="$t('Музей')"
                    dense
                    hide-details

                    outlined
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="newLicense.license_no"
                    :label="$t('Номер лицензии')"
                    dense
                    outlined
                    hide-details

                    hint=""
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newLicense.seniority"
                    :label="$t('Стаж (лет)')"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="datemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="newLicense.expiration_date"
                        :label="$t('Дата окончания')"
                        readonly
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newLicense.expiration_date"
                      color="primary"
                      no-title
                      locale="ru"
                      first-day-of-week="1"
                      :weekday-format="getDay"
                      @input="datemenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="newLicense.primary_lang_id"
                    item-value="id"
                    item-text="name"
                    :items="licensingLangs"
                    :label="$t('Основной язык')"
                    dense
                    hide-details

                    outlined
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="newLicense.additional_langs"
                    item-value="id"
                    item-text="name"
                    :items="licensingLangs"
                    :label="$t('Дополнительные языки')"
                    dense
                    outlined
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="isDialogVisible = false"
            >
              {{$t('Закрыть')}}
            </v-btn>
            <v-btn
              color="success"
              @click="addNewLicense()"
            >
              {{$t('Добавить')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <!-- loader -->
    <v-col
      v-if="!licensesRequestIsSuccess"
      xs="12"
      sm="12"
      md="12"
      lg="10"
      cols="12"
    >
      <loader></loader>
    </v-col>
    <!-- data -->
    <v-col
      v-if="licensesList.length > 0"
      xs="12"
      sm="12"
      md="9"
      lg="9"
      cols="12"
    >
      <v-card
        v-for="license in licensesList"
        :key="license.license_id"
        class="overflow-hidden mt-4"
      >
        <v-row class="ma-0 h-full">
          <!-- info -->
          <License
            :license="license"
            :licensing-langs="licensingLangs"
          ></License>
        </v-row>
      </v-card>
    </v-col>
    <v-col
      v-if="licensesRequestIsSuccess == true && licensesList.length == 0"
      xs="12"
      sm="12"
      md="9"
      lg="9"
      cols="12"
    >
      <p class="mt-6">
        {{$t('В настоящий момент нет сохраненных лицензий, вы можете добавить их.')}}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mdiPlus } from '@mdi/js'

import Loader from '@/components/Loader.vue'
import License from '@/components/License.vue'

import { computed, ref } from '@vue/composition-api'
import store from '@/store'

import { formatDate } from '@core/utils/filter'

export default {
    components: {
        Loader,
        License,
    },
    setup() {
        const isDialogVisible = ref(false)
        const newLicense = ref({
            lic_object_id: null,
            license_no: '',
            seniority: null,
            expiration_date: null,
            primary_lang_id: null,
            additional_langs: [],
        })
        const date = null
        const datemenu = ref(false)
        const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

        const getDay = currentDate => {
            const i = new Date(currentDate).getDay(currentDate)

            return daysOfWeek[i]
        }

        const fetchLicenses = () => {
            store.dispatch('licenses/fetchLicenses')
        }
        const fetchLicensesLangs = () => {
            store.dispatch('licenses/fetchLiclangs')
        }
        const fetchLicobjects = () => {
            store.dispatch('licenses/fetchLicobjects')
        }
        fetchLicenses()
        fetchLicensesLangs()
        fetchLicobjects()

        const addNewLicense = () => {
            isDialogVisible.value = false
            newLicense.value.seniority = Number(newLicense.value.seniority)
            store.dispatch('licenses/addLicense', newLicense.value).then(() => {
                isDialogVisible.value = false
                store.dispatch('licenses/fetchLicenses')
            })
        }

        return {
            formatDate,
            date,
            datemenu,
            getDay,
            newLicense,
            addNewLicense,

            icons: {
                mdiPlus,
            },
            isDialogVisible,

            licensesList: computed(() => store.getters['licenses/getLicenses']),
            licensingLangs: computed(() => store.getters['licenses/getLicensingLangs']),
            licensingObjects: computed(() => store.getters['licenses/getLicensingObjects']),
            licensesRequestIsSuccess: computed(() => store.getters['licenses/getReqStatus']),
        }
    },
}
</script>
