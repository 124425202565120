import axios from '@axios'

export default {
  namespaced: true,
  state: {
    /**
     * is_sending - отправляется ли в данный момент запрос
     * is_show_message - показывать ли сообщение о результате
     * request_is_success - успешно или нет
     * request_success_msg - текст сообщения если успешно
     * request_error_msg - текст сообщения об ошибке
     */
    is_sending: false,
    navigation_data: {
      is_show: false,
      navigation: null,
      chiefWorker: {},
    },
    is_show_message: false,
    request_is_success: null,
    request_success_msg: null,
    request_error_msg: null,
  },
  getters: {
    isSending: state => state.is_sending,
    getNavigationData: state => state.navigation_data,
    isShowMessage: state => state.is_show_message,
    successMsg: state => state.request_success_msg,
    errorMsg: state => state.request_error_msg,
    isSuccess: state => state.request_is_success,
  },
  mutations: {
    start_request_sending(state) {
      state.is_sending = true
    },
    set_on_request_success(state, msg) {
      state.request_success_msg = msg
      state.request_is_success = true
      state.is_sending = false
      state.is_show_message = true
      state.request_error_msg = null
    },
    set_on_request_location_success(state, data) {
      if (data.navigation) {
        state.navigation_data.navigation = true
      } else {
        state.navigation_data.is_show = true
        state.navigation_data.navigation = false
        state.navigation_data.chiefWorker = data.chiefWorker
      }
      state.is_sending = false
    },
    close_navigation_dialog(state) {
      state.navigation_data.is_show = false
    },
    close_message(state) {
      state.is_show_message = false
    },
    set_on_request_error(state, error) {
      state.request_is_success = false
      state.is_sending = false
      state.is_show_message = true
      state.request_error_msg = error
    },
  },
  actions: {
    getWorkerLocation({ commit }, museumId) {
      commit('start_request_sending')

      return new Promise((resolve, reject) => {
        const data = JSON.stringify({
          museum_id: String(museumId),
        })
        const postData = new FormData()
        postData.append('data', data)
        postData.append('action', 'get_location')
        axios({
          method: 'post',
          url: '/api/v1/actions/',
          data: postData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.status === 200) {
              commit('set_on_request_location_success', response.data.data)
              resolve(response.data.data)
            } else {
              commit('set_on_request_error', response.data.error)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    annulOrder({ commit }, order) {
      commit('start_request_sending')

      return new Promise((resolve, reject) => {
        const data = JSON.stringify({
          request_id: String(order.id),
        })
        const postData = new FormData()
        postData.append('data', data)
        postData.append('action', 'delete_request')
        axios({
          method: 'post',
          url: '/api/v1/actions/',
          data: postData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.status === 200) {
              if (response.data.data.result === null) {
                commit('set_on_request_success', `Заявка №${order.id} аннулирована`)
                resolve(response)
              } else {
                commit('set_on_request_error', response.data.error)
                resolve(response)
              }
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    archiveOrder({ commit }, order) {
      commit('start_request_sending')

      return new Promise((resolve, reject) => {
        const data = JSON.stringify({
          request_id: String(order.id),
        })
        const postData = new FormData()
        postData.append('data', data)
        postData.append('action', 'archive_request')
        axios({
          method: 'post',
          url: '/api/v1/actions/',
          data: postData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.status === 200) {
              if (response.data.success) {
                if (response.data.data.result === null) {
                  commit('set_on_request_success', `Заявка №${order.id} отправлена в архив`)
                  resolve(response)
                }
              } else {
                commit('set_on_request_error', response.data.error)
                resolve(response)
              }
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
