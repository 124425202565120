<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('Обратная связь') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            xs="12"
            sm="12"
            md="12"
            lg="10"
            cols="12"
          >
            <h2 class="text-2xl font-weight-semibold text--primary">
              {{ $t('Мы всегда рады услышать вас!') }}
            </h2>
            <p class="text-sm pt-6 pb-4">
              {{ $t('Наши усилия направлены на то, чтобы сделать систему подачи заявок максимально удобной и эффективной для вас. Ваше мнение и предложения играют ключевую роль в этом процессе. Не стесняйтесь делиться своими мыслями, идеями, а также сообщать нам обо всех замеченных ошибках или проблемах в работе системы. Вместе мы сделаем систему подачи заявок лучше!') }}
            </p>
          </v-col>
          <v-col
            xs="12"
            sm="12"
            md="12"
            lg="10"
            cols="12"
          >
            <v-text-field
              v-model="feedback.name"
              :label="$t('Как к вам обращаться?')"
              dense
              outlined
              hide-details
              hint=""
            ></v-text-field>
          </v-col>
          <v-col
            xs="12"
            sm="12"
            md="12"
            lg="10"
            cols="12"
          >
            <v-text-field
              v-model="feedback.email"
              :label="$t('Email для обратной связи')"
              dense
              outlined
              hide-details
              hint=""
            ></v-text-field>
          </v-col>
          <v-col
            xs="12"
            sm="12"
            md="12"
            lg="10"
            cols="12"
          >
            <v-textarea
              id="textarea-feedback-text"
              v-model="feedback.text"
              :label="$t('Опишите ваше предложение, жалобу или замеченную ошибку')"
              outlined
              auto-grow
              class="mt-2"
              rows="4"
            ></v-textarea>
          </v-col>
          <v-col
            xs="12"
            sm="12"
            md="12"
            lg="10"
            cols="12"
          >
            <v-btn
              color="primary"
              @click="sendFeedback"
            >
              {{ $t('Отправить') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="isShowMessage"
      overlay
      persistent
      width="500"
    >
      <v-card>
        <v-card-title v-if="isSuccess">
          {{ $t('Успешно') }}
        </v-card-title>
        <v-card-title
          v-else
        >
          {{ $t('Ошибка') }}
        </v-card-title>

        <v-card-text v-if="isSuccess">
          {{ successMsg }}
        </v-card-text>
        <v-card-text v-else>
          {{ errorMsg }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isSuccess"
            color="primary"
            @click="closeMessageBoxAndRedirect"
          >
            {{ $t('Ок') }}
          </v-btn>
          <v-btn
            v-else
            color="error"
            @click="closeMessageBox"
          >
            {{ $t('Ок') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

  <script>
  import { ref, computed } from '@vue/composition-api'
  import { useRouter } from '@core/utils'
  import store from '@/store'

  export default {
    setup() {
      const { router } = useRouter()
      const feedback = ref({
        text: '',
        name: '',
        email: '',
      })
      const sendFeedback = () => {
        store.dispatch('notify/subscribeEmailByLaunch', feedback)
      }
      const closeMessageBoxAndRedirect = () => {
        store.commit('updateOrder/close_message')
        router.push({ name: 'upcoming-requests' })
      }
      const closeMessageBox = () => {
        store.commit('actionsOrder/close_message')
      }

      return {
        feedback,
        sendFeedback,
        closeMessageBox,
        closeMessageBoxAndRedirect,
        isSuccess: computed(() => store.getters['notify/isSuccess']),
        isShowMessage: computed(() => store.getters['notify/isShowMessage']),
        errorMsg: computed(() => store.getters['notify/errorMsg']),
      }
    },
  }
  </script>

  <style lang="scss">
    @import '@core/preset/preset/misc.scss';
  </style>
