const appRoutes = [
    {
        path: '/',
        name: 'index',
        component: () =>
            import ('@/views/pages/index/Index.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/upcoming/info',
        name: 'order-info',
        component: () =>
            import ('@/views/pages/order/OrderInfo.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/upcoming/edit',
        name: 'order-edit',
        component: () =>
            import ('@/views/pages/order/OrderEdit.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/upcoming',
        name: 'upcoming-requests',
        component: () =>
            import ('@/views/pages/home/UpcomingRequests.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/new',
        name: 'new-request',
        component: () =>
            import ('@/views/pages/new/NewRequest.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/new-schedule',
        name: 'new-request-schedule',
        component: () =>
            import ('@/views/pages/new/NewRequestSchedule.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/new-rg-ag',
        name: 'new-request-rg-ag',
        component: () =>
            import ('@/views/pages/new/NewRequestRgAg.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/new-transport',
        name: 'new-request-transport',
        component: () =>
            import ('@/views/pages/new/NewRequestTransport.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/new-guide',
        name: 'new-request-guide',
        component: () =>
            import ('@/views/pages/new/NewRequestGuide.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/archive',
        name: 'archive-requests',
        component: () =>
            import ('@/views/pages/archive/ArchiveRequests.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/revise',
        name: 'revise',
        component: () =>
            import ('@/views/pages/revise/Revise.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/licenses',
        name: 'licenses',
        component: () =>
            import ('@/views/pages/licenses/Licenses.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/prolongation',
        name: 'prolongation',
        component: () =>
            import ('@/views/pages/licenses/Prolongation.vue'),
        meta: {
            layout: 'content',
        },
    },

    // {
    //     path: '/prolongation-price',
    //     name: 'prolongation-pricelist',
    //     component: () =>
    //         import ('@/views/pages/licenses/Prolongation.vue'),
    //     meta: {
    //         layout: 'content',
    //     },
    // },
    {
        path: '/news',
        name: 'news',
        component: () =>
            import ('@/views/pages/news/News.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/pricelist',
        name: 'pricelist',
        component: () =>
            import ('@/views/pages/payment/Pricelist.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ('@/views/pages/about/About.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: () =>
            import ('@/views/pages/feedback/Feedback.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/payment',
        name: 'payment',
        component: () =>
            import ('@/views/pages/payment/Pay.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/payment-wechat',
        name: 'payment-wechat',
        component: () =>
            import ('@/views/pages/on-development/onDevelopment.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/transport-order',
        name: 'transport-order',
        component: () =>
            import ('@/views/pages/on-development/onDevelopment.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/payments-link',
        name: 'payment-link',
        component: () =>
            import ('@/views/pages/payment/PaymentLink.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/payments-history',
        name: 'payments-history',
        component: () =>
            import ('@/views/pages/payment/PaymentsHistory.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/payment-confirmation',
        name: 'payment-confirmation',
        component: () =>
            import ('@/views/pages/payment/PaymentConfirmation.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: () =>
            import ('@/views/pages/settings/Settings.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () =>
            import ('@/views/pages/help/Help.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/messages',
        name: 'chat',
        component: () =>
            import ('@/views/pages/chat/Chat.vue'),
        meta: {
            layout: 'content',
        },
    },
]

export default appRoutes
