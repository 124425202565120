/* eslint-disable import/order */
<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="upcomingList"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            :object-info="objectInfo"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search>
          <!-- <p
            class="body-2 pt-4 pl-1 cursor-pointer"
            @click="shallShowFullSearch = !shallShowFullSearch"
          >
            Поиск по ref или номеру заявки
          </p> -->
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-i18n></app-bar-i18n>
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <!-- <app-bar-notification></app-bar-notification> -->

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between mt-4">
        <span>{{$t('ООО «АВАНГАРД ТРАВЕЛ ГРУПП»')}}, &copy; {{ new Date().getFullYear() }}</span>

        <div class="align-center d-none d-md-flex">
          <v-icon
            color="primary"
            class="ms-2 mr-1"
          >
            {{ icons.mdiLinkVariant }}
          </v-icon><a
            href="https://requests.6400228.ru"
            class="text-decoration-none"
            color="secondary"
          >{{$t('Старая версия сайта')}}</a>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import { getVuetify } from '@core/utils'
import store from '@/store'

import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import navMenuItems from '@/navigation/vertical'

// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'

import { mdiMenu, mdiHeartOutline, mdiLinkVariant } from '@mdi/js'

// Search Data

import { ref, computed } from '@vue/composition-api'
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import appBarSearchData from '@/assets/app-bar-search-data'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,
    AppBarI18n,
    AppBarThemeSwitcher,

    // AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      return appBarSearchQuery.value && isMatched
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiLinkVariant,
      },
      upcomingList: computed(() => {
        const rawUpcoming = store.getters['upcoming/getUpcomingList']
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < rawUpcoming.length; index++) {
          rawUpcoming[
            index
          ].search_data = `${rawUpcoming[index].id} ${rawUpcoming[index].data.refno} ${rawUpcoming[index].data.pass_date} ${rawUpcoming[index].info.museum}`
        }

        return rawUpcoming
      }),
      objectInfo: computed(() => store.getters['upcoming/getMuseumObjectsInfo']),
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
