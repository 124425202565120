export default {

    // меню
    'АТГ. Заявки': 'ATG. Đơn đăng ký',
    Заказы: 'Đơn đặt hàng',
    'Новый заказ': 'Đơn đặt hàng mới',
    'Билеты в музеи': 'Vé tham quan bảo tàng',
    'Подать график': 'Nộp (thông báo) lịch trình làm việc',
    'Радио/Аудиогиды': 'Radio / AudioGuide',
    Архив: 'Lưu trữ',
    Отчеты: 'Tổng kết',
    Сверка: 'Kiểm tra',
    Оплата: 'Thanh toán ',
    'Оплата Wechat': 'Thanh toán qua ứng dụng WeChat',
    'Оплата заявок': 'Thanh toán đơn đặt hàng',
    'История платежей': 'Lịch sử chi trả',
    'Прайс-лист': 'Đơn giá',
    'Продление лицензий': 'Gia hạn giấy phép hướng dẫn',
    'Мои лицензии': 'Giấy phép hướng dẫn  của tôi',
    Продление: 'Gia hạn',
    Информация: 'Thông tin',
    Новости: 'Tin mới / thời sự',
    Транспорт: 'Phương tiện vận chuyển',
    'О компании': 'Thông tin về công ty',
    Договор: 'Hợp đồng',
    'Договор оферты': 'Hợp đồng ưu đãi',
    'Политика возврата': 'Chính sách hoàn trả',
    'Поиск по ref, номеру заявки, дате или объекту': 'Tìm kiếm theo số định danh, theo đơn đặt hàng, theo ngày hoặc theo tên bảo tàng',
    Настройки: 'Cài đặt',
    Помощь: 'Trợ giúp',
    'Обратная связь': 'Liên hệ',
    Выйти: 'Thoát ra',
    'Старая версия сайта': 'Phiên bản cũ của trang web',
    'ООО «АВАНГАРД ТРАВЕЛ ГРУПП»': 'Công ty trách nhiệm hữu hạn Avangard Travel Group',

    // заказы
    'Нет предстоящих заказов': 'Không có đơn hàng sắp tới',
    'Выберите форму заказа': 'Lựa chọn hình thức đặt hàng',
    'Заказать билеты в музей': 'Đặt vé tham quan bảo tàng',
    'Заказать Радио/Аудиогиды': 'Đặt Radio / Đặt AudioGuide',
    Заявка: 'Đơn hàng',
    'В обработке': 'Đang trong quá trình xử lý',
    'Подтврждена': 'Khẳng định / xác nhận',
    'Лист ожидания': 'Danh sách chờ',
    'Аннулирована': 'Đã được hủy bỏ',
    'Отклонена': 'Đã bị từ chối',
    'Подтвержденное время:': 'Thời gian đã được xác nhận',
    'Вход': 'Lối vào / cổng vào',
    'ПОДРОБНЕЕ': 'Tìm hiểu thêm chi tiết',
    'ИЗМЕНИТЬ': 'Thay đổi',
    'ГДЕ СОТРУДНИК': 'Đồng nghiệp ở đâu',
    'АННУЛИРОВАТЬ': 'Hủy bỏ',
    'В АРХИВ': 'Trong kho lưu trữ',
    'Сотрудник на объекте': 'Đồng nghiệp đang có mặt tại địa điểm',
    'Сотрудник не на объекте': 'Đồng nghiệp không có mặt tại địa điểm',
    'Но вы можете связаться с нами:': 'Nhưng bạn có thể liên hệ với chúng tôi',
    'Аннулировать': 'Hủy bỏ',
    'Хотите аннулировать заявку?': 'Bạn muốn hủy bỏ đơn đăng ký',
    'Аннулированная заявка попадет в архив и восстановить ее будет невозможно': 'Đơn đăng ký hủy chuyển vào vùng Lưu trữ và không có khả năng khôi phục lại',
    'Отмена': 'Hủy bỏ',
    'Дата и время:': 'Ngày tháng và thời gian',
    'Имя гида:': 'Họ tên hướng dẫn viên',
    'Не заполнено': 'Không điền vào đủ',
    'Телефон гида:': 'Điện thoại hướng dẫn viên',
    'Пожелания:': 'Mong muốn (ghi chú)',
    'Подтвержденное время и вход:': 'Xác nhận thời gian và cổng vào',
    'Билеты:': 'Vé ',
    'Дополнительные услуги:': 'Dịch vụ bổ sung',
    'Причина отклонения:': 'Nguyên nhân bị từ chối',
    'Информация о группе:': 'Thông tin về đoàn khách (nhóm)',
    'Доп.услуги:': 'Dịch vụ bổ sung',
    'Доп.информация:': 'Thông tin bổ sung',
    'График': 'Lịch làm việc',
    'Гид': 'Hướng dẫn viên',
    'Заказать': 'Đặt hàng',

    // новый заказ
    'Информация о музее': 'Thông tin về bảo tàng',
    'Выберите категорию группы (РФ или ИГ), музей, дату и время': 'Lựa chọn danh mục khách hàng (công dân Nga hay khách nước ngoài), Bảo tàng, ngày tháng và thời gian',
    'Выберите категорию группы': 'Lựa chọn danh mục khách hàng ',
    'Выберите музей': 'Lựa chọn bảo tàng',
    'Выберите объект': 'Lựa chọn địa điểm',
    Далее: 'Tiếp tục',
    'Выберите дату': 'Lựa chọn ngày tháng',
    'Выберите время': 'Lựa chọn thời gian',
    'Информация о группе': 'Thông tin về nhóm khách ',
    'Выберите кол-во билетов': 'Lựa chọn số lượng vé ',
    'Выберите страну': 'Lựa chọn quốc gia',
    Назад: 'Về trang trước (Quay lại trang trước)',
    'Доп. услуги': 'Dịch vụ bổ sung',
    'Выберите дополнительные услуги': 'Lựa chọn dịch vụ bổ sung',
    'Кол-во гидов сопровождающих (если нет лицензии)': 'Số lượng hướng dẫn viên cần đặt (nếu không có hướng dẫn viên có giấy phép)',
    'Необходим если у гида нет лицензии в выбранный музей': 'Cần đặt nếu đoàn không có hướng dẫn viên có giấy phép làm việc trong bảo tàng này',
    'Доп. информация': 'Thông tin bổ sung',
    'Информация о гиде и пожелания': 'Thông tin về hướng dẫn viên và mong muốn',
    'Ref номер (необязательно)': 'Số định danh (không bắt buộc)',
    'Уникальный номер группы / вспомогательный номер для ваших целей': 'Mã số của đoàn / Số phụ trợ dành cho mục đích của bạn',
    'Имя гида': 'Họ tên hướng dẫn viên',
    'Телефон гида': 'Số điện thoại hướng dẫn viên',
    Пожелания: 'Mong muốn (ghi chú)',
    'В поле "Пожелания" укажите:': 'Trong mục "Mong muốn (ghi chú)" ghi rõ',
    'адрес и время получения': 'Địa chỉ và thời gian nhận ',
    'адрес и приблизительное время возврата оборудования': 'Địa chỉ và thời gian giao trả lại thiết bị',
    Отправить: 'Gửi đi',
    'Пожалуйста, ожидайте': 'Xin vui lòng chờ',
    'Успешно!': 'Thành công',
    'Заказ создан': 'Đơn hàng đã tạo xong',
    'Ошибка:': 'Lỗi',
    Ок: 'OK',
    'Подача графика на апрель 2024 закрывается 20 марта 2024.': 'Đăng ký lịch trình tháng 4 năm 2024 sẽ đóng lại vào ngày 20 tháng 3 năm 2024 ',
    'Кол-во взрослых': 'Số lượng người lớn',
    'Кол-во детей': 'Số lượng trẻ em',
    'итого:': 'Tổng số',
    'чел. (надо': 'Số lượng khách',
    'экскурс.обсл.)': 'Dịch vụ tham quan',
    'гид. или сопровожд.)': 'Hướng dẫn viên hoặc nhân viên đi kèm',
    'Радиогид': 'RadioGuide',
    'Экскурсия ведется лицензионным гидом': 'Chương trình tham quan được hướng dẫn bởi hướng dẫn viên có giấy phép hợp lệ',
    'Аудиогид': 'AudioGuide',
    'Экскурсия ведется при помощи технических средств на языке группы': 'Chương trình tham quan được hướng dẫn bởi thiết bị điện tử bằng ngôn ngữ của đoàn',
    'Кол-во гидов с лицензией': 'Số lượng hướng dẫn viên có giấy phép hành nghề',
    'Кол-во сопровождающих': 'Số lượng nhân viên đi kèm',
    'Удалить': 'Hủy bỏ',
    'Отправить заказ': 'Gửi đơn đặt hàng',
    'Добавить группу на эту дату': 'Thêm đoàn khách vào ngày này',
    'Группа': 'Đoàn khách',

    // архив
    'Номер заявки или ref': 'Mã số đơn đăng ký hoặc mã số định danh',
    Объект: 'Địa điểm',
    'Номер заявки': 'Mã số đơn đăng ký',
    Все: 'Tất cả',
    'Дата посещения с': 'Ngày tham quan từ',
    'Дата посещения по': 'Ngày tham quan đến',
    Найти: 'Tìm kiếm',
    'Заказов согласно фильтру не найдено': 'Đơn đặt hàng theo bộ lọc không được tìm thấy',
    Ошибка: 'Lỗi',
    'Любой номер': 'Số bất kỳ',

    // сверка
    Сформировать: 'Tạo lập',
    'Файл сверки': 'File kiểm tra',
    'Для скачивания сформированного файла сверки нажмите на конпку "Скачать".': 'Để tải file "Kiểm tra" hãy nhấn nút "Tải về"',
    'Скачать': 'Tải về',

    // оплата заявок
    'Оплатить заявки': 'Thanh toán đơn đăng ký',
    'Номера ваучеров или заявок для оплаты (через запятую)': 'Số voucher hoặc mã số đơn đăng ký (cách dấu phẩy)',
    'Введите номера ваучеров или заявок для оплаты': 'Điền số voucher hoặc mã số đơn đăng ký để thanh toán',
    'ФИО или название организации': 'Họ tên hoặc tên công ty',
    'Введите ФИО или название организации': 'Điền họ tên hoặc tên công ty',
    Email: 'Email',
    'Введите Email': 'Điền địa chỉ email',
    'Сумма к оплате': 'Tổng số tiền cần thanh toán',
    'Введите сумму к оплате': 'Điền tổng số tiền cần thanh toán',
    'Согласен с договором оферты': 'Theo hợp đồng đề nghị',
    'Перейти к оплате': 'Tiến hành thanh toán',
    'Подождите,': 'Xin vui lòng chờ,',
    'принимаем': 'Chấp nhận thanh toán',
    'оплату...': 'Thanh toán...',
    'Заказ успешно оплачен!': 'Đơn đặt hàng đã thanh toán thành công',
    'Ваш заказ': 'Đơn đặt hàng của bạn',
    'успешно оплачен. Спасибо за покупку!': 'Thanh toán thành công. Cảm ơn vì đã mua hàng!',
    'Что-то пошло не так!': 'Đã xảy ra lỗi!',
    'При оплате заказа': 'Trong quá trình thanh toán đơn hàng',
    'произошла ошибка!': 'Đã xảy ra lỗi',
    'Cчет успешно сформирован!': 'Hóa đơn đã được tạo lập ',
    'Вы можете открыть его нажав кнопку ниже': 'Bạn có thể mở hóa đơn khi nhấn nút ở phía dưới',
    'Открыть счет': 'Hóa đơn đã được mở',
    'Физическим лицам': 'Khách hàng cá nhân',
    'Юридическим лицам': 'Khách hàng pháp nhân công ty',
    'Для формирования счета на оплату введите номера ваучеров или заказов, укажите реквизиты компании и точную сумму:': 'Để tạo lập hóa đơn thanh toán, hãy điền số Voucher hoặc số đơn đặt hàng,hãy điền thông ty công ty và tổng tiền thanh toán ',
    'Для оплаты введите номера ваучеров или заявок и укажите точную сумму для оплаты:': 'Để trả tiền, hãy điền số Voucher hoặc mã số đơn đăng ký, hãy điền tổng tiền cần chi trả',
    'Название организации': 'Tên công ty',
    'Юридический адрес': 'Địa chỉ pháp nhân công ty',
    'Сформировать счет': 'Tạo lập hóa đơn',
    'Расчетный счет': 'Tài khoản thanh toán của công ty',
    'ИНН': 'Mã số thuế công ty',
    'КПП': 'KPP',
    'Банк': 'Ngân hàng',
    'Полное название банка (для формирования акта). Например: ': 'Tên ngân hàng (để thanh lý đơn đặt hàng). Ví dụ:',
    'БИК Банка': 'BIK của ngân hàng',
    'Корреспондентский счет': 'Tài khoản trung chuyển của ngân hàng',

    // история платежей
    'Номер платежа': 'Số thanh toán',
    Ваучеры: 'Chứng từ',
    'Способ оплаты': 'Hình thức chi trả',
    'Время и дата оплаты': 'Thời gian và ngày tháng chi trả',
    Сумма: 'Tổng số',
    Статус: 'Trạng thái (tình trạng)',
    'Платежи отсутствуют': 'Không có các thanh toán ',

    // прайс-лист
    'Прайс-лист для иностранных групп': 'Danh sách đơn giá dành cho đoàn khách nước ngoài',
    'Актуальный файл с ценами для иностранных групп.': 'File "Đơn giá hiện tại dành cho đoàn khách nước ngoài"',
    Открыть: 'Mở',
    'Прайс-лист для российских групп': 'Danh sách đơn giá dành cho đoàn khách Nga',
    'Актуальный файл с ценами для российских групп.': 'File "Đơn giá hiện tại dành cho đoàn khách Nga"',

    // лицензии
    'Добавить лицензию': 'Thêm giấp phép',
    'В настоящий момент нет сохраненных лицензий, вы можете добавить их.': 'Hiện tại không có dữ liệu về giấy phép trong lưu trữ, bạn có thể thêm vào',
    'Добавить': 'Thêm',
    'Музей': 'Bảo tàng',
    'Номер лицензии': 'Số giấy phép',
    'Стаж (лет)': 'Kinh nghiệm (năm)',
    'Дата окончания': 'Ngày kết thúc',
    'Основной язык': 'Ngôn ngữ chính',
    'Дополнительные языки': 'Ngôn ngữ phụ',

    // о компании
    'ИНН:7841027155': 'Mã số thuế INN:7841027155',
    'КПП: 784101001': 'KPP: 784101001',
    'ОГРН: 1157847276003': 'OGRN: 1157847276003',
    'ОКПО: 25885413': 'OKPO: 25885413',
    'Расчетный счет: 40702810603260000188': 'Tài khoản giao dịch: 40702810603260000188',
    'Банк: Филиал "Центральный" Банка ВТБ (ПАО)': 'Ngân hàng: Chi nhánh Trung tâm, Ngân hàng ngoại thương VTB / Филиал "Центральный" Банка ВТБ (ПАО)',
    'БИК: 044525411': 'BIK: 044525411',
    'Корр. счет: 30101810145250000411': 'Tài khoản trung chuyển: 30101810145250000411',
    'Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405': 'Địa chỉ công ty: 191186, Russia, Saint-Petersburg, nabereznaya Kanala Griboedova, nhà số 5, LIT.E, mặt bằng 19-H, văn phòng 405',
    'Email: info@avangard-travel.ru': 'Email: info@avangard-travel.ru',
    'Телефон: (812) 640-02-28': 'Điện thoại: +7(812) 640-02-28',

    // договор
    'Вы можете посмотреть договор с ООО "Авангард Травел Групп".': 'Bạn có thểm xem mẫu hợp đồng của công ty trách nhiệm hữu hạn "Avangard Travel Group"',

    // договор оферты
    'Вы можете посмотреть договор оферты с ООО "Авангард Травел Групп".': 'Bạn có thểm xem hợp đồng ưu đãi của công ty trách nhiệm hữu hạn "Avangard Travel Group"',

    // политика возврата
    'Вы можете посмотреть политику возврата.': 'Bạn có thểm xem các chính sách hoàn trả',

    // настройки
    'Имя:': 'Họ tên',
    'Телефон:': 'Số điện thoại',
    'Страна:': 'Quốc gia',
    'Информация о гиде': 'Thông tin về hướng dẫn viên',
    'Заполните информацию и подавайте заявки еще быстрее': 'Hãy điền thêm thông tin để gửi đơn đăng ký nhanh hơn',
    Имя: 'Họ tên',
    Телефон: 'Số điện thoại',
    'Выберите страницу': 'Hãy lựa chọn quốc gia',
    'Использовать в заказе?': 'Sử dụng trong đơn đặt hàng',
    Сохранить: 'Lưu giữ',
    'Являетесь ли вы туристической фирмой / юридическим лицом': 'Bạn là công ty du lịch / hoặc pháp nhân công ty',
    'Юридическое лицо': 'Pháp nhân công ty',
    'Для выставления счетов': 'Dùng để tạo lập hóa đơn thanh toán',

    // форма логина
    Логин: 'Tên đăng nhập',
    Пароль: 'Mật khẩu',
    Войти: 'Đăng nhập vào tài khoản',
    'Выберите язык': 'Hãy chọn ngôn ngũ',
    'У вас нет аккаунта?': 'Bạn chưa có tài khoản?',
    'Зарегистрироваться': 'Đăng ký',
    'Добро пожаловать!': 'Chào mừng bạn!',
    'Будем рады видеть вас среди наших клиентов! Пожалуйста, уделите немного времени на заполнение формы ниже. Вскоре мы отправим вам все необходимые инструкции на указанный адрес электронной почты.': 'Chúng tôi rất vui mừng khi bạn trở thành khách hàng của chúng tôi! Hãy dành một chút thời gian để điền vào mẫu dưới đây. Chúng tôi sẽ gửi cho bạn các hướng dẫn cần thiết qua địa chỉ email của bạn.',
    'Фамилия': 'Họ',
    'Физ.лицо': 'Cá nhân',
    'Юр.лицо': 'Pháp nhân',
    'Отчество': 'Tên đệm',
    'У вас есть аккаунт?': 'Bạn đã có tài khoản?',
    'Название фирмы': 'Tên công ty',
    'БИК': 'BIK',
    'ФИО контактного лица': 'Họ tên người liên hệ',
    'Заявка принята!': 'Đơn đăng ký đã được ghi nhận!',
    'Спасибо за вашу заявку! В ближайшее время на указанный вами адрес электронной почты мы отправим все необходимые инструкции.': 'Cảm ơn vì đã gửi đơn đăng ký! Chúng tôi sẽ gửi cho bạn các hướng dẫn cần thiết qua địa chỉ email của bạn.',
    'Закрыть': 'Đóng',

    // уведомление
    'Теперь можно оплатить заявки онлайн, включая оплату от юридических лиц!': 'Hiện tại bạn có thể thanh toán trực tuyến, kể cả thanh toán dành cho các pháp nhân công ty',
    'Мы рады сообщить, что теперь вы можете оплатить заявки на нашем сайте, используя банковскую карту или QR-код системы быстрых платежей. Вдобавок к этому, мы расширили наши возможности и теперь доступна оплата и для юридических лиц. Юридические лица могут самостоятельно генерировать счета для оплаты.': 'Chúng tôi hân hạnh thông báo: Hiện tại bạn có thể thanh toán trực tuyến trên trang web của chúng tôi bằng thẻ ngân hàng hoặc qua mã QR trong hệ thống thanh toán nhanh SBP. Hình thức thanh toán này áp dụng cho khách hàng cá nhân và cho cả các pháp nhân công ty. Các hóa đơn sẽ được tự động tạo ra khi các pháp nhân công ty  tiến hành thanh toán trực tuyến trên trang web của chúng tôi.',
    'Никаких задержек и лишних хлопот - оплата проходит мгновенно. Перейдите в раздел "Оплата заявок" и оплачивайте свои заявки быстро и удобно! Наши новые возможности сделают процесс ещё более удобным и эффективным.': 'Bạn sẽ không phải chờ đợi hoặc làm các thao tác mất thời gian khác - thanh toán sẽ được thực hiện ngay lập tức. Hãy chọn ấn nút "Thanh toán đơn hàng" và chi trả đơn hàng một cách nhanh chóng và thuận tiện. Những khả năng mới trong thanh toán của chúng tôi khiến quá trình đặt và thanh toán thuận tiện và hiệu quả.',

    // помощь
    'У вас есть вопросы?': 'Bạn có các câu hỏi?',
    'Если вы не можете найти ответ на вопрос, вы всегда можете связаться с нами. Мы ответим вам в ближайшее время!': 'Nếu bạn không thể tìm thấy giải đáp cho các câu hỏi, bạn luôn luôn có thể liên hệ với chúng tôi. Chúng tôi sẽ trả lời bạn trong thowifw gian ngắn nhất!',
    'Мы всегда рады помочь!': 'Chúng tôi luôn luôn sẵn lòng trợ giúp!',
    'Лучший способ получить ответ быстрее!': 'Cách tốt nhất để được giải đáp nhanh nhất!',

    // в разработе
    'Готовимся к запуску. Новая функция в пути! 🚀': 'Chuẩn bị khởi động. Tính năng mới đang được triển khai!',
    'Мы активно работаем над новой функцией для вас.': 'Chúng tôi tích cực phát triển những tính năng mới dành cho bạn!',
    'Будьте в курсе предстоящих обновлений!': 'Hãy chú ý đến những cập nhật mới!',

    // Обратная связь
    'Мы всегда рады услышать вас!': 'Chúng tôi luôn vui mừng nghe thấy bạn!',
    'Наши усилия направлены на то, чтобы сделать систему подачи заявок максимально удобной и эффективной для вас. Ваше мнение и предложения играют ключевую роль в этом процессе. Не стесняйтесь делиться своими мыслями, идеями, а также сообщать нам обо всех замеченных ошибках или проблемах в работе системы. Вместе мы сделаем систему подачи заявок лучше!': 'Những nổ lực của chúng tôi hướng đến việc tạo ra một hệ thống đăng ký đơn hàng thuận tiện và hiệu quả. Ý kiến và ý tưởng của bạn rất quan trọng với chúng tôi trong quá trình này. Xin vui lòng chia sẻ với chúng tôi những ý tưởng, kinh nghiệm và các vấn đề bất cập, các lỗi kỹ thuật mà bạn gặp phải trong quá trình làm việc ở hệ thống của chúng tôi. Hãy cùng nhau phát triển hệ thống tốt và hiệu quả hơn.',
    'Как к вам обращаться?': 'Làm thế nào để liên hệ với bạn?',
    'Email для обратной связи': 'Email để liên hệ',
    'Опишите ваше предложение, жалобу или замеченную ошибку': 'Hãy gửi những đề nghị, phàn nàn của bạn hoặc thông tin về lỗi kỹ thuật cho chúng tôi!',
}
