<template>
  <v-card elevation="2">
    <v-card-title>
      <span class="title pb-4">Заказ на транспорт {{ orderIndex + 1 }}</span>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="transportForm"
        lazy-validation
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="order.date"
              label="Дата"
              type="date"
              required
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="order.transportType"
              :items="transportTypes"
              label="Выберите вид транспорта"
              required
              item-text="name"
              hide-details
              item-value="system_id"
              outlined
              dense
              @change="emitFetchSchedule(orderIndex)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-card-text class="px-0 py-0 mx-0 my-0">
              {{ $t("Минимальный заказ - 3 часа, и время зависит от транспорта.") }}
            </v-card-text>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="order.startTime"
              label="Время начала"
              type="time"
              required
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="order.startAddress"
              label="Адрес начала"
              required
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="order.endTime"
              label="Время окончания"
              type="time"
              required
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="order.endPlace"
              label="Адрес окончания"
              required
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="order.routeDescription"
              label="Описание маршрута"
              outlined
              dense
              hide-details
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="order.notes"
              label="Примечания"
              outlined
              dense
              hide-details
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row-reverse"
          >
            <v-btn
              v-if="transportTypes.length > 1 && orderIndex !== 0"
              color="error"
              @click="emitDeleteGroup(orderIndex)"
            >
              <v-icon
                size="19"
                class="mr-2"
              >
                {{ icons.mdiDelete }}
              </v-icon>
              {{ $t("Удалить") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDelete } from "@mdi/js";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderIndex: {
      type: Number,
      required: true,
    },
    transportTypes: {
      type: Array,
      required: true,
    },
  },
  emits: ["deleteGroup", "fetchSchedule"],
  methods: {
    emitDeleteGroup(index) {
      this.$emit('deleteGroup', index);
    },
    emitFetchSchedule(index) {
      this.$emit('fetchSchedule', index);
    },
  },
  setup() {
    return {
      icons: {
        mdiDelete,
      },
    }
  },
}
</script>
