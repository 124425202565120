<template>
  <div class="user-tab-security">
    <!-- edit user data -->
    <v-card class="user-edit-info mb-7">
      <v-card-title>
        {{ $t('Информация о гиде') }}
      </v-card-title>
      <v-card-text class="mt-n2">
        {{ $t('Заполните информацию и подавайте заявки еще быстрее') }}
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.default_guide_name"
                outlined
                dense
                :label="$t('Имя')"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.default_guide_phone"
                outlined
                dense
                :label="$t('Телефон')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-select
                v-model="userDataLocal.default_guide_country"
                :items="countryList"
                item-text="name"
                item-value="name"
                :label="$t('Выберите страну')"
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="userDataLocal.use_default_data"
                class="mt-0"
                hide-details
              >
                <template #label>
                  <span class="font-weight-medium text--primary">{{ $t('Использовать в заказе?') }}</span>
                </template>
              </v-switch>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                {{ $t('Сохранить') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- is legal entity -->
    <v-card class="mb-7">
      <v-card-title>
        {{ $t('Юридическое лицо') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('Являетесь ли вы туристической фирмой / юридическим лицом') }}
      </v-card-subtitle>
      <v-card-text class="pb-2">
        <v-list
          dense
          class="py-0"
        >
          <v-list-item
            class="px-0"
          >
            <v-btn
              max-width="35"
              contain
              icon
              color="secondary"
              class="me-3"
            >
              <v-icon>
                {{ icons.mdiAccountTie }}
              </v-icon>
            </v-btn>

            <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div class="me-2">
                <v-list-item-title class="text-sm">
                  {{ $t('Юридическое лицо') }}
                </v-list-item-title>
                <v-list-item-subtitle class="mb-0">
                  {{ $t('Для выставления счетов') }}
                </v-list-item-subtitle>
              </div>

              <v-spacer></v-spacer>

              <v-switch
                v-model="userDataLocal.is_legal_entity"
                @change="onSubmit"
              ></v-switch>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <!-- push notifications -->
    <!-- <v-card class="mb-7">
      <v-card-title>
        Push уведомления
      </v-card-title>
      <v-card-subtitle>
        Получайте уведомления в браузере о новых сообщениях и изменении статуса заявок
      </v-card-subtitle>
      <v-card-text class="pb-2">
        <v-list
          dense
          class="py-0"
        >
          <v-list-item
            class="px-0"
          >
            <v-btn
              max-width="35"
              contain
              icon
              color="secondary"
              class="me-3"
            >
              <v-icon>
                {{ icons.mdiBellRing }}
              </v-icon>
            </v-btn>

            <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div class="me-2">
                <v-list-item-title class="text-sm">
                  Push уведомления
                </v-list-item-title>
                <v-list-item-subtitle class="mb-0">
                  Сообщения и статус заявки
                </v-list-item-subtitle>
              </div>

              <v-spacer></v-spacer>

              <v-switch
                v-model="userDataLocal.is_push_active"
                @change="onSubmit"
              ></v-switch>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
import { mdiBellRing, mdiAccountTie } from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const userDataLocal = ref({})
    userDataLocal.value = props.userData

    const fetchCountries = () => {
      store.dispatch('countries/fetchCountries')
    }
    fetchCountries()

    const onSubmit = () => {
      const stringifyUserData = JSON.stringify(userDataLocal.value)
      store.dispatch('app-user/updateUserDataOnServer', stringifyUserData).then(() => {
        store.dispatch('app-user/fetchUserDataFromServer').then(() => {
          emit('forceRerender')
        })
      })
    }

    watch(
      () => props.userData,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
      },
    )

    return {
      userDataLocal,
      onSubmit,
      icons: {
        mdiBellRing,
        mdiAccountTie,
      },
      countryList: computed(() => store.getters['countries/getCountries']),
    }
  },
}
</script>
