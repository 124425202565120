<template>
  <v-row>
    <!-- Уведомление -->
    <v-col
      cols="12"
      xs="12"
      sm="12"
      md="9"
    >
      <v-card class="px-4 py-4">
        <span class="subtitle">
          {{ $t("Подача графика на апрель 2024 закрывается 20 марта 2024.") }}
        </span>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      xs="12"
      sm="12"
      md="9"
    >
      <v-card>
        <v-card-text>
          <v-form
            ref="orderForm"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <!-- Категория группы -->
              <v-col
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <v-select
                  v-model="baseOrderInfo.groupType"
                  :items="typeList[getFormatedLocale()]"
                  :rules="[v => !!v || $t('Выберите категорию группы')]"
                  :label="$t('Выберите категорию группы')"
                  required
                  item-text="name"
                  item-value="value"
                  hide-details="auto"
                  class="mt-2"
                  outlined
                  dense
                  @change="fetchMuseums"
                >
                </v-select>
              </v-col>
              <!-- Выбор музея -->
              <v-col
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <v-select
                  v-model="baseOrderInfo.museum"
                  :items="museumsList"
                  :rules="[v => !!v || $t('Выберите музей')]"
                  :label="$t('Выберите музей')"
                  required
                  :item-text="'name' + getFormatedLocale()"
                  item-value="system_id"
                  hide-details="auto"
                  outlined
                  dense
                  @change="fetchSchedule"
                >
                </v-select>
              </v-col>
              <!-- ДАТА -->
              <v-col
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <datepicker
                  :key="datepickerKey"
                  class="mt-4"
                  on-page="new-request-date"
                  @newDate="handleDateChanges($event)"
                />
              </v-col>
              <v-col
                v-for="(order, index) in orders"
                :key="index"
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <v-card elevation="2">
                  <v-card-title>
                    <span class="title"> {{ $t("Группа") }} {{ index + 1 }} </span>
                  </v-card-title>
                  <v-card-text>
                    <!-- Время -->
                    <v-row>
                      <v-col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                      >
                        <v-select
                          v-model="order.schedule"
                          :items="schedule"
                          :rules="[v => !!v || $t('Выберите время')]"
                          :label="$t('Выберите время')"
                          required
                          item-text="name"
                          item-value="id"
                          hide-details="auto"
                          class=""
                          outlined
                          dense
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <!-- Кол-во человек -->
                    <v-row>
                      <v-col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        cols="12"
                      >
                        <v-text-field
                          id="amount"
                          key="amount"
                          v-model="order.tickets.adult"
                          type="number"
                          outlined
                          dense
                          :label="$t('Кол-во взрослых')"
                          hide-details
                          placeholder="0"
                          :rules="[validators.integerPositiveValidator]"
                        />
                      </v-col>
                      <v-col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        cols="12"
                      >
                        <v-text-field
                          id="amount"
                          key="amount"
                          v-model="order.tickets.child"
                          type="number"
                          outlined
                          dense
                          :label="$t('Кол-во детей')"
                          hide-details
                          placeholder="0"
                          :rules="[validators.integerPositiveValidator]"
                        />
                      </v-col>
                      <v-col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                        class="py-0 my-0"
                      >
                        <span
                          v-if="baseOrderInfo.persons_per_guide !== '0' && baseOrderInfo.groupType === 'ru'"
                          class="caption"
                        >
                          {{ $t("итого:") }} {{ Number(order.tickets.adult) + Number(order.tickets.child) }} {{ $t('чел. (надо') }} {{ Math.ceil((Number(order.tickets.adult) + Number(order.tickets.child))/Number(baseOrderInfo.persons_per_guide)) }} {{ $t('экскурс.обсл.)') }}
                        </span>
                        <!-- {{ baseOrderInfo }} -->
                        <span
                          v-if="baseOrderInfo.persons_per_guide !== '0' && baseOrderInfo.groupType === 'cn'"
                          class="caption"
                        >
                          {{ $t("итого:") }} {{ Number(order.tickets.adult) + Number(order.tickets.child) }} {{ $t('чел. (надо') }} {{ Math.ceil((Number(order.tickets.adult) + Number(order.tickets.child))/Number(baseOrderInfo.persons_per_guide)) }} {{ $t('гид. или сопровожд.)') }}
                        </span>
                      </v-col>
                    </v-row>
                    <!-- РГ / АГ / СОПР / Гид с лицензией -->
                    <v-row v-if="baseOrderInfo.groupType === 'cn'">
                      <v-col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        cols="12"
                        class="mt-0 pt-0 pb-0"
                      >
                        <v-checkbox
                          id="rg"
                          v-model="order.services.rg"
                          hide-details
                          class="mt-2 mb-6"
                          :disabled="checkIsServiceDisabled(index, 'rg')"
                        >
                          <template #label>
                            <div>
                              {{ $t('Радиогид') }}
                              <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    size="20"
                                    class="ml-2 info--secondary"
                                    v-on="on"
                                  >
                                    {{ icons.mdiHelpCircleOutline }}
                                  </v-icon>
                                </template>
                                <span>{{ $t('Экскурсия ведется лицензионным гидом') }}</span>
                              </v-tooltip>
                            </div>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          id="ag"
                          v-model="order.services.ag"
                          hide-details
                          class="mt-2 mb-6"
                          :disabled="checkIsServiceDisabled(index, 'ag')"
                        >
                          <template #label>
                            <div>
                              {{ $t('Аудиогид') }}
                              <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    size="20"
                                    class="ml-2 info--secondary"
                                    v-on="on"
                                  >
                                    {{ icons.mdiHelpCircleOutline }}
                                  </v-icon>
                                </template>
                                <span>{{ $t('Экскурсия ведется при помощи технических средств на языке группы') }}</span>
                              </v-tooltip>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        cols="12"
                        class="mt-0 pt-0 pb-0"
                      >
                        <v-text-field
                          v-model="order.services.license"
                          type="text"
                          outlined
                          dense
                          class="mb-6"
                          :label="$t('Кол-во гидов с лицензией')"
                          :placeholder="$t('Кол-во гидов с лицензией')"
                          hide-details="auto"
                          :disabled="checkIsServiceDisabled(index, 'license')"
                        ></v-text-field>
                        <v-text-field
                          v-model="order.services.sopr"
                          type="text"
                          outlined
                          dense
                          class="mb-4"
                          :label="$t('Кол-во сопровождающих')"
                          :placeholder="$t('Кол-во сопровождающих')"
                          hide-details="auto"
                          :disabled="checkIsServiceDisabled(index, 'sopr')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- REF номер -->
                    <v-row>
                      <v-col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        cols="12"
                        class="mt-0 pt-0 pb-0"
                      >
                        <v-text-field
                          v-model="order.refNumber"
                          type="text"
                          outlined
                          dense
                          class="mb-6"
                          :label="$t('Ref номер (необязательно)')"
                          :placeholder="$t('Ref номер (необязательно)')"
                          :hint="$t('Уникальный номер группы / вспомогательный номер для ваших целей')"
                          persistent-hint
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Удалить  -->
                    <v-col
                      v-if="orders.length > 1 && index !== 0"
                      class="pt-0 pb-0"
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      cols="12"
                    >
                      <v-btn
                        small
                        color="error"
                        @click="removeOrder(index)"
                      >
                        <v-icon
                          size="19"
                          class="mr-2"
                        >
                          {{ icons.mdiDelete }}
                        </v-icon>
                        {{ $t('Удалить') }}
                      </v-btn>
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Отправить -->
              <v-col
                xs="12"
                sm="12"
                md="12"
                lg="10"
                cols="12"
              >
                <v-btn
                  color="primary"
                  class="mr-2 mb-2"
                  @click="sendOrders"
                >
                  {{ $t("Отправить заказ") }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  class="mr-2 mb-2"
                  @click="addOrder"
                >
                  {{ $t("Добавить группу на эту дату") }}
                </v-btn>
              </v-col>
              <!-- Отправка заявки -->
              <v-dialog
                v-model="isSending"
                overlay
                persistent
                width="300"
              >
                <v-card
                  color="primary"
                  dark
                >
                  <v-card-text class="pt-3 white--text">
                    {{ $t("Пожалуйста, ожидайте") }}
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0 mt-2"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Результат отправки -->
              <v-dialog
                v-model="isShowMessage"
                width="500"
              >
                <v-card>
                  <v-card-title v-if="isSuccess">
                    {{ $t("Успешно!") }}
                    {{ $t("Заказ создан") }}
                  </v-card-title>
                  <v-card-title v-else>
                    {{ $t("Ошибка") }}
                  </v-card-title>

                  <v-card-text v-if="isSuccess">
                    {{ successMsg }}
                  </v-card-text>
                  <v-card-text v-else>
                    {{ errorMsg }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      v-if="isSuccess"
                      color="primary"
                      @click="closeMessageBoxAndCleanData"
                    >
                      {{ $t("Ок") }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="error"
                      @click="closeMessageBox"
                    >
                      {{ $t("Ок") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import store from '@/store'
import { integerPositiveValidator, required } from '@core/utils/validation'
import {
  mdiCalendar, mdiDelete, mdiHelpCircleOutline, mdiPlus,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  components: {
    Datepicker,
  },
  created() {
  },
  setup() {
    const datepickerKey = ref(0)
    const globalSelectedMuseum = ref(null)
    const valid = ref(true)
    const orderForm = ref(null)

    function createEmptyOrder() {
      return {
        tickets: {
          adult: 0,
          child: 0,
        },
        services: {
          rg: false,
          ag: false,
          sopr: '0',
          license: '0',
        },
        refNumber: '',
        guides: '0',
        guidePhone: '',
        guideName: '',
      }
    }

    const baseOrderInfo = ref({
      groupType: null,
      museum: null,
      date: new Date().toISOString().substr(0, 10),
      guidePhone: '',
      guideName: '',
      persons_per_guide: '0',
    })

    const orders = ref([createEmptyOrder()])

    const addOrder = () => { orders.value.push(createEmptyOrder()) }
    const removeOrder = index => { orders.value.splice(index, 1) }
    const checkIsServiceDisabled = (index, field) => {
      if (orders.value.length > 0) {
        switch (field) {
          case 'rg':
            return orders.value[index].services.ag || orders.value[index].services.sopr !== '0'
          case 'ag':
            return orders.value[index].services.rg || orders.value[index].services.license !== '0'
          case 'sopr':
            return orders.value[index].services.rg
          case 'license':
            return orders.value[index].services.ag
          default:
            return false
        }
      } else {
        return false
      }
    }
    const sendOrders = () => {
      const resultedObjectList = []
      orders.value.forEach(order => {
        const mergedObject = { ...baseOrderInfo.value, ...order }
        resultedObjectList.push(mergedObject)
      })
      store.dispatch('createOrder/createOrderForSheduleRequest', resultedObjectList)
    }

    const fetchMuseums = () => {
      baseOrderInfo.value.persons_per_guide = '0'
      store.dispatch('museums/fetchMuseumsForScheduleRequest', baseOrderInfo.value.groupType)
    }
    fetchMuseums()

    const fetchSchedule = () => {
      const selectedMuseum = store.getters['museums/getMuseumsList'].find(
        museum => museum.system_id === baseOrderInfo.value.museum,
      )
      globalSelectedMuseum.value = selectedMuseum
      baseOrderInfo.value.persons_per_guide = selectedMuseum.persons_per_guide
      const formData = {
        date: baseOrderInfo.value.date,
        museumObjectId: baseOrderInfo.value.museum,
      }
      store.dispatch('schedule/fetchSchedule', formData)
      store.dispatch('museums/setMuseum', selectedMuseum)
    }

    const handleDateChanges = event => {
      baseOrderInfo.value.date = event.date
      fetchSchedule()
    }

    const closeMessageBoxAndCleanData = () => {
      store.commit('createOrder/close_message')
      orders.value = [createEmptyOrder()]
      baseOrderInfo.value = {
        groupType: null,
        museum: null,
        date: new Date().toISOString().substr(0, 10),
        guidePhone: '',
        guideName: '',
        persons_per_guide: '0',
      }
    }
    const closeMessageBox = () => {
      store.commit('createOrder/close_message')
    }
    store.commit('museumObjects/init_selected_museum_object')

    const getFormatedLocale = () => {
      const locale = localStorage.getItem('locale') || 'ru'
      if (locale === 'zh') {
        return '_zh_cn'
      }
      if (locale === 'en') {
        return '_en_US'
      }
      if (locale === 'ru') {
        return '_ru_RU'
      }
      if (locale === 'fa') {
        return '_fa_IR'
      }
      if (locale === 'vi') {
        return '_vi'
      }

      return '_ru_RU'
    }

    return {
      baseOrderInfo,
      globalSelectedMuseum,
      orderForm,
      valid,
      fetchMuseums,
      fetchSchedule,
      handleDateChanges,
      closeMessageBoxAndCleanData,
      closeMessageBox,
      datepickerKey,
      validators: {
        required,
        integerPositiveValidator,
      },
      icons: {
        mdiPlus,
        mdiDelete,
        mdiCalendar,
        mdiHelpCircleOutline,
      },
      orders,
      addOrder,
      removeOrder,
      sendOrders,
      getFormatedLocale,
      checkIsServiceDisabled,
      typeList: computed(() => (localStorage.getItem('locale') === 'zh'
        ? {
            '_zh_cn': [{ name: '中国', value: 'cn' }],
        }
        : {
            '_ru_RU': [
                { name: 'Российские туристы', value: 'ru' },

                // { name: 'Иностранные туристы', value: 'en' },
                { name: 'Китайские туристы', value: 'cn' },
            ],
            '_en_US': [
                { name: 'Russian tourists', value: 'ru' },

                // { name: 'Foreign tourists', value: 'en' },
                { name: 'Chinese tourists', value: 'cn' },
            ],
            '_fa_IR': [
                { name: 'توریست روسی', value: 'ru' },

                // { name: 'گردشگران خارجی', value: 'en' },
                { name: 'گردشگران چینی', value: 'cn' },
            ],
            '_vi': [
                { name: 'Du khách Nga', value: 'ru' },

                // { name: 'Du khách nước ngoài', value: 'en' },
                { name: 'Du khách Trung Quốc', value: 'cn' },
            ],
        })),
      museumsList: computed(() => store.getters['museums/getMuseumsList']),
      schedule: computed(() => store.getters['schedule/getSchedule'].filter(scheduleItem => scheduleItem.name.includes('График')).map(scheduleItem => ({
        id: scheduleItem.id,
        name: scheduleItem.name.match(/\d{2}:\d{2}-\d{2}:\d{2}/)[0],
        valid_from: scheduleItem.valid_from,
      }))),
      isSending: computed(() => store.getters['createOrder/isSending']),
      isShowMessage: computed(() => store.getters['createOrder/isShowMessage']),
      successMsg: computed(() => store.getters['createOrder/successMsg']),
      errorMsg: computed(() => store.getters['createOrder/errorMsg']),
      isSuccess: computed(() => store.getters['createOrder/isSuccess']),
      selectedMuseum: computed(() => store.getters['museums/getSelectedMuseum']),
    }
  },
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.type-select {
  width: 100px;
  height: 100px;
  margin: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  line-height: 16px;
  font-size: 14px;
  filter: grayscale(100%);
}
.image-select {
    width: 35px;
    height: 35px;
}
.type-select-active-ru {
  filter: grayscale(0%);
  border: 1px solid blue;
}
.type-select-active-cn {
  filter: grayscale(0%);
  border: 1px solid red;
}
.type-select-active-other {
  filter: grayscale(0%);
  border: 1px solid green;
}
.text-other {
    color: green;
}
.text-ru {
    color: blue;
}
.text-cn {
    color: red;
}
</style>
