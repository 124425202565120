<template>
  <div class="text-center mt-5">
    <v-progress-circular
      :width="4"
      :size="30"
      color="secondary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>

<style lang="scss">
</style>
