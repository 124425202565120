import axios from "@axios"

export default {
  namespaced: true,
  state: {
    /**
     * is_sending - отправляется ли в данный момент заявка
     * is_show_message - показывать ли сообщение о результате отправки
     * request_is_success - успешно ли создана заявка
     * request_success_msg - текст сообщения о успешном создании заявки
     * request_error_msg - текст сообщения об ошибке
     */
    is_sending: false,
    is_show_message: false,
    request_is_success: null,
    request_success_msg: null,
    request_error_msg: null,
  },
  getters: {
    isSending: state => state.is_sending,
    isSuccess: state => state.request_is_success,
    isShowMessage: state => state.is_show_message,
    successMsg: state => state.request_success_msg,
    errorMsg: state => state.request_error_msg,
  },
  mutations: {
    set_on_request_success(state, id) {
    },
    close_message(state) {
      state.is_show_message = false
    },
    set_on_request_error(state, error) {
    },
  },
  actions: {
    subscribeEmailByLaunch({ commit }, data) {
        // console.log(data)
    },
  },
}
