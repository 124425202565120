import axios from '@axios'

export default {
    namespaced: true,
    state: {
        museums_list: [],
        request_is_success: null,
        request_error_msg: null,
        selected_museum: null,
    },
    getters: {
        getMuseumsList: state => state.museums_list,
        getRequestStatus: state => state.request_is_success,
        getRequestErrorMsg: state => state.request_error_msg,
        getSelectedMuseum: state => state.selected_museum,
    },
    mutations: {
        set_on_request_success(state, payload) {
            state.museums_list = payload
            state.request_is_success = true
            state.request_error_msg = null
        },
        set_on_request_error(state, error) {
            state.museums_list = []
            state.request_is_success = false
            state.request_error_msg = error
        },
        set_selected_museum(state, museumObject) {
            state.selected_museum = museumObject
        },
    },
    actions: {
        fetchMuseums({ commit }, type) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: `/api/v1/museums/?type=${type}`,
                })
                    .then(response => {
                        if (response.status === 200) {
                            commit('set_on_request_success', response.data)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        fetchMuseumsForRgAg({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: `/api/v1/museums-for-rgag/`,
                })
                    .then(response => {
                        if (response.status === 200) {
                            commit('set_on_request_success', response.data)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        fetchMuseumsForTransport({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: `/api/v1/museums-for-transport/`,
                })
                    .then(response => {
                        if (response.status === 200) {
                            commit('set_on_request_success', response.data)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        fetchMuseumsForGuide({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: `/api/v1/museums-for-guide/`,
                })
                    .then(response => {
                        if (response.status === 200) {
                            commit('set_on_request_success', response.data)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        setMuseum({ commit }, museumsObject) {
            return new Promise(() => {
              commit('set_selected_museum', museumsObject)
            })
        },
        fetchMuseumsForScheduleRequest({ commit }, type) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: `/api/v1/museums-for-schedule/?type=${type}`,
                })
                    .then(response => {
                        if (response.status === 200) {
                            commit('set_on_request_success', response.data)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
