export default {

    // меню
    'АТГ. Заявки': 'АТГ. Заявки',
    Заказы: 'Заказы',
    'Новый заказ': 'Новый заказ',
    'Билеты в музеи': 'Билеты в музеи',
    'Подать график': 'Подать график',
    'Радио/Аудиогиды': 'Радио/Аудиогиды',
    Архив: 'Архив',
    Отчеты: 'Отчеты',
    Сверка: 'Сверка',
    Оплата: 'Оплата',
    'Оплата Wechat': 'Оплата Wechat',
    'Оплата заявок': 'Оплата заявок',
    'История платежей': 'История платежей',
    'Прайс-лист': 'Прайс-лист',
    'Продление лицензий': 'Продление лицензий',
    'Мои лицензии': 'Мои лицензии',
    Продление: 'Продление',
    Информация: 'Информация',
    Новости: 'Новости',
    Транспорт: 'Транспорт',
    'О компании': 'О компании',
    Договор: 'Договор',
    'Договор оферты': 'Договор оферты',
    'Политика возврата': 'Политика возврата',
    'Поиск по ref, номеру заявки, дате или объекту': 'Поиск по ref, номеру заявки, дате или объекту',
    Настройки: 'Настройки',
    Помощь: 'Помощь',
    'Обратная связь': 'Обратная связь',
    Выйти: 'Выйти',
    'Старая версия сайта': 'Старая версия сайта',
    'ООО «АВАНГАРД ТРАВЕЛ ГРУПП»': 'ООО «АВАНГАРД ТРАВЕЛ ГРУПП»',

    // заказы
    'Нет предстоящих заказов': 'Нет предстоящих заказов',
    'Выберите форму заказа': 'Выберите форму заказа',
    'Заказать билеты в музей': 'Заказать билеты в музей',
    'Заказать Радио/Аудиогиды': 'Заказать Радио/Аудиогиды',
    Заявка: 'Заявка',
    'В обработке': 'В обработке',
    'Подтврждена': 'Подтврждена',
    'Лист ожидания': 'Лист ожидания',
    'Аннулирована': 'Аннулирована',
    'Отклонена': 'Отклонена',
    'Подтвержденное время:': 'Подтвержденное время:',
    'Вход': 'Вход',
    'ПОДРОБНЕЕ': 'ПОДРОБНЕЕ',
    'ИЗМЕНИТЬ': 'ИЗМЕНИТЬ',
    'ГДЕ СОТРУДНИК': 'ГДЕ СОТРУДНИК',
    'АННУЛИРОВАТЬ': 'АННУЛИРОВАТЬ',
    'В АРХИВ': 'В АРХИВ',
    'Сотрудник на объекте': 'Сотрудник на объекте',
    'Сотрудник не на объекте': 'Сотрудник не на объекте',
    'Но вы можете связаться с нами:': 'Но вы можете связаться с нами:',
    'Аннулировать': 'Аннулировать',
    'Хотите аннулировать заявку?': 'Хотите аннулировать заявку?',
    'Аннулированная заявка попадет в архив и восстановить ее будет невозможно': 'Аннулированная заявка попадет в архив и восстановить ее будет невозможно',
    'Отмена': 'Отмена',
    'Дата и время:': 'Дата и время:',
    'Имя гида:': 'Имя гида:',
    'Не заполнено': 'Не заполнено',
    'Телефон гида:': 'Телефон гида:',
    'Пожелания:': 'Пожелания:',
    'Подтвержденное время и вход:': 'Подтвержденное время и вход:',
    'Билеты:': 'Билеты:',
    'Дополнительные услуги:': 'Дополнительные услуги:',
    'Причина отклонения:': 'Причина отклонения:',
    'Информация о группе:': 'Информация о группе:',
    'Доп.услуги:': 'Доп.услуги:',
    'Доп.информация:': 'Доп.информация:',
    'График': 'График',
    'Гид': 'Гид',
    'Заказать': 'Заказать',

    // новый заказ
    'Информация о музее': 'Информация о музее',
    'Выберите категорию группы (РФ или ИГ), музей, дату и время': 'Выберите категорию группы (РФ или ИГ), музей, дату и время',
    'Выберите категорию группы': 'Выберите категорию группы',
    'Выберите музей': 'Выберите музей',
    'Выберите объект': 'Выберите объект',
    Далее: 'Далее',
    'Выберите дату': 'Выберите дату',
    'Выберите время': 'Выберите время',
    'Информация о группе': 'Информация о группе',
    'Выберите кол-во билетов': 'Выберите кол-во билетов',
    'Выберите страну': 'Выберите страну',
    Назад: 'Назад',
    'Доп. услуги': 'Доп. услуги',
    'Выберите дополнительные услуги': 'Выберите дополнительные услуги',
    'Кол-во гидов сопровождающих (если нет лицензии)': 'Кол-во гидов сопровождающих (если нет лицензии)',
    'Необходим если у гида нет лицензии в выбранный музей': 'Необходим если у гида нет лицензии в выбранный музей',
    'Доп. информация': 'Доп. информация',
    'Информация о гиде и пожелания': 'Информация о гиде и пожелания',
    'Ref номер (необязательно)': 'Ref номер (необязательно)',
    'Уникальный номер группы / вспомогательный номер для ваших целей': 'Уникальный номер группы / вспомогательный номер для ваших целей',
    'Имя гида': 'Имя гида',
    'Телефон гида': 'Телефон гида',
    Пожелания: 'Пожелания',
    'В поле "Пожелания" укажите:': 'В поле "Пожелания" укажите:',
    'адрес и время получения': 'адрес и время получения',
    'адрес и приблизительное время возврата оборудования': 'адрес и приблизительное время возврата оборудования',
    Отправить: 'Отправить',
    'Пожалуйста, ожидайте': 'Пожалуйста, ожидайте',
    'Успешно!': 'Успешно!',
    'Заказ создан': 'Заказ создан',
    'Ошибка:': 'Ошибка:',
    Ок: 'Ок',
    'Подача графика на апрель 2024 закрывается 20 марта 2024.': 'Подача графика на апрель 2024 закрывается 20 марта 2024.',
    'Кол-во взрослых': 'Кол-во взрослых',
    'Кол-во детей': 'Кол-во детей',
    'итого:': 'итого:',
    'чел. (надо': 'чел. (надо',
    'экскурс.обсл.)': 'экскурс.обсл.)',
    'гид. или сопровожд.)': 'гид. или сопровожд.)',
    'Радиогид': 'Радиогид',
    'Экскурсия ведется лицензионным гидом': 'Экскурсия ведется лицензионным гидом',
    'Аудиогид': 'Аудиогид',
    'Экскурсия ведется при помощи технических средств на языке группы': 'Экскурсия ведется при помощи технических средств на языке группы',
    'Кол-во гидов с лицензией': 'Кол-во гидов с лицензией',
    'Кол-во сопровождающих': 'Кол-во сопровождающих',
    'Удалить': 'Удалить',
    'Отправить заказ': 'Отправить заказ',
    'Добавить группу на эту дату': 'Добавить группу на эту дату',
    'Группа': 'Группа',

    // архив
    'Номер заявки или ref': 'Номер заявки или ref',
    Объект: 'Объект',
    'Номер заявки': 'Номер заявки',
    Все: 'Все',
    'Дата посещения с': 'Дата посещения с',
    'Дата посещения по': 'Дата посещения по',
    Найти: 'Найти',
    'Заказов согласно фильтру не найдено': 'Заказов согласно фильтру не найдено',
    Ошибка: 'Ошибка',
    'Любой номер': 'Любой номер',

    // сверка
    Сформировать: 'Сформировать',
    'Файл сверки': 'Файл сверки',
    'Для скачивания сформированного файла сверки нажмите на конпку "Скачать".': 'Для скачивания сформированного файла сверки нажмите на конпку "Скачать".',
    'Скачать': 'Скачать',

    // оплата заявок
    'Оплатить заявки': 'Оплатить заявки',
    'Номера ваучеров или заявок для оплаты (через запятую)': 'Номера ваучеров или заявок для оплаты (через запятую)',
    'Введите номера ваучеров или заявок для оплаты': 'Введите номера ваучеров или заявок для оплаты',
    'ФИО или название организации': 'ФИО или название организации',
    'Введите ФИО или название организации': 'Введите ФИО или название организации',
    Email: 'Email',
    'Введите Email': 'Введите Email',
    'Сумма к оплате': 'Сумма к оплате',
    'Введите сумму к оплате': 'Введите сумму к оплате',
    'Согласен с договором оферты': 'Согласен с договором оферты',
    'Перейти к оплате': 'Перейти к оплате',
    'Подождите,': 'Подождите,',
    'принимаем': 'принимаем',
    'оплату...': 'оплату...',
    'Заказ успешно оплачен!': 'Заказ успешно оплачен!',
    'Ваш заказ': 'Ваш заказ',
    'успешно оплачен. Спасибо за покупку!': 'успешно оплачен. Спасибо за покупку!',
    'Что-то пошло не так!': 'Что-то пошло не так!',
    'При оплате заказа': 'При оплате заказа',
    'произошла ошибка!': 'произошла ошибка!',
    'Cчет успешно сформирован!': 'Cчет успешно сформирован!',
    'Вы можете открыть его нажав кнопку ниже': 'Вы можете открыть его нажав кнопку ниже',
    'Открыть счет': 'Открыть счет',
    'Физическим лицам': 'Физическим лицам',
    'Юридическим лицам': 'Юридическим лицам',
    'Для формирования счета на оплату введите номера ваучеров или заказов, укажите реквизиты компании и точную сумму:': 'Для формирования счета на оплату введите номера ваучеров или заказов, укажите реквизиты компании и точную сумму:',
    'Для оплаты введите номера ваучеров или заявок и укажите точную сумму для оплаты:': 'Для оплаты введите номера ваучеров или заявок и укажите точную сумму для оплаты:',
    'Название организации': 'Название организации',
    'Юридический адрес': 'Юридический адрес',
    'Сформировать счет': 'Сформировать счет',
    'Расчетный счет': 'Расчетный счет',
    'ИНН': 'ИНН',
    'КПП': 'КПП',
    'Банк': 'Банк',
    'Полное название банка (для формирования акта). Например: ': 'Полное название банка (для формирования акта). Например: ',
    'БИК Банка': 'БИК Банка',
    'Корреспондентский счет': 'Корреспондентский счет',

    // история платежей
    'Номер платежа': 'Номер платежа',
    Ваучеры: 'Ваучеры',
    'Способ оплаты': 'Способ оплаты',
    'Время и дата оплаты': 'Время и дата оплаты',
    Сумма: 'Сумма',
    Статус: 'Статус',
    'Платежи отсутствуют': 'Платежи отсутствуют',

    // прайс-лист
    'Прайс-лист для иностранных групп': 'Прайс-лист для иностранных групп',
    'Актуальный файл с ценами для иностранных групп.': 'Актуальный файл с ценами для иностранных групп.',
    Открыть: 'Открыть',
    'Прайс-лист для российских групп': 'Прайс-лист для российских групп',
    'Актуальный файл с ценами для российских групп.': 'Актуальный файл с ценами для российских групп.',

    // лицензии
    'Добавить лицензию': 'Добавить лицензию',
    'В настоящий момент нет сохраненных лицензий, вы можете добавить их.': 'В настоящий момент нет сохраненных лицензий, вы можете добавить их.',
    'Добавить': 'Добавить',
    'Музей': 'Музей',
    'Номер лицензии': 'Номер лицензии',
    'Стаж (лет)': 'Стаж (лет)',
    'Дата окончания': 'Дата окончания',
    'Основной язык': 'Основной язык',
    'Дополнительные языки': 'Дополнительные языки',

    // о компании
    'ИНН:7841027155': 'ИНН:7841027155',
    'КПП: 784101001': 'КПП: 784101001',
    'ОГРН: 1157847276003': 'ОГРН: 1157847276003',
    'ОКПО: 25885413': 'ОКПО: 25885413',
    'Расчетный счет: 40702810603260000188': 'Расчетный счет: 40702810603260000188',
    'Банк: Филиал "Центральный" Банка ВТБ (ПАО)': 'Банк: Филиал "Центральный" Банка ВТБ (ПАО)',
    'БИК: 044525411': 'БИК: 044525411',
    'Корр. счет: 30101810145250000411': 'Корр. счет: 30101810145250000411',
    'Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405': 'Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405',
    'Email: info@avangard-travel.ru': 'Email: info@avangard-travel.ru',
    'Телефон: (812) 640-02-28': 'Телефон: (812) 640-02-28',

    // договор
    'Вы можете посмотреть договор с ООО "Авангард Травел Групп".': 'Вы можете посмотреть договор с ООО "Авангард Травел Групп". ',

    // договор оферты
    'Вы можете посмотреть договор оферты с ООО "Авангард Травел Групп".': 'Вы можете посмотреть договор оферты с ООО "Авангард Травел Групп".',

    // политика возврата
    'Вы можете посмотреть политику возврата.': 'Вы можете посмотреть политику возврата.',

    // настройки
    'Имя:': 'Имя:',
    'Телефон:': 'Телефон:',
    'Страна:': 'Страна:',
    'Информация о гиде': 'Информация о гиде',
    'Заполните информацию и подавайте заявки еще быстрее': 'Заполните информацию и подавайте заявки еще быстрее',
    Имя: 'Имя',
    Телефон: 'Телефон',
    'Выберите страницу': 'Выберите страницу',
    'Использовать в заказе?': 'Использовать в заказе?',
    Сохранить: 'Сохранить',
    'Являетесь ли вы туристической фирмой / юридическим лицом': 'Являетесь ли вы туристической фирмой / юридическим лицом',
    'Юридическое лицо': 'Юридическое лицо',
    'Для выставления счетов': 'Для выставления счетов',

    // форма логина
    Логин: 'Логин',
    Пароль: 'Пароль',
    Войти: 'Войти',
    'Выберите язык': 'Выберите язык',
    'У вас нет аккаунта?': 'У вас нет аккаунта?',
    'Зарегистрироваться': 'Зарегистрироваться',
    'Добро пожаловать!': 'Добро пожаловать!',
    'Будем рады видеть вас среди наших клиентов! Пожалуйста, уделите немного времени на заполнение формы ниже. Вскоре мы отправим вам все необходимые инструкции на указанный адрес электронной почты.': 'Будем рады видеть вас среди наших клиентов! Пожалуйста, уделите немного времени на заполнение формы ниже. Вскоре мы отправим вам все необходимые инструкции на указанный адрес электронной почты.',
    'Фамилия': 'Фамилия',
    'Физ.лицо': 'Физ.лицо',
    'Юр.лицо': 'Юр.лицо',
    'Отчество': 'Отчество',
    'У вас есть аккаунт?': 'У вас есть аккаунт?',
    'Название фирмы': 'Название фирмы',
    'БИК': 'БИК',
    'ФИО контактного лица': 'ФИО контактного лица',
    'Заявка принята!': 'Заявка принята!',
    'Спасибо за вашу заявку! В ближайшее время на указанный вами адрес электронной почты мы отправим все необходимые инструкции.': 'Спасибо за вашу заявку! В ближайшее время на указанный вами адрес электронной почты мы отправим все необходимые инструкции.',
    'Закрыть': 'Закрыть',

    // уведомление
    'Теперь можно оплатить заявки онлайн, включая оплату от юридических лиц!': 'Теперь можно оплатить заявки онлайн, включая оплату от юридических лиц!',
    'Мы рады сообщить, что теперь вы можете оплатить заявки на нашем сайте, используя банковскую карту или QR-код системы быстрых платежей. Вдобавок к этому, мы расширили наши возможности и теперь доступна оплата и для юридических лиц. Юридические лица могут самостоятельно генерировать счета для оплаты.': 'Мы рады сообщить, что теперь вы можете оплатить заявки на нашем сайте, используя банковскую карту или QR-код системы быстрых платежей. Вдобавок к этому, мы расширили наши возможности и теперь доступна оплата и для юридических лиц. Юридические лица могут самостоятельно генерировать счета для оплаты.',
    'Никаких задержек и лишних хлопот - оплата проходит мгновенно. Перейдите в раздел "Оплата заявок" и оплачивайте свои заявки быстро и удобно! Наши новые возможности сделают процесс ещё более удобным и эффективным.': 'Никаких задержек и лишних хлопот - оплата проходит мгновенно. Перейдите в раздел "Оплата заявок" и оплачивайте свои заявки быстро и удобно! Наши новые возможности сделают процесс ещё более удобным и эффективным.',

    // помощь
    'У вас есть вопросы?': 'У вас есть вопросы?',
    'Если вы не можете найти ответ на вопрос, вы всегда можете связаться с нами. Мы ответим вам в ближайшее время!': 'Если вы не можете найти ответ на вопрос, вы всегда можете связаться с нами. Мы ответим вам в ближайшее время!',
    'Мы всегда рады помочь!': 'Мы всегда рады помочь!',
    'Лучший способ получить ответ быстрее!': 'Лучший способ получить ответ быстрее!',

    // в разработе
    'Готовимся к запуску. Новая функция в пути! 🚀': 'Готовимся к запуску. Новая функция в пути! 🚀',
    'Мы активно работаем над новой функцией для вас.': 'Мы активно работаем над новой функцией для вас.',
    'Будьте в курсе предстоящих обновлений!': 'Будьте в курсе предстоящих обновлений!',

    // Обратная связь
    'Мы всегда рады услышать вас!': 'Мы всегда рады услышать вас!',
    'Наши усилия направлены на то, чтобы сделать систему подачи заявок максимально удобной и эффективной для вас. Ваше мнение и предложения играют ключевую роль в этом процессе. Не стесняйтесь делиться своими мыслями, идеями, а также сообщать нам обо всех замеченных ошибках или проблемах в работе системы. Вместе мы сделаем систему подачи заявок лучше!': 'Наши усилия направлены на то, чтобы сделать систему подачи заявок максимально удобной и эффективной для вас. Ваше мнение и предложения играют ключевую роль в этом процессе. Не стесняйтесь делиться своими мыслями, идеями, а также сообщать нам обо всех замеченных ошибках или проблемах в работе системы. Вместе мы сделаем систему подачи заявок лучше!',
    'Как к вам обращаться?': 'Как к вам обращаться?',
    'Email для обратной связи': 'Email для обратной связи',
    'Опишите ваше предложение, жалобу или замеченную ошибку': 'Опишите ваше предложение, жалобу или замеченную ошибку',
}
