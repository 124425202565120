// eslint-disable-next-line object-curly-newline
import {
    mdiArchive,
    mdiAutorenew,
    mdiCheckboxMarkedCircleOutline,
    mdiCreditCard,
    mdiFileDocument,
    mdiInformationOutline,
    mdiMessageAlertOutline,
    mdiNewspaperVariantOutline,
    mdiPlus,
    mdiTextBoxMultipleOutline,
    mdiViewList,
    mdiViewListOutline,
    mdiWechat,
} from '@mdi/js'

const userData = JSON.parse(localStorage.getItem('userData'))
const newsBlock = {
    title: 'Новости',
    icon: mdiNewspaperVariantOutline,
    to: 'news',
}
if (userData) {
    if (userData.news_mark) {
        newsBlock.badge = '!'
        newsBlock.badgeColor = 'error'
    }
}

export default [
    // заказы
    {
        title: 'Заказы',
        icon: mdiViewListOutline,
        to: 'upcoming-requests',
    },
    {
        title: 'Новый заказ',
        icon: mdiPlus,
        children: [
            {
                title: 'Билеты в музеи',
                to: 'new-request',
            },

            // {
            //     title: 'Подать график',
            //     to: 'new-request-schedule',
            // },
            {
                title: 'Заказать транспорт',
                to: 'new-request-transport',
            },
            {
                title: 'Заказать гида',
                to: 'new-request-guide',
            },
            {
                title: 'Радио/Аудиогиды',
                to: 'new-request-rg-ag',
            },
        ],
      },
    {
        title: 'Архив',
        icon: mdiArchive,
        to: 'archive-requests',
    },

    // отчеты
    {
        subheader: 'Отчеты',
    },
    {
        title: 'Сверка',
        icon: mdiCheckboxMarkedCircleOutline,
        to: 'revise',
    },

    // оплата
    {
        subheader: 'Оплата',
    },
    {
        title: 'Оплата заявок',
        icon: mdiCreditCard,
        to: 'payment',
        badgeColor: 'warning',
    },
    {
        title: 'История платежей',
        icon: mdiViewList,
        to: 'payments-history',
    },
    {
        title: 'Прайс-лист',
        icon: mdiFileDocument,
        to: 'pricelist',
    },
    {
        title: 'Оплата Wechat',
        icon: mdiWechat,
        to: 'payment-wechat',
        badge: 'СКОРО',
        badgeColor: 'error',
    },

    // продление лицензий
    {
        subheader: 'Продление лицензий',
    },
    {
        title: 'Мои лицензии',
        icon: mdiTextBoxMultipleOutline,
        to: 'licenses',
    },
    {
        title: 'Продление',
        icon: mdiAutorenew,
        to: 'prolongation',
    },

    // информация
    {
        subheader: 'Информация',
    },
    newsBlock,
    {
        title: 'Обратная связь',
        icon: mdiMessageAlertOutline,
        to: 'feedback',
        badge: 'Новое',
        badgeColor: 'info',
    },
    {
        title: 'О компании',
        icon: mdiInformationOutline,
        to: 'about',
    },

    // {
    //     title: 'Сообщения',
    //     icon: mdiChat,
    //     to: 'chat',
    //     badge: '3',
    //     badgeColor: 'warning',
    // },
]
