import router from '@/router'
import axios from 'axios'
import Vue from 'vue'

const API_URL = process.env.VUE_APP_API_URL
const axiosIns = axios.create({
  baseURL: API_URL,
  timeout: 30000,
})

axiosIns.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.data.error === 'Неверные имя пользователя или пароль') {
      return error.response.data.error
    }
    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')

        router.push({ name: 'auth-login' })
      }
    }

    return error
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
