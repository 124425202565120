<template>
  <v-row>
    <v-col
      cols="12"
      lg="10"
      md="12"
      sm="12"
      xs="12"
    >
      <v-tabs
        v-model="currentTab"
        grow
        centered
      >
        <v-tab>Сделать заказ на транспорт</v-tab>
        <v-tab>Подать график</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <v-card
            class="px-4 py-4"
            flat
          >
            <v-col
              v-for="(order, index) in transportInfo"
              :key="index"
              cols="12"
            >
              <transport-order-form
                :order="order"
                :transport-types="museumsList"
                :order-index="index"
                @deleteGroup="deleteGroup(index)"
                @fetchSchedule="fetchSchedule(index)"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-space-between"
            >
              <v-btn
                color="primary"
                @click="createOrder()"
              >
                {{ $t("Создать заказ") }}
              </v-btn>
              <div>
                <v-btn
                  outlined
                  class="ml-2"
                  @click="addGroup()"
                >
                  {{ $t("Добавить заказ на транспорт") }}
                </v-btn>
              </div>
            </v-col>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card
            class="px-4 py-4"
            flat
          >
            <v-card-text class="text-xl">
              {{ $t("Подача графика на апрель 2024 закрывается 20 марта 2024.") }}
            </v-card-text>
            <v-col
              v-for="(order, index) in transportInfo"
              :key="index"
              cols="12"
            >
              <transport-order-form
                :order="order"
                :transport-types="museumsList"
                :order-index="index"
                @deleteGroup="deleteGroup(index)"
                @fetchSchedule="fetchSchedule(index)"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-space-between"
            >
              <v-btn
                color="primary"
                @click="createOrder()"
              >
                {{ $t("Создать заказ") }}
              </v-btn>
              <div>
                <v-btn
                  outlined
                  class="ml-2"
                  @click="addGroup()"
                >
                  {{ $t("Добавить заказ на транспорт") }}
                </v-btn>
              </div>
            </v-col>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <!-- Отправка заявки -->
    <v-dialog
      v-model="isSending"
      overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3 white--text">
          {{ $t("Пожалуйста, ожидайте") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Результат отправки -->
    <v-dialog
      v-model="isShowMessage"
      width="500"
    >
      <v-card>
        <v-card-title v-if="isSuccess">
          {{ $t("Успешно!") }}
          {{ $t("Заказ создан") }}
        </v-card-title>
        <v-card-title v-else>
          {{ $t("Ошибка") }}
        </v-card-title>

        <v-card-text v-if="isSuccess">
          {{ successMsg }}
        </v-card-text>
        <v-card-text v-else>
          {{ errorMsg }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="isSuccess"
            color="primary"
            @click="closeMessageBoxAndCleanData"
          >
            {{ $t("Ок") }}
          </v-btn>
          <v-btn
            v-else
            color="error"
            @click="closeMessageBox"
          >
            {{ $t("Ок") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TransportOrderForm from "@/components/TransportOrderForm.vue";
import store from "@/store";
import { useRouter } from "@core/utils";
import { computed, ref } from "@vue/composition-api";

export default {
  components: {
    TransportOrderForm,
  },
  setup() {
    const router = useRouter()
    const currentTab = ref(router.route.value.query.tab ? Number(router.route.value.query.tab) : 0)

    function createEmptyOrder() {
      return {
        date: "",

        // notes values
        transportType: '',
        startTime: "",
        startAddress: "",
        endTime: "",
        endPlace: "",
        routeDescription: "",
        notes: "",

        // neseccarty fields
        scheduleId: '',
        guidePhone: '',
        guideName: '',
        persons_per_guide: '0',
        tickets: {
          adult: 1,
          child: 0,
        },
        services: {
          rg: false,
          ag: false,
          sopr: '0',
          license: '0',
        },
      }
    }
    const closeMessageBox = () => {
      store.commit('createOrder/close_message')
    }
    const transportInfo = ref([createEmptyOrder()])
    const closeMessageBoxAndCleanData = () => {
      store.commit('createOrder/close_message')
      transportInfo.value = [createEmptyOrder()]
    }
    const fetchMuseums = () => {
      store.dispatch("museums/fetchMuseumsForTransport")
    }
    fetchMuseums()

    const addGroup = () => transportInfo.value.push(createEmptyOrder())
    const deleteGroup = index => transportInfo.value.splice(index, 1)
    const fetchSchedule = index => {
      const formData = {
        date: transportInfo.value[index].date,
        museumObjectId: transportInfo.value[index].transportType,
      }
      store.dispatch('schedule/fetchSchedule', formData).then(() => {
        transportInfo.value[index].scheduleId = store.getters['schedule/getSchedule'][0].id
      })
    }

    const createOrder = () => {
      // fetchSchedule(0);
      store.dispatch('createOrder/createOrderForTransportRequest', transportInfo.value)
    }

    return {
      currentTab,
      transportInfo,
      addGroup,
      deleteGroup,
      createOrder,
      fetchSchedule,
      closeMessageBox,
      closeMessageBoxAndCleanData,
      museumsList: computed(() => [
        ...store.getters["museums/getMuseumsList"],
      ]),
      isSending: computed(() => store.getters['createOrder/isSending']),
      isShowMessage: computed(() => store.getters['createOrder/isShowMessage']),
      successMsg: computed(() => store.getters['createOrder/successMsg']),
      errorMsg: computed(() => store.getters['createOrder/errorMsg']),
      isSuccess: computed(() => store.getters['createOrder/isSuccess']),
      schedule: computed(() => store.getters['schedule/getSchedule']),
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
