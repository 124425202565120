<template>
  <div id="misc">
    <div class="page-title text-center pb-5">
      <h2 class="text-2xl font-weight-semibold text--primary">
        {{ $t("Готовимся к запуску. Новая функция в пути! 🚀") }}
      </h2>
      <p class="text-sm py-12">
        {{ $t("Мы активно работаем над новой функцией для вас.") }}
        <br>
        {{ $t("Будьте в курсе предстоящих обновлений!") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>

<style lang="scss">
  @import '@core/preset/preset/misc.scss';
</style>
