<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <v-card class="mb-4">
        <v-card-title>{{$t('Прайс-лист для иностранных групп')}}</v-card-title>
        <v-card-text>
          {{$t('Актуальный файл с ценами для иностранных групп.')}}
        </v-card-text>
        <v-card-actions class="justify-start">
          <v-btn
            color="primary"
            class="ps-4"
            :disabled="priceFileIG === null"
            :href="priceFileIG"
            target="_blank"
          >
            {{$t('Открыть')}}
            <v-icon
              dark
              right
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card>
        <v-card-title>{{$t('Прайс-лист для российских групп')}}</v-card-title>
        <v-card-text>
          {{$t('Актуальный файл с ценами для российских групп.')}}
        </v-card-text>
        <v-card-actions class="justify-start">
          <v-btn
            color="primary"
            class="ps-4"
            :disabled="priceFileRG === null"
            :href="priceFileRG"
            target="_blank"
          >
            {{$t('Открыть')}}
            <v-icon
              dark
              right
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { mdiOpenInNew } from '@mdi/js'
import { computed } from '@vue/composition-api'

export default {
  setup() {
    const getPricelists = () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      store.dispatch('pricelist/getPricelists', userData.login)
    }
    getPricelists()

    return {
      icons: {
        mdiOpenInNew,
      },
      priceFileRG: computed(() => store.getters['pricelist/getPriceFileRG']),
      priceFileIG: computed(() => store.getters['pricelist/getPriceFileIG']),
    }
  },
}
</script>
