var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"user-edit-info pa-3"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('История платежей'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"no-data-text":_vm.$t('Платежи отсутствуют'),"items":_vm.payments.filter(function (payment) { return payment.status !== 'FORM_OPENED'; }),"items-per-page":15,"item-key":"payment_id"},scopedSlots:_vm._u([{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatToLocalDatetime(item.payment_date))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.amount) + " ₽"))+" ")]}},{key:"item.vouchers",fn:function(ref){
var item = ref.item;
return [(item.vouchers.length !== 0)?_c('span',[_vm._v(" "+_vm._s(("" + (item.vouchers)))+" ")]):_c('span',[_vm._v("–")])]}},{key:"item.orders",fn:function(ref){
var item = ref.item;
return [(item.orders.length !== 0)?_c('span',[_vm._v(" "+_vm._s(("" + (item.orders)))+" ")]):_c('span',[_vm._v("–")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusColor[_vm.status[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}},{key:"item.pdf_invoice",fn:function(ref){
var item = ref.item;
return [(item.payment_type === 'invoice')?_c('a',{attrs:{"href":'https://backrequests.atg-service.info/media/' + item.pdf_invoice,"target":"_blank"}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1):_vm._e()]}},{key:"item.certificate_of_completion",fn:function(ref){
var item = ref.item;
return [(item.payment_type === 'invoice' && item.status === 'COMPLETE')?_c('a',{attrs:{"href":'https://backrequests.atg-service.info/media/' + item.certificate_of_completion,"target":"_blank"}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1):_vm._e(),(item.payment_type === 'invoice' && item.status != 'COMPLETE')?_c('span',[_vm._v(" — ")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }