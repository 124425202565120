/* eslint-disable operator-linebreak */
export const getOrderStatusTitle = status => {
  // получить текст статуса заявки
  switch (status) {
    case 1:
      return 'В обработке'
    case 2:
      return 'Подтверждена'
    case 3:
      return 'Лист ожидания'
    case 4:
      return 'Аннулирована'
    case 5:
      return 'Отклонена'
    default:
      return ''
  }
}

export const getOrderStatusColor = (status, isLocked) => {
  // получить цвет  по статусу заявки
  switch (status) {
    case 1:
      return 'order-status-new'
    case 2:
      if (isLocked === 1) {
        return 'order-status-accepted-blue'
      }

      return 'order_status-accepted-green'
    case 3:
      return 'order-status-waiting'
    case 4:
      return 'order-status-decline'
    case 5:
      return 'order-status-decline'
    default:
      return ''
  }
}

export const getQtyTotal = order => {
  // возвращает общее кол-во билетов в заказе
  // eslint-disable-next-line operator-linebreak
  const { notes } = order.data
  const correctString = notes.substr(0, notes.indexOf('###'))
  const notesNumbersValues = correctString.match(/[0-9]+/g)
  let notesValue = 0
  if (notesNumbersValues) {
    notesValue = notesNumbersValues.reduce((acc, val) => Number(acc) + Number(val), 0)
  }
  const total =
    Number(order.data.qty_common) +
    Number(order.data.qty_privileged) +
    Number(order.data.qty_child) +
    Number(notesValue)

  return total
}

export const getGuideStr = request => {
  // возвращает значение сопровождающего гида
  let guideStr = 'Без сопр. гида'
  if (request.data.qty_guide > 0) {
    guideStr = `${request.data.qty_guide} сопр.`
  }

  return guideStr
}

export const getUserNotes = notes => {
  if (notes && notes.includes('Клиент написал:')) {
    return notes.split('Клиент написал:')[1]
  }

return notes
}

export const getInfoFromNotesTickets = (notes, catergory) => {
  // возвращает доп информацию внесенную в заметки системой о билетах
  const removeBeforeValueString = notes.split(`${catergory}: `)[1]
  const valueString = removeBeforeValueString.split(' шт.')[0]

  return valueString
}

export const getInfoFromNotesServices = (notes, catergory) => {
  // возвращает доп информацию внесенную в заметки системой об услугах
  const removeBeforeValueString = notes.split(`${catergory}: `)[1].split('.')[0]
  if (removeBeforeValueString.includes('Да')) {
    return true
  }

  return false
}
