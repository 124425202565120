import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import ads from './ads'
import app from './app'
import help from './help'
import licenses from './licenses'
import news from './news'
import actionsOrder from './orders/all-actions'
import archive from './orders/archive'
import countries from './orders/new/countries'
import createOrder from './orders/new/create-order'
import museumObjects from './orders/new/museum-objects'
import museums from './orders/new/museums'
import schedule from './orders/new/schedule'
import revise from './orders/revise'
import upcoming from './orders/upcoming'
import updateOrder from './orders/update'
import payments from './payments'
import pricelist from './pricelist'
import notify from './notify'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    help,
    news,
    ads,
    museums,
    museumObjects,
    schedule,
    upcoming,
    countries,
    createOrder,
    updateOrder,
    actionsOrder,
    archive,
    revise,
    pricelist,
    licenses,
    payments,
    notify,
  },
})
