import axios from '@axios'

export default {
  namespaced: true,
  state: {
    price_file_rg: null,
    price_file_ig: null,
    request_is_success: null,
    request_error_msg: null,
  },
  getters: {
    getPriceFileRG: state => state.price_file_rg,
    getPriceFileIG: state => state.price_file_ig,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
  },
  mutations: {
    set_on_request_success(state, payload) {
      state.price_file_rg = payload[0].file_rg
      state.price_file_ig = payload[0].file_ig
      state.request_is_success = true
      state.request_error_msg = null
    },
    set_on_request_error(state, error) {
      state.price_file = null
      state.request_is_success = false
      state.request_error_msg = error
    },
  },
  actions: {
    getPricelists({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/v1/pricelist/',
        })
          .then(response => {
            if (response.status === 200) {
              commit('set_on_request_success', response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
