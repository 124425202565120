export default {

    // меню
    'АТГ. Заявки': 'درخواست ',
    Заказы: 'سفارش',
    'Новый заказ': 'سفار ش جدید',
    'Билеты в музеи': 'بلیط موزه',
    'Подать график': 'ارسال نمودار',
    'Радио/Аудиогиды': 'هد ست یا رادیو گاید',
    Архив: 'آرشیو',
    Отчеты: 'گزارش',
    Сверка: 'تطبیق کردن',
    Оплата: 'واریزی',
    'Оплата Wechat': 'وار یزی از طریق ویچت',
    'Оплата заявок': 'واریزی درخواستها',
    'История платежей': 'کل واریزی ها',
    'Прайс-лист': 'لیست قیمتها ',
    'Продление лицензий': 'تمدید مجوز ها',
    'Мои лицензии': 'مجوز من ',
    Продление: 'تمدید',
    Информация: 'اطلاعات',
    Новости: 'اخبار ',
    Транспорт: 'وسیله نقلیه',
    'О компании': 'درمورد کمپانی',
    Договор: 'قرارداد',
    'Договор оферты': 'پیش قرارداد',
    'Политика возврата': 'شرایط برگرداندن',
    'Поиск по ref, номеру заявки, дате или объекту': 'جستحو با رفرنس شماره درخواست .زمان ویا مکان ',
    Настройки: 'تنظیمات',
    Помощь: 'کمک ',
    'Обратная связь': 'پیگیری تماس',
    Выйти: 'خروج',
    'Старая версия сайта': 'نسخه قدیمی سایت ',
    'ООО «АВАНГАРД ТРАВЕЛ ГРУПП»': 'شرکت آوانگارد تراول گروپ',

    // заказы
    'Нет предстоящих заказов': 'سفارش جدیدی وجود ندارد',
    'Выберите форму заказа': 'نحوه سفارش را انتخاب کنید',
    'Заказать билеты в музей': 'سفارش بلیلط برای موزه',
    'Заказать Радио/Аудиогиды': 'سفارش هد ست یا رادیو گاید',
    Заявка: 'درخواست ',
    'В обработке': 'در حال پردازش',
    'Подтврждена': 'مورد تایید',
    'Лист ожидания': 'لیست انتظار',
    'Аннулирована': 'لغو شده',
    'Отклонена': 'قطع شده است ',
    'Подтвержденное время:': 'زمان تایید شده ',
    'Вход': 'ورود',
    'ПОДРОБНЕЕ': 'شرح کامل',
    'ИЗМЕНИТЬ': 'عوض کردن',
    'ГДЕ СОТРУДНИК': 'همکار کجا هست ',
    'АННУЛИРОВАТЬ': 'لغو کردن',
    'В АРХИВ': 'داخل آرشیو',
    'Сотрудник на объекте': 'همکار ما در محل میباشد',
    'Сотрудник не на объекте': 'همکار ما در محل نمیباشد',
    'Но вы можете связаться с нами:': 'شما میتوانید با ما در تماس باشید',
    'Аннулировать': 'لغو کردن',
    'Хотите аннулировать заявку?': 'میخواهید درخواست را لغو بکنید؟',
    'Аннулированная заявка попадет в архив и восстановить ее будет невозможно': 'اگردرخواست را لغو کنیددرخواست شما وارد آرشیو میشود وبازیابی امکان ندارد',
    'Отмена': 'لغو کردن',
    'Дата и время:': 'تاریخ و زمان ',
    'Имя гида:': 'اسم راهنما',
    'Не заполнено': 'کامل پر نشده است ',
    'Телефон гида:': 'شماره تلفن راهنما',
    'Пожелания:': 'با میل شما',
    'Подтвержденное время и вход:': 'تایید زمان ورودی',
    'Билеты:': 'بلیط ها',
    'Дополнительные услуги:': 'خدمات اضافی',
    'Причина отклонения:': 'علت قطع شدن',
    'Информация о группе:': 'اطلاعات گروه ',
    'Доп.услуги:': 'خدمات اضافی',
    'Доп.информация:': 'اطلاعات اضافی',
    'График': 'برنامه و نمودار',
    'Гид': 'راهنما',
    'Заказать': 'سفارش دادن',

    // новый заказ
    'Информация о музее': 'اطلاعات در مورد موزه',
    'Выберите категорию группы (РФ или ИГ), музей, дату и время': 'انتخاب نوع گروه(روسی یا خارجی)موزه تاریخ و زمان',
    'Выберите категорию группы': 'انتخاب نوع گروه ',
    'Выберите музей': 'انتخاب موزه',
    'Выберите объект': 'انتخاب مکان',
    Далее: 'بعدی',
    'Выберите дату': 'تاریخ را انتخاب کنید',
    'Выберите время': 'زمان را انتخاب کنید ',
    'Информация о группе': 'اطلاعات گروه ها',
    'Выберите кол-во билетов': 'تعداد بلیط را انتخاب کنید',
    'Выберите страну': 'کشور مورد نظر را انتخاب کنید',
    Назад: 'برگشت ',
    'Доп. услуги': 'خدمات اضافی',
    'Выберите дополнительные услуги': 'خدمات اضافی را انتخاب کنید',
    'Кол-во гидов сопровождающих (если нет лицензии)': 'تعداد راهنماهایی که همراه گروه هستند(اگر مجوز ندارند)',
    'Необходим если у гида нет лицензии в выбранный музей': 'لازم هست اگر راهنما مجوز موزه مورد نظر را ندارد',
    'Доп. информация': 'اطلاعات اصافی',
    'Информация о гиде и пожелания': 'اطلاعات در مورد راهنما و نیازها',
    'Ref номер (необязательно)': 'شماره رفرنس (لارم نیست)',
    'Уникальный номер группы / вспомогательный номер для ваших целей': 'شماره منحصر بفرد گروه این شماره به خود شما کمک میکند',
    'Имя гида': 'اسم راهنما',
    'Телефон гида': 'شماره تلفن راهنما',
    Пожелания: 'انتظارات',
    'В поле "Пожелания" укажите:': 'در سطر انتظارات بنویسید',
    'адрес и время получения': 'آدرس و زمان دریافت ',
    'адрес и приблизительное время возврата оборудования': 'آدرس و زمان تقریبی برگرداندان تجهیزات ',
    Отправить: 'بفرستید',
    'Пожалуйста, ожидайте': 'خواهش میکنم منتظر باشید',
    'Успешно!': 'با موفقیت انجام شد',
    'Заказ создан': 'سفارش شما شکل گرفت',
    'Ошибка:': 'اشتباه ',
    Ок: 'اکی ',
    'Подача графика на апрель 2024 закрывается 20 марта 2024.': 'ارسال نمودار برای ماه اپریل  در 20 ماه مارس 2024 بسته میشود',
    'Кол-во взрослых': 'تعداد بزرگسالان',
    'Кол-во детей': 'تعدا د کودکان',
    'итого:': 'مجموع',
    'чел. (надо': 'نفر',
    'экскурс.обсл.)': 'خدمات گشتها',
    'гид. или сопровожд.)': 'راهنما و یا فرد همراه',
    'Радиогид': 'صدا رسان یا رادیو گاید',
    'Экскурсия ведется лицензионным гидом': 'راهنمای مجوز دار گشت را اجرا میکند',
    'Аудиогид': 'صدارسان ',
    'Экскурсия ведется при помощи технических средств на языке группы': 'برنامه گشت با کمک تجهیزات مخصوص و به زبان گروه مورد نظر اجرا میشود',
    'Кол-во гидов с лицензией': 'تعداد راهنماهای مجوز دار',
    'Кол-во сопровождающих': 'تعداد همراههای گروه',
    'Удалить': 'حذف کردن',
    'Отправить заказ': 'سفارش را فرستادن',
    'Добавить группу на эту дату': 'به این تاریخ گروه را اضافه کنید',
    'Группа': 'گروهها',

    // архив
    'Номер заявки или ref': 'شماره درخواست یا رفرنس',
    Объект: 'اسم موزه ',
    'Номер заявки': 'شماره درخواست ',
    Все: 'همه',
    'Дата посещения с': 'زمان بازدید از تاریخ',
    'Дата посещения по': 'زمان بازدید تا تاریخ ',
    Найти: 'پیدا کردن',
    'Заказов согласно фильтру не найдено': 'سفارش شمابرطبق فیلتری که هست پیدا نشد',
    Ошибка: 'اشتباه ',
    'Любой номер': 'شماره دلخواه',

    // сверка
    Сформировать: 'شکل دادن ',
    'Файл сверки': 'فایل تطبیق',
    'Для скачивания сформированного файла сверки нажмите на конпку "Скачать".': 'برای دانلود کردن (فایل تطبیق )بر روی دگمه دانلود فشار دهید',
    'Скачать': 'دانلو دکردن',

    // оплата заявок
    'Оплатить заявки': 'پرداخت کردن درخواست ',
    'Номера ваучеров или заявок для оплаты (через запятую)': 'شماره والچر یا درخواست برای پرداخت (باورگول )',
    'Введите номера ваучеров или заявок для оплаты': 'برا ی واریزی شماره والجر یا درخواست را وارد کنید',
    'ФИО или название организации': 'اسم و فامیلی ویا اسم کمپانی',
    'Введите ФИО или название организации': 'اسم و فامیلی و یا اسم کمپانی را بنویسید',
    Email: ' امیل',
    'Введите Email': 'امیل خودتان را بنویسید',
    'Сумма к оплате': 'مبلغ و یا پرداختی',
    'Введите сумму к оплате': 'مبلغ واریزی را بنویسید',
    'Согласен с договором оферты': 'با پیش قرار داد موافق هستید',
    'Перейти к оплате': 'ورود برای واریزی',
    'Подождите,': 'صبر کنید,',
    'принимаем': 'قبول میکنیم',
    'оплату...': 'و اریزی',
    'Заказ успешно оплачен!': 'سفارش شما با موفقیت پرداخت شد',
    'Ваш заказ': 'شماره سفارش ',
    'успешно оплачен. Спасибо за покупку!': 'با موفقیت پرداخت شد با تشکر ازخرید شما',
    'Что-то пошло не так!': 'درست انجام نشد',
    'При оплате заказа': 'درزمان پرداخت سفارش',
    'произошла ошибка!': 'اشتباه شد',
    'Cчет успешно сформирован!': 'صورت حساب با موفقیت شکل گرفته است ',
    'Вы можете открыть его нажав кнопку ниже': 'با فشار دگمه پایین شما میتوانید آن را باز کنید',
    'Открыть счет': 'باز کردن حساب بانکی',
    'Физическим лицам': 'حساب شخصی ',
    'Юридическим лицам': 'حساب برای شرکت ',
    'Для формирования счета на оплату введите номера ваучеров или заказов, укажите реквизиты компании и точную сумму:': 'برای اماده کردن واریزی شماره والجر ویا سفارش ومشخصات حساب و مبلغ دقیق را وارد کنید',
    'Для оплаты введите номера ваучеров или заявок и укажите точную сумму для оплаты:': 'برای پرداخت شماره والچرو یا سفارش و مشخصات حساب و مبلغ دقیق را وارد کنید',
    'Название организации': 'اسم کمپانی',
    'Юридический адрес': 'آدرس حقوقی کمپانی',
    'Сформировать счет': 'آماده کردن حساب بانکی',
    'Расчетный счет': 'حساب بانکی',
    'ИНН': 'ای ان ان',
    'КПП': 'ک پ پ',
    'Банк': 'بانک',
    'Полное название банка (для формирования акта). Например: ': 'اسم کامل بانک برای صورت حساب پرکردن',
    'БИК Банка': 'شماره بیک بانک',
    'Корреспондентский счет': 'حساب کورس پاندنسکی',

    // история платежей
    'Номер платежа': 'شماره قبض پرداختی ',
    Ваучеры: 'والچر ها',
    'Способ оплаты': 'روش پرداخت ',
    'Время и дата оплаты': 'زمان و تاریخ پرداختی ها',
    Сумма: 'مبلغ ',
    Статус: 'مرحله',
    'Платежи отсутствуют': 'قبض پرداختی وجود ندارد',

    // прайс-лист
    'Прайс-лист для иностранных групп': 'قیمتها برای گروههای خارجی ',
    'Актуальный файл с ценами для иностранных групп.': 'فایل واقعی با قیمت برای گروههای خارجی ',
    Открыть: 'باز',
    'Прайс-лист для российских групп': 'قیمتها برای گروههای روسی ',
    'Актуальный файл с ценами для российских групп.': 'فایل واقعی باقیمت برای گروههای روسی ',

    // лицензии
    'Добавить лицензию': 'مجوز را اضافه کنید',
    'В настоящий момент нет сохраненных лицензий, вы можете добавить их.': 'درحال حاضرمجوز شما نگهداری نشده هست میتوانید اضافه کنید',
    'Добавить': 'اضافه کردن',
    'Музей': 'موزه',
    'Номер лицензии': 'شماره مجوز',
    'Стаж (лет)': 'تجریه کاری (سال)',
    'Дата окончания': 'زمان تمام شدن',
    'Основной язык': 'زبان اصلی',
    'Дополнительные языки': 'زبان بعدی',

    // о компании
    'ИНН:7841027155': ' ای ان ان 7841027155',
    'КПП: 784101001': 'ک پ  پ 784101001',
    'ОГРН: 1157847276003': 'ا گ رن1157847276003',
    'ОКПО: 25885413': 'ا ا ک پ ا 25885413',
    'Расчетный счет: 40702810603260000188': 'شماره حساب40702810603260000188',
    'Банк: Филиал "Центральный" Банка ВТБ (ПАО)': 'بانک مرکزی و ت ب',
    'БИК: 044525411': 'بیک 044525411',
    'Корр. счет: 30101810145250000411': 'حساب کر 30101810145250000411',
    'Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405': 'آدرس حقوقی کمپانی 191186سنت پتربورگ کانال گریبایدوا ساختمان 5 شماره 405',
    'Email: info@avangard-travel.ru': 'امیلinfo@avangard-travel.ru   ',
    'Телефон: (812) 640-02-28': 'تلفن 8126400228',

    // договор
    'Вы можете посмотреть договор с ООО "Авангард Травел Групп".': 'شما میتوانید به قرارداد با شرکت آوانگارد نگاه کنید',

    // договор оферты
    'Вы можете посмотреть договор оферты с ООО "Авангард Травел Групп".': 'شما میتوانید به پیش قرارداد با شرکت آوانگارد نگاه کنید',

    // политика возврата
    'Вы можете посмотреть политику возврата.': 'شما میتوانید به روش کنسلی نگاه کنید',

    // настройки
    'Имя:': '',
    'Телефон:': 'شماره تلفن ',
    'Страна:': 'کشور',
    'Информация о гиде': 'اطلاعات راهنما',
    'Заполните информацию и подавайте заявки еще быстрее': 'اطلاعات را پرکنید و درخواست را سریعتر ارسال کنید',
    Имя: 'اسم ',
    Телефон: 'شماره تلفن',
    'Выберите страницу': 'کشور را انتخاب کنید',
    'Использовать в заказе?': 'در سفارش استفاده شود؟',
    Сохранить: 'نگهداری کردن',
    'Являетесь ли вы туристической фирмой / юридическим лицом': 'آیا شما شرکت توریستی هستید شرکت حقوقی هستید',
    'Юридическое лицо': 'شرکت حقوقی',
    'Для выставления счетов': 'برای ارسال صورت حساب',

    // форма логина
    Логин: 'لوگین ',
    Пароль: 'پارول',
    Войти: 'وارد شدن',
    'Выберите язык': 'انتخاب زبان',
    'У вас нет аккаунта?': 'شما حساب کاربری ندارید؟',
    'Зарегистрироваться': 'ثبت کردن',
    'Добро пожаловать!': 'خوش آمدید',
    'Будем рады видеть вас среди наших клиентов! Пожалуйста, уделите немного времени на заполнение формы ниже. Вскоре мы отправим вам все необходимые инструкции на указанный адрес электронной почты.': '-از اینکه شما را دربین مشتریانمان میبینیم خوشحال هستیم ،خواهشمندم برای پر کردن فرم زیر کمی وقت بگذارید،به امیل شما ما دستورالعمل لازم را میفرستیم',
    'Фамилия': 'فامیلی',
    'Физ.лицо': 'شخصی ',
    'Юр.лицо': 'شرکتی',
    'Отчество': 'اسم پدر',
    'У вас есть аккаунт?': 'شما حساب کاربری دارید؟',
    'Название фирмы': 'اسم شرکت ',
    'БИК': 'БИК',
    'ФИО контактного лица': 'فامیلی و اسم مخاطب',
    'Заявка принята!': 'درخواست شما پذیرفته شد',
    'Спасибо за вашу заявку! В ближайшее время на указанный вами адрес электронной почты мы отправим все необходимые инструкции.': 'با تشکر از درخواست شما،به زودی به امیل شما  دستورالعمل لازم را میفرستیم',

    // уведомление
    'Теперь можно оплатить заявки онлайн, включая оплату от юридических лиц!': 'الان میتوانید درخواست را به نام کمپانی  انلاین پرداخت کنید ',
    'Мы рады сообщить, что теперь вы можете оплатить заявки на нашем сайте, используя банковскую карту или QR-код системы быстрых платежей. Вдобавок к этому, мы расширили наши возможности и теперь доступна оплата и для юридических лиц. Юридические лица могут самостоятельно генерировать счета для оплаты.': 'با استفاده از کارت بانکی و بارکد میتوانید پرداختی را انجام بدهید',
    'Никаких задержек и лишних хлопот - оплата проходит мгновенно. Перейдите в раздел "Оплата заявок" и оплачивайте свои заявки быстро и удобно! Наши новые возможности сделают процесс ещё более удобным и эффективным.': 'واریزی بدون وقفه انجام میشود',
    Закрыть: 'بسته',

    // помощь
    'У вас есть вопросы?': 'آیا شما سوال دارید؟',
    'Если вы не можете найти ответ на вопрос, вы всегда можете связаться с нами. Мы ответим вам в ближайшее время!': ' اگرجواب سوال خودتان را پیدا نکردید همیشه میتوانید به ما زنگ بزنید ما سریع جواب میدهیم',
    'Мы всегда рады помочь!': 'خوشحال میشویم که همیشه به شما کمک کنیم',
    'Лучший способ получить ответ быстрее!': 'بهترین روش برای اینکه سریع جواب خود را بگیرید',

    // в разработе
    'Готовимся к запуску. Новая функция в пути! 🚀': 'آماده برای راه اندازی،طرح جدید در راه هست ',
    'Мы активно работаем над новой функцией для вас.': 'ما فعالانه برای شما بر روی طرح جدید کار میکنیم ',
    'Будьте в курсе предстоящих обновлений!': 'در جریان روز رسانی های ما باشید',

    // Обратная связь
    'Мы всегда рады услышать вас!': 'ما همیشه ازتوجهات شما خوشحال میشویم ',
    'Наши усилия направлены на то, чтобы сделать систему подачи заявок максимально удобной и эффективной для вас. Ваше мнение и предложения играют ключевую роль в этом процессе. Не стесняйтесь делиться своими мыслями, идеями, а также сообщать нам обо всех замеченных ошибках или проблемах в работе системы. Вместе мы сделаем систему подачи заявок лучше!': 'تلاش ما بر این است که سیستم برنامه کاربردی را تا حد امکان برای شما راحت و کارآمد کنیم. نظرات و پیشنهادات شما نقش اساسی در این فرآیند دارد. در صورت تمایل نظرات، ایده های خود را به اشتراک بگذارید و همچنین هرگونه خطا یا مشکلی را که در سیستم مشاهده می کنید با ما در میان بگذارید. ما با هم سیستم برنامه را بهتر خواهیم کرد!',
    'Как к вам обращаться?': 'اسم شما یا اسم کمپانی شما چی هست',
    'Email для обратной связи': 'امیل خودنان را بنویسید تا با شما تماس داشته باشیم ',
    'Опишите ваше предложение, жалобу или замеченную ошибку': 'پیشنهادات و نظریات و شکایات و یا اشتباه ما را بنویسید',
}
