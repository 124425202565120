<template>
  <v-row>
    <v-col
      xs="12"
      sm="12"
      md="12"
      lg="11"
      cols="12"
    >
      <editing-order></editing-order>
    </v-col>
  </v-row>
</template>

<script>
import EditingOrder from '@/components/EditingOrder.vue'

export default {
  components: {
    EditingOrder,
  },
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>
