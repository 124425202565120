<template>
  <v-card>
    <v-card-title>
      {{ $t('Новости') }}
    </v-card-title>

    <v-card-text>
      <v-timeline
        dense
        class="timeline-custom-dense timeline-custom-dots"
      >
        <v-timeline-item
          v-for="newsItem in newsList"
          :key="newsItem.id"
          color="primary"
          small
        >
          <div class="d-flex justify-space-between">
            <h3 class="mb-1 text--primary font-weight-semibold">
              {{ newsItem['title' + getFormatedLocale()] }}
            </h3>
            <small>{{ formatDate(newsItem.date) }}</small>
          </div>
          <v-img
            v-if="newsItem.image"
            width="30%"
            :src="newsItem.image"
            class="rounded me-4 mb-4 mt-4"
          ></v-img>
          <p
            class="mb-1 body-1"
            v-html="replaceBreak(newsItem['content' + getFormatedLocale()]) "
          >
          </p>
        </v-timeline-item>
      </v-timeline>
      <!-- <v-pagination
        v-model="currentPage"
        :length="5"
        class="mt-4 mb-4"
      ></v-pagination> -->
    </v-card-text>
  </v-card>
</template>

<script>
import {
 mdiFilePdf, mdiArrowRight, mdiMessageTextOutline, mdiPhone,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import { formatDate, replaceBreak } from '@core/utils/filter'

export default {
  setup() {
    const currentPage = ref(1)

    const fetchNews = () => {
      store.dispatch('news/fetchNews')
    }
    fetchNews()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const disableNewsMark = () => {
      userData.news_mark = false
      localStorage.setItem('userData', JSON.stringify(userData))
      store.dispatch('news/disableNewsMark').then(() => {})
    }
    if (userData.news_mark) {
      disableNewsMark()
    }
    const getFormatedLocale = () => {
        const locale = localStorage.getItem('locale') || 'ru'
        if (locale === 'zh') {
            return '_zh_cn'
        } if (locale === 'en') {
            return '_en_US'
        } if (locale === 'ru') {
            return '_ru_RU'
        } if (locale === 'fa') {
            return '_fa_IR'
        } if (locale === 'vi') {
            return '_vi'
        }

return '_ru_RU'
    }

    return {
      formatDate,
      replaceBreak,
      getFormatedLocale,
      icons: {
        mdiFilePdf,
        mdiArrowRight,
        mdiMessageTextOutline,
        mdiPhone,
      },
      currentPage,
      newsList: computed(() => store.getters['news/getNews']),
    }
  },
}
</script>

<style>
.v-timeline--dense .v-timeline-item__body {
    max-width: calc(100% - 10px) !important;
}
</style>
