<template>
  <v-card class="license-card">
    <v-card-title class="align-start">
      <span class="text-2xl font-weight-semibold pl-2">
        {{ license.lic_object_name }}
      </span>
      <v-spacer></v-spacer>
      <span
        class="fix-state-width text-xl font-weight-bold"
      >
        <v-menu
          bottom
          :close-on-content-click="closeOnClick"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              outlined
              color="secondary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                dark
                small
                class="mr-2"
              >
                {{ icons.mdiPencil }}
              </v-icon>
              Изменить
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group
              color="primary"
            >
              <!-- Изменить языки -->
              <v-list-item>
                <v-list-item-content>
                  <v-col
                    v-model="isDialogVisibleChangeLanguage"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    cols="12"
                  >
                    <v-dialog
                      persistent
                      max-width="600px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-list-item-title
                          v-bind="attrs"
                          v-on="on"
                          @click="updateLanguageStore(license.langs)"
                        >
                          Изменить языки
                        </v-list-item-title>
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="headline">Изменить языки</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container class="mt-4">
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                              >
                                <v-select
                                  v-model="changeLanguageStore.primary_lang_id"
                                  item-value="id"
                                  item-text="name"
                                  :items="licensingLangs"
                                  label="Основной язык"
                                  dense
                                ></v-select>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                              >
                                <v-autocomplete
                                  v-model="changeLanguageStore.additional_langs"
                                  item-value="id"
                                  item-text="name"
                                  :items="licensingLangs"
                                  label="Дополнительные языки"
                                  dense
                                  multiple
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            outlined
                            @click="isDialogVisibleChangeLanguage = false"
                          >
                            Закрыть
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="changeLicenseLanguages(license.license_id)"
                          >
                            Сохранить
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <!-- Удалить лицензию -->
              <v-list-item
                color="primary"
              >
                <v-list-item-content>
                  <v-col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    cols="12"
                  >
                    <v-dialog
                      v-model="isDialogVisibleDeleteLicense"
                      persistent
                      max-width="600px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-list-item-title
                          v-bind="attrs"
                          v-on="on"
                        >
                          Удалить лицензию
                        </v-list-item-title>
                      </template>

                      <v-card>
                        <v-card-title class="headline">
                          Удалить лицензию
                        </v-card-title>
                        <v-card-text>Вы дейсвительно хотите удалить лицензию?</v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            outlined
                            @click="isDialogVisibleDeleteLicense = false"
                          >
                            Нет
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="deleteLicense(license.license_id)"
                          >
                            Да
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

      </span>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="4"
          cols="12"
        >
          <div
            class="d-flex align-center mt-2"
          >
            <v-icon
              size="28"
              class="mt-1"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
            <div class="ms-2">
              <span class="text">Номер лицензии</span>

              <p class="text-no-wrap text--primary mb-0">
                {{ license.license_no }}
              </p>
            </div>
          </div>
        </v-col>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="4"
          cols="12"
        >
          <div
            class="d-flex align-center mt-2"
          >
            <v-icon
              size="28"
              class="mt-1"
            >
              {{ icons.mdiCalendarCheck }}
            </v-icon>
            <div class="ms-2">
              <span class="text">Стаж (лет)</span>
              <p class="text-no-wrap text--primary mb-0">
                {{ license.seniority }}
              </p>
            </div>
          </div>
        </v-col>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="4"
          cols="12"
        >
          <div
            class="d-flex align-center mt-2"
          >
            <v-icon
              size="28"
              class="mt-1"
            >
              {{ icons.mdiCalendarMonthOutline }}
            </v-icon>
            <div class="ms-2">
              <span class="text">Действительна до</span>
              <p class="text-no-wrap text--primary mb-0">
                {{ formatDate(license.expiration_date) }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="4"
          cols="12"
        >
          <div
            class="d-flex align-center mt-4 mb-4"
          >
            <v-icon
              size="28"
              class="mt-1"
            >
              {{ icons.mdiWeb }}
            </v-icon>
            <div class="ms-2">
              <span class="text">Основной язык</span>
              <p class="text-no-wrap text--primary mb-0">
                <span
                  v-for="lang in license.langs"
                  :key="lang.id"
                ><span v-if="lang.primary">{{ lang.name }}</span></span>
              </p>
            </div>
          </div>
        </v-col>
        <v-col
          v-if="license.langs.length > 1"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          cols="12"
        >
          <div
            class="d-flex align-center mt-4 mb-4"
          >
            <v-icon
              size="28"
              class="mt-1"
            >
              {{ icons.mdiPlusThick }}
            </v-icon>
            <div class="ms-2">
              <span class="text">Дополнительные языки</span>
              <p class="text-no-wrap text--primary mb-0">
                <span
                  v-for="lang in license.langs"
                  :key="lang.id"
                ><span v-if="!lang.primary">{{ lang.name }}<br></span></span>
              </p>
            </div>
          </div>
        </v-col>
        <v-col
          v-if="license.prolongation_price"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          cols="12"
        >
          <div
            class="d-flex align-center mt-4 mb-4"
          >
            <div class="ms-2">
              <span class="text font-weight-semibold">Стоимость продления</span>
              <p class="text-no-wrap text--primary mb-0">
                {{ license.prolongation_price }} ₽
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="license.prolongation_request_id">
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text v-if="license.prolongation_request_id">
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="primary">
            <v-icon
              dark
              size="26"
              color="white"
              v-text="icons.mdiCheckBold"
            ></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Заявка принята</v-list-item-title>
            <v-list-item-subtitle>Вы хотите продлить эту лицензию</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCalendarCheck,
  mdiCalendarMonthOutline,
  mdiWeb,
  mdiPlusThick,
  mdiCheckBold,
  mdiInformationOutline,
  mdiPencil,
} from '@mdi/js'

export default {
  components: {
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
    licensingLangs: {
        type: Array,
        required: true,
    },
  },
  setup() {
    const isDialogVisibleChangeLanguage = ref(false)
    const isDialogVisibleDeleteLicense = ref(false)
    const isDialogVisiblePolongateLicense = ref(false)
    const closeOnClick = ref(false)
    const items = [{ title: 'Изменить языки' }, { title: 'Удалить лицензию' }]

    const changeLanguageStore = ref({
        primary_lang_id: null,
        additional_langs: [],
    })

    const deleteLicense = licenseId => {
        store.dispatch('licenses/deleteLicense', { license_id: licenseId }).then(() => {
            isDialogVisibleDeleteLicense.value = false
            store.dispatch('licenses/fetchLicenses')
        })
    }
    const updateLanguageStore = langs => {
        langs.forEach(lang => {
            if (lang.primary === true) {
                changeLanguageStore.value.primary_lang_id = lang.id
            } else {
                changeLanguageStore.value.additional_langs.push(lang.id)
            }
        })
    }

    const changeLicenseLanguages = licenseId => {
        changeLanguageStore.value.license_id = licenseId
        store.dispatch('licenses/changeLicenseLanguages', changeLanguageStore.value).then(() => {
            isDialogVisibleChangeLanguage.value = false
            store.dispatch('licenses/fetchLicenses')
        })
    }

    return {
        formatDate,
        isDialogVisibleChangeLanguage,
        isDialogVisibleDeleteLicense,
        closeOnClick,
        items,
        deleteLicense,
        updateLanguageStore,
        changeLanguageStore,
        changeLicenseLanguages,
        isDialogVisiblePolongateLicense,
        icons: {
            mdiAccountOutline,
            mdiCalendarCheck,
            mdiCalendarMonthOutline,
            mdiWeb,
            mdiPlusThick,
            mdiCheckBold,
            mdiInformationOutline,
            mdiPencil,
        },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.license-card {
  width: 100%;
}
</style>
