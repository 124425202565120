<template>
  <div>
    <v-row class="mb-2">
      <!-- loader -->
      <v-col
        v-if="false"
        xs="12"
        sm="12"
        md="12"
        lg="10"
        cols="12"
      >
        <loader></loader>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="2"
      >
        <v-btn
          color="primary"
          dark
          @click="$router.go(-1);"
        >
          <v-icon
            dark
            left
          >
            {{ icons.mdiArrowLeft }}
          </v-icon>{{ $t('Назад') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="9"
        lg="9"
        xl="9"
        class="pt-0"
      >
        <v-card>
          <v-card-title class="align-start">
            <span class="text-xl font-weight-semibold">
              {{ $t('Заявка') }}
              # {{ selectedOrder.id }}
            </span>
            <v-spacer></v-spacer>
            <span
              :class="'text-2xl font-weight-bold ' + getOrderStatusColor(selectedOrder.state, selectedOrder.is_locked)"
            >
              {{ $t(getOrderStatusTitle(selectedOrder.state)) }}
            </span>
          </v-card-title>
          <v-card-subtitle class="mx-0 px-5 pt-0 my-0">
            <v-row class="pb-2">
              <v-col
                class="pb-2 pt-2 pl-4"
                cols="12"
                xs="12"
                sm="6"
                order-xs="2"
              >
                REF #{{ selectedOrder.data.refno }}
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-title class="no-break text-2xl py-4">
            {{ objectInfo.museum['name' + getFormatedLocale()] }}
          </v-card-title>
          <v-card-subtitle class="no-break pt-2 pb-4">
            {{ objectInfo['name' + getFormatedLocale()] }}
          </v-card-subtitle>
          <v-card-text>
            <h3 class="text-xl font-weight-semibold mb-4 mt-6">
              {{ $t('Дата и время:') }}
            </h3>
            <datepicker
              on-page="new-request-date"
              :disabled="selectedOrder.state === 2"
              :date-value="selectedOrder.data.pass_date"
              @newDate="handleDateChanges($event)"
            ></datepicker>

            <v-select
              v-model="selectedOrder.data.schedule_item_id"
              :disabled="selectedOrder.state === 2"
              :items="schedule"
              :rules="[v => !!v || $t('Выберите время')]"
              :label="$t('Выберите время')"
              required
              item-text="name"
              item-value="id"
              hide-details="auto"
              class="mb-4 mt-4"
              outlined
              dense
            ></v-select>
            <h3 class="text-xl font-weight-semibold mb-4 mt-8">
              {{ $t('Информация о группе:') }}
            </h3>
            <v-select
              v-if="!(objectInfo.is_audioguide_request || objectInfo.is_radioguide_request)"
              v-model="selectedOrder.data.country_id"
              :items="objectInfo.countries"
              :rules="[v => !!v || $t('Выберите страну')]"
              :label="$t('Выберите страну')"
              required
              hide-details="auto"
              item-text="name"
              item-value="system_id"
              class="mt-2 mb-6"
              outlined
              dense
            ></v-select>
            <!-- Билеты -->
            <div
              v-for="kindOfTicket in objectInfo.tickets"
              :id="kindOfTicket.id"
              :key="kindOfTicket.internal_name"
            >
              <v-text-field
                v-if="kindOfTicket.not_is_new"
                v-model="selectedOrder.data[kindOfTicket.system_name]"
                type="number"
                outlined
                dense
                class="mt-2 mb-2"
                :label="kindOfTicket['name' + getFormatedLocale()]"
                :hint="kindOfTicket['helper_text' + getFormatedLocale()]"
                placeholder="0"
                :rules="[validators.integerPositiveValidator]"
                persistent-hint
              >
              </v-text-field>
              <text-field-from-notes
                v-else
                :key="reRenderKey"
                :notes="selectedOrder.data.notes"
                :kind-of-ticket="kindOfTicket"
                @update-notes="updateNotes"
              >
              </text-field-from-notes>
            </div>
            <h3 class="text-xl font-weight-semibold mb-4 mt-8">
              {{ $t('Доп.услуги:') }}
            </h3>
            <!-- Услуги -->
            <div
              v-for="kindOfService in objectInfo.services"
              :id="kindOfService.id"
              :key="kindOfService.id"
            >
              <v-checkbox
                v-if="kindOfService.not_is_new"
                v-model="selectedOrder.data[kindOfService.system_name]"
                hide-details
                class="mt-2 mb-2"
              >
                <template #label>
                  <div>
                    {{ kindOfService['name' + getFormatedLocale()] }}
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          size="20"
                          class="ml-2 info--secondary"
                          v-on="on"
                        >
                          {{ icons.mdiHelpCircleOutline }}
                        </v-icon>
                      </template>
                      <span>{{ kindOfService.helper_text }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <checkbox-from-notes
                v-else
                :key="reRenderKey"
                :notes="selectedOrder.data.notes"
                :kind-of-service="kindOfService"
                @update-notes="updateNotes"
              >
              </checkbox-from-notes>
            </div>
            <!-- Сопровождающий гид -->
            <v-text-field
              v-if="objectInfo.is_additional_guide_field"
              v-model="selectedOrder.data.qty_guide"
              type="number"
              outlined
              dense
              class="mt-6"
              :label="$t('Кол-во гидов сопровождающих (если нет лицензии)')"
              :placeholder="$t('Кол-во гидов сопровождающих (если нет лицензии)')"
              :hint="$t('Необходим если у гида нет лицензии в выбранный музей')"
              persistent-hint
              hide-details="auto"
            ></v-text-field>
            <h3 class="text-xl font-weight-semibold mb-4 mt-8">
              {{ $t('Доп.информация:') }}
            </h3>
            <v-text-field
              v-if="!(objectInfo.is_audioguide_request || objectInfo.is_radioguide_request)"
              v-model="selectedOrder.data.refno"
              type="text"
              outlined
              dense
              class="mt-6 mb-2"
              :label="$t('Ref номер (необязательно)')"
              :placeholder="$t('Ref номер (необязательно)')"
              :hint="$t('Уникальный номер группы / вспомогательный номер для ваших целей')"
              persistent-hint
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="selectedOrder.data.guide"
              type="text"
              outlined
              dense
              class="mt-2 mb-4"
              :label="$t('Имя гида')"
              :placeholder="$t('Имя гида')"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="selectedOrder.data.guide_phone"
              type="text"
              outlined
              dense
              class="mt-2 mb-4"
              :label="$t('Телефон гида')"
              :placeholder="$t('Телефон гида')"
              hide-details="auto"
            ></v-text-field>
            <textarea-from-notes
              :notes="selectedOrder.data.notes"
              @update-notes="updateNotes"
            ></textarea-from-notes>
            <v-btn
              color="primary"
              class="ms-2"
              @click="updateOrder"
            >
              {{ $t('Сохранить') }}
            </v-btn>
          </v-card-text>
          <!-- Модальные окна -->
          <v-dialog
            v-model="isSending"
            overlay
            persistent
            width="300"
          >
            <v-card
              color="primary"
              dark
            >
              <v-card-text class="pt-3 white--text">
                {{ $t('Пожалуйста, ожидайте') }}
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0 mt-2"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="isShowMessage"
            overlay
            persistent
            width="500"
          >
            <v-card>
              <v-card-title v-if="isSuccess">
                {{ $t('Успешно') }}
              </v-card-title>
              <v-card-title
                v-else
              >
                {{ $t('Ошибка') }}
              </v-card-title>

              <v-card-text v-if="isSuccess">
                {{ successMsg }}
              </v-card-text>
              <v-card-text v-else>
                {{ errorMsg }}
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="isSuccess"
                  color="primary"
                  @click="closeMessageBoxAndRedirect"
                >
                  {{ $t('Ок') }}
                </v-btn>
                <v-btn
                  v-else
                  color="error"
                  @click="closeMessageBox"
                >
                  {{ $t('Ок') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref } from '@vue/composition-api'
import { mdiArrowLeft, mdiHelpCircleOutline } from '@mdi/js'
import { required, integerPositiveValidator } from '@core/utils/validation'
import Datepicker from '@/components/Datepicker.vue'
import Loader from '@/components/Loader.vue'
import TextFieldFromNotes from '@/components/TextFieldFromNotes.vue'
import CheckboxFromNotes from '@/components/CheckboxFromNotes.vue'
import TextareaFromNotes from '@/components/TextareaFromNotes.vue'
import store from '@/store'
import { useRouter } from '@core/utils'
import { getOrderStatusColor, getOrderStatusTitle } from '@/utils/helper'

export default {
  components: {
    Loader,
    Datepicker,
    TextFieldFromNotes,
    CheckboxFromNotes,
    TextareaFromNotes,
  },
  setup() {
    const { router } = useRouter()
    const selectedOrder = JSON.parse(localStorage.getItem('selectedOrder'))
    const objectInfo = JSON.parse(localStorage.getItem('objectInfo'))
    const reRenderKey = ref(1)

    const fetchMuseums = () => {
      store.dispatch('museums/fetchMuseums')
    }
    fetchMuseums()
    const fetchMuseumObjects = () => {
      store.dispatch('museumObjects/fetchMuseumObjects', objectInfo.museum.id)
    }
    fetchMuseumObjects()

    const fetchSchedule = () => {
      const formData = {
        date: selectedOrder.data.pass_date,
        museumObjectId: objectInfo.system_id,
      }
      store.dispatch('schedule/fetchSchedule', formData)
    }
    fetchSchedule()
    const handleDateChanges = event => {
      selectedOrder.data.pass_date = event.date
      fetchSchedule()
    }
    const updateNotes = notes => {
      reRenderKey.value += 1
      selectedOrder.data.notes = notes
    }
    const updateOrder = () => {
      store.dispatch('updateOrder/updateOrder', selectedOrder)
    }

    const closeMessageBoxAndRedirect = () => {
      store.commit('updateOrder/close_message')
      router.push({ name: 'upcoming-requests' })
    }
    const closeMessageBox = () => {
      store.commit('updateOrder/close_message')
    }
    const getFormatedLocale = () => {
        const locale = localStorage.getItem('locale') || 'ru'
        if (locale === 'zh') {
            return '_zh_cn'
        } if (locale === 'en') {
            return '_en_US'
        } if (locale === 'ru') {
            return '_ru_RU'
        } if (locale === 'fa') {
            return '_fa_IR'
        } if (locale === 'vi') {
            return '_vi'
        }

return '_ru_RU'
    }

    return {
      icons: {
        mdiArrowLeft,
        mdiHelpCircleOutline,
      },
      validators: {
        required,
        integerPositiveValidator,
      },
      selectedOrder,
      objectInfo,
      handleDateChanges,
      fetchSchedule,
      updateNotes,
      reRenderKey,
      updateOrder,
      closeMessageBoxAndRedirect,
      closeMessageBox,
      getOrderStatusColor,
      getOrderStatusTitle,
      getFormatedLocale,
      museumsList: computed(() => store.getters['museums/getMuseumsList']),
      museumObjects: computed(() => store.getters['museumObjects/getMuseumObjectsList']),
      schedule: computed(() => store.getters['schedule/getSchedule']),
      isSending: computed(() => store.getters['updateOrder/isSending']),
      isShowMessage: computed(() => store.getters['updateOrder/isShowMessage']),
      successMsg: computed(() => store.getters['updateOrder/successMsg']),
      errorMsg: computed(() => store.getters['updateOrder/errorMsg']),
      isSuccess: computed(() => store.getters['updateOrder/isSuccess']),
    }
  },
}
</script>
