export const formatDate = request => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }

    const [year, month, day] = request.split('-')
    const d = new Date(year, month - 1, day, 0, 0, 0, 0)

    const locale = localStorage.getItem('locale') || 'ru'

    if (locale === 'ru') {
        return d.toLocaleDateString('ru-RU', options)
    } if (locale === 'en') {
        return d.toLocaleDateString('en-US', options)
    } if (locale === 'zh') {
        return d.toLocaleDateString('zh-CN', options)
    } if (locale === 'fa') {
        return d.toLocaleDateString('fa-IR', options)
    } if (locale === 'vi') {
        return d.toLocaleDateString('vi-VN', options)
    }
}

export const formatToLocalDatetime = utcString => {
    const date = new Date(utcString)
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: '2-digit',
        minute: '2-digit',
      }
    const locale = localStorage.getItem('locale') || 'ru'
    if (locale === 'ru') {
        return date.toLocaleString('ru-RU', options)
    } if (locale === 'en') {
        return date.toLocaleString('en-US', options)
    } if (locale === 'zh') {
        return date.toLocaleString('zh-CN', options)
    } if (locale === 'fa') {
        return date.toLocaleString('fa-IR', options)
    } if (locale === 'vi') {
        return date.toLocaleString('vi-VN', options)
    }
}

// export const dc3 = (
//     value,
//     formatting = {
//         month: 'long',
//         day: 'numeric',
//         year: 'numeric',
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit',
//     },
// ) => {
//     if (!value) return value

//     return new Intl.DateTimeFormat('ru-RU', formatting).format(new Date(value))
// }
