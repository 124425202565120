<template>
  <v-row>
    <!-- filter form -->
    <v-col
      md="10"
      lg="11"
      cols="12"
    >
      <v-card class="px-8 py-8">
        <v-row>
          <!-- номер заявки -->
          <v-col
            cols="12"
            md="3"
          >
            <label for="firstnameHorizontalIcons">{{ $t('Номер заявки или ref') }}</label>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="firstnameHorizontalIcons"
              :v-model="$t(archiveFilter.no)"
              :prepend-inner-icon="icons.mdiPound"
              :label="$t('Номер заявки')"
              outlined
              dense
              hide-details="auto"
              :placeholder="$t('Все заявки')"
            ></v-text-field>
          </v-col>

          <!-- выбор объекта -->
          <v-col
            cols="12"
            md="3"
          >
            <label for="emailHorizontalIcons">{{ $t('Объект') }}</label>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <v-select
              v-model="archiveFilter.museum_id"
              :items="allMuseumObjects.filter(museum => museum.name !== '')"
              :item-text="'name' + getFormatedLocale()"
              item-value="system_id"
              :label="$t('Все')"
              :prepend-inner-icon="icons.mdiBankOutline"
              hide-details="auto"
              outlined
              dense
            ></v-select>
          </v-col>

          <!-- дата посещения с -->
          <v-col
            cols="12"
            md="3"
          >
            <label for="mobileHorizontalIcons">{{ $t('Дата посещения с') }}</label>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <datepicker
              on-page="archive-requests-date-from"
              @newDate="handleDateChanges($event)"
            ></datepicker>
          </v-col>

          <!-- дата посещения по -->
          <v-col
            cols="12"
            md="3"
          >
            <label for="passwordHorizontalIcons">{{ $t('Дата посещения по') }}</label>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <datepicker
              on-page="archive-requests-date-to"
              @newDate="handleDateChanges($event)"
            ></datepicker>
          </v-col>

          <!-- найти -->
          <v-col
            offset-md="3"
            cols="12"
          >
            <v-btn
              color="primary"
              @click="getArchive"
            >
              {{ $t('Найти') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!-- loader -->
    <v-col
      v-if="requestIsSending"
      xs="12"
      sm="12"
      md="12"
      lg="10"
      cols="12"
    >
      <loader></loader>
    </v-col>
    <!-- archive data -->
    <v-col
      v-for="request in archiveOrders"
      :key="request.id"
      xs="12"
      sm="12"
      md="12"
      lg="11"
      cols="12"
    >
      <order
        :key="request.id"
        :order="request"
        :disable-actions="['archive','location']"
        :object-info="museumObjectsInfo[request.data.museum_id]"
      ></order>
    </v-col>
    <!-- archive request empty -->
    <v-col
      v-if="archiveRequestIsSuccess && archiveOrders.length == 0"
      class="mt-6"
      xs="12"
      sm="12"
      md="12"
      lg="11"
      cols="12"
    >
      <v-alert
        border="left"
        color="secondary"
        text
        dark
      >
        {{ $t('Заказов согласно фильтру не найдено') }}
      </v-alert>
    </v-col>
    <!-- archive request error -->
    <v-col
      v-if="archiveRequestIsSuccess === false"
      class="mt-6"
      xs="12"
      sm="12"
      md="12"
      lg="11"
      cols="12"
    >
      <v-alert
        border="left"
        color="error"
        text
        dark
      >
        <strong>{{ $t('Ошибка:') }}</strong>{{ archiveRequestErrorMsg }}
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiPound, mdiBankOutline, mdiCalendarToday } from '@mdi/js'
import store from '@/store'
import Order from '@/components/Order.vue'
import Loader from '@/components/Loader.vue'
import Datepicker from '@/components/Datepicker.vue'

export default {
  components: {
    Loader,
    Order,
    Datepicker,
  },
  setup() {
    const archiveFilter = ref({
      no: 'Любой номер',
      museum_id: null,
      period_lower: new Date().toISOString().substr(0, 10),
      period_upper: new Date().toISOString().substr(0, 10),
    })
    const menu1 = ref(false)
    const menu2 = ref(false)
    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

    const fetchMuseumObjects = () => {
      store.commit('archive/init_archive')
      store.dispatch('archive/fetchAllMuseumObjects')
    }
    fetchMuseumObjects()

    const getArchive = () => {
      store.dispatch('archive/getArchiveRequest', archiveFilter.value)
    }

    const getDay = currentDate => {
      const i = new Date(currentDate).getDay(currentDate)

      return daysOfWeek[i]
    }
    const handleDateChanges = event => {
      if (event.onPage === 'archive-requests-date-from') {
        archiveFilter.value.period_lower = event.date
      }
      if (event.onPage === 'archive-requests-date-to') {
        archiveFilter.value.period_upper = event.date
      }
    }
    const getFormatedLocale = () => {
        const locale = localStorage.getItem('locale') || 'ru'
        if (locale === 'zh') {
            return '_zh_cn'
        } if (locale === 'en') {
            return '_en_US'
        } if (locale === 'ru') {
            return '_ru_RU'
        } if (locale === 'fa') {
            return '_fa_IR'
        } if (locale === 'vi') {
            return '_vi'
        }

return '_ru_RU'
    }

    return {
      menu1,
      menu2,
      archiveFilter,
      getArchive,
      icons: {
        mdiPound,
        mdiBankOutline,
        mdiCalendarToday,
      },
      getDay,
      handleDateChanges,
      getFormatedLocale,
      allMuseumObjects: computed(() => store.getters['archive/getAllMuseumObjects']),
      archiveOrders: computed(() => store.getters['archive/getArchive']),
      museumObjectsInfo: computed(() => store.getters['archive/getMuseumObjectsInfo']),
      archiveRequestIsSuccess: computed(() => store.getters['archive/getRequestStatus']),
      archiveRequestErrorMsg: computed(() => store.getters['archive/getRequestErrorMsg']),
      requestIsSending: computed(() => store.getters['archive/getRequestIsSending']),
    }
  },
}
</script>
