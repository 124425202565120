<template>
  <v-row class="membership-pricing pt-5 pl-5 pr-5 pb-5">
    <v-col
      v-for="item in currentActions.filter(item => item !== 'annul')"
      :key="item"
      cols="12"
      lg="12"
      md="12"
      sm="4"
      class="px-2 py-0 mx-0 my-0"
    >
      <v-btn
        :color="avaliableActions[item].color"
        block
        outlined
        class="mt-2 pl-4 pr-4 mb-2"
        @click="avaliableActions[item].action(order)"
      >
        <v-icon
          v-if="avaliableActions[item].isIcon"
          small
          class="me-2"
        >
          {{ avaliableActions[item].icon }}
        </v-icon>{{ $t(avaliableActions[item].text) }}
      </v-btn>
    </v-col>
    <v-col
      v-if="currentActions.includes('annul')"
      cols="12"
      lg="12"
      md="12"
      sm="4"
      class="px-2 py-0 mx-0 my-0"
    >
      <v-dialog
        v-model="isDialogVisible"
        persistent
        width="500"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            block
            color="error"
            class="mt-2 pl-4 pr-4 mb-2"
            outlined
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('Аннулировать') }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">
            {{ $t('Хотите аннулировать заявку?') }}
          </v-card-title>
          <v-card-text>{{ $t('Аннулированная заявка попадет в архив и восстановить ее будет невозможно') }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              outlined
              @click="isDialogVisible = false"
            >
              {{ $t('Отмена') }}
            </v-btn>
            <v-btn
              color="error"
              @click="isDialogVisible = false; annul(order)"
            >
              {{ $t('Аннулировать') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <!-- Модальные окна -->
    <!-- location -->
    <v-dialog
      v-model="navigationData.is_show"
      overlay
      persistent
      width="500"
    >
      <v-card>
        <v-card-title v-if="navigationData.navigation === true">
          {{ $t('Сотрудник на объекте') }}
        </v-card-title>
        <v-card-title v-else>
          {{ $t('Сотрудник не на объекте') }}
        </v-card-title>

        <v-card-text v-if="navigationData.navigation === false">
          <p>
            {{ $t('Но вы можете связаться с нами:') }}
          </p>
          <p class="mb-1">
            {{ navigationData.chiefWorker.name }}
          </p>
          <a :href="`tel:${navigationData.chiefWorker.phone}`">{{ navigationData.chiefWorker.phone }}</a>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="closeNavigationDialog"
          >
            {{ $t('Ок') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- waiting -->
    <v-dialog
      v-model="isSending"
      overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3 white--text">
          {{ $t('Пожалуйста, ожидайте') }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- succes/error -->
    <v-dialog
      v-model="isShowMessage"
      overlay
      persistent
      width="500"
    >
      <v-card>
        <v-card-title v-if="isSuccess">
          {{ $t('Успешно') }}
        </v-card-title>
        <v-card-title
          v-else
        >
          {{ $t('Ошибка') }}
        </v-card-title>

        <v-card-text v-if="isSuccess">
          {{ successMsg }}
        </v-card-text>
        <v-card-text v-else>
          {{ errorMsg }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isSuccess"
            color="primary"
            @click="closeMessageBoxAndRedirect"
          >
            {{ $t('Ок') }}
          </v-btn>
          <v-btn
            v-else
            color="error"
            @click="closeMessageBox"
          >
            {{ $t('Ок') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { useRouter } from '@core/utils'
import { computed, ref } from '@vue/composition-api'
import { mdiTextBoxSearchOutline, mdiPencilOutline, mdiMapMarker } from '@mdi/js'
import store from '@/store'

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    objectInfo: {
      type: Object,
      required: true,
    },
    disabledActions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { router } = useRouter()
    const isDialogVisible = ref(false)

    const getActionsByStatus = (state, isLocked) => {
      switch (state) {
        case 1:
          // новая заявка
          return ['info', 'edit', 'annul']
        case 2:
          if (isLocked === 1) {
            // подтверждена и заблокирована
            return ['info', 'location']
          }

          // подтверждена и не заблокирована
          return ['info', 'edit', 'annul']
        case 3:
          // в листе ожидания
          return ['info', 'edit', 'annul']
        case 4:
          // аннулирована
          return ['info']
        case 5:
          // отклонена
          return ['info', 'archive']
        default:
          return []
      }
    }

    const currentActions = getActionsByStatus(props.order.state, props.order.is_locked).filter(
      action => !props.disabledActions.includes(action),
    )

    const toOrderInfo = order => {
      localStorage.setItem('selectedOrder', JSON.stringify(order))
      localStorage.setItem('objectInfo', JSON.stringify(props.objectInfo))
      router.push('/upcoming/info').catch(() => {})
    }
    const toOrderEdit = order => {
      localStorage.setItem('selectedOrder', JSON.stringify(order))
      localStorage.setItem('objectInfo', JSON.stringify(props.objectInfo))
      router.push('/upcoming/edit').catch(() => {})
    }
    const getWorkerLocation = order => {
      store.dispatch('actionsOrder/getWorkerLocation', order.data.museum_id).then(data => {
        if (data.navigation) {
          window.location = data.url
        }
      })
    }
    const annul = order => {
      store.dispatch('actionsOrder/annulOrder', order)
    }
    const archive = order => {
      store.dispatch('actionsOrder/archiveOrder', order)
    }
    const closeMessageBoxAndRedirect = () => {
      store.commit('actionsOrder/close_message')
      router.push({ name: 'upcoming-requests' }).catch(() => {
        emit('refetch-upcoming')
      })
    }
    const closeMessageBox = () => {
      store.commit('actionsOrder/close_message')
    }
    const closeNavigationDialog = () => {
      store.commit('actionsOrder/close_navigation_dialog')
    }
    const avaliableActions = {
      info: {
        text: 'ПОДРОБНЕЕ',
        isIcon: true,
        icon: mdiTextBoxSearchOutline,
        action: toOrderInfo,
        color: 'default',
      },
      edit: {
        text: 'ИЗМЕНИТЬ',
        isIcon: true,
        icon: mdiPencilOutline,
        action: toOrderEdit,
        color: 'default',
      },
      location: {
        text: 'ГДЕ СОТРУДНИК',
        isIcon: true,
        icon: mdiMapMarker,
        action: getWorkerLocation,
        color: 'default',
      },
      annul: {
        text: 'АННУЛИРОВАТЬ',
        isIcon: false,
        action: annul,
        color: 'default',
      },
      archive: {
        text: 'В АРХИВ',
        isIcon: false,
        action: archive,
        color: 'error',
      },
    }

    return {
      isDialogVisible,
      currentActions,
      avaliableActions,
      icons: {
        mdiTextBoxSearchOutline,
        mdiPencilOutline,
        mdiMapMarker,
      },
      toOrderInfo,
      toOrderEdit,
      getWorkerLocation,
      annul,
      closeMessageBoxAndRedirect,
      closeMessageBox,
      closeNavigationDialog,
      isSending: computed(() => store.getters['actionsOrder/isSending']),
      isShowMessage: computed(() => store.getters['actionsOrder/isShowMessage']),
      successMsg: computed(() => store.getters['actionsOrder/successMsg']),
      errorMsg: computed(() => store.getters['actionsOrder/errorMsg']),
      isSuccess: computed(() => store.getters['actionsOrder/isSuccess']),
      navigationData: computed(() => store.getters['actionsOrder/getNavigationData']),
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
}
.order-status-new {
  color: #8a8d93;
}
.order-status-accept {
  color: #56ca00;
}
.order-status-waiting {
  color: #ffb400;
}
.order-status-decline {
  color: #ff4c51;
}
</style>
