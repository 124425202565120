<template>
  <v-text-field
    v-model="infoFromNotes"
    type="number"
    outlined
    dense
    class="mt-2 mb-2"
    :label="kindOfTicket.name"
    :hint="kindOfTicket.helper_text"
    placeholder="0"
    :rules="[validators.integerPositiveValidator]"
    persistent-hint
  >
  </v-text-field>
</template>

<script>
import { computed } from '@vue/composition-api'
import { required, integerPositiveValidator } from '@core/utils/validation'

export default {
  props: {
    notes: {
      type: String,
      required: true,
    },
    kindOfTicket: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      validators: {
        required,
        integerPositiveValidator,
      },
      infoFromNotes: computed({
        get() {
          const removeBeforeValueString = props.notes.split(`${props.kindOfTicket.name}: `)[1]
          const valueString = removeBeforeValueString.split(' шт.')[0]

          return valueString
        },
        set(newValue) {
          const removeBeforeValueString = props.notes.split('###')[0]
          const pattern = `${props.kindOfTicket.name}: .+шт.`
          const re = new RegExp(pattern, 'g')
          const partNewStr = removeBeforeValueString.replace(re, `${props.kindOfTicket.name}: ${newValue} шт.`)
          const fullNewStr = `${partNewStr}### ${props.notes.split('###')[1]}`
          emit('update-notes', fullNewStr)
        },
      }),
    }
  },
}
</script>

<style lang="scss">
</style>
