import axios from '@axios'

export default {
  namespaced: true,
  state: {
    schedule: [],
    request_is_success: null,
    request_error_msg: null,
  },
  getters: {
    getSchedule: state => state.schedule,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
  },
  mutations: {
    set_on_request_success(state, payload) {
      state.schedule = payload
      state.request_is_success = true
      state.request_error_msg = null
    },
    set_on_request_error(state, error) {
      state.schedule = []
      state.request_is_success = false
      state.request_error_msg = error
    },
  },
  actions: {
    fetchSchedule({ commit }, formData) {
      return new Promise((resolve, reject) => {
        const data = JSON.stringify({
          museum_object_id: formData.museumObjectId,
          schedule_date: formData.date,
        })
        const postData = new FormData()
        postData.append('data', data)
        postData.append('action', 'get_schedule')
        axios({
          method: 'post',
          url: '/api/v1/actions/',
          data: postData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.status === 200) {
              commit('set_on_request_success', response.data.data.schedule_data.result)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
