import axios from '@axios'

export default {
  namespaced: true,
  state: {
    revise_file: null,
    museum_objects: [],
    request_is_sending: false,
    request_is_success: null,
    request_error_msg: null,
  },
  getters: {
    getFile: state => state.revise_file,
    getAllMuseumObjects: state => state.museum_objects,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
    getRequestIsSending: state => state.request_is_sending,
  },
  mutations: {
    init_revise(state) {
      state.revise_file = null
      state.request_is_sending = false
      state.request_is_success = null
      state.request_error_msg = null
    },
    set_request_init(state) {
      state.revise_file = null
      state.request_is_sending = true
      state.request_is_success = null
      state.request_error_msg = null
    },
    revise_request_success(state, data) {
      state.revise_file = data
      state.request_is_sending = false
      state.request_is_success = true
      state.request_error_msg = null
    },
    revise_request_error(state, errorMsg) {
      state.revise_file = null
      state.request_is_sending = false
      state.request_is_success = false
      state.request_error_msg = errorMsg
    },
    museum_objects_request_success(state, payload) {
      state.museum_objects = payload
    },
  },
  actions: {
    fetchAllMuseumObjects({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/v1/museums-objects/',
        })
          .then(response => {
            if (response.status === 200) {
              commit('museum_objects_request_success', response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getReviseRequest({ commit }, reviseFilter) {
      commit('set_request_init')

      return new Promise((resolve, reject) => {
        const data = JSON.stringify({
          museum_id: reviseFilter.museum_id,
          period_lower: reviseFilter.period_lower,
          period_upper: reviseFilter.period_upper,
        })
        const postData = new FormData()
        postData.append('data', data)
        postData.append('action', 'revise')
        axios({
          method: 'post',
          url: '/api/v1/actions/',
          data: postData,
          headers: { 'Content-Type': 'multipart/form-data' },

          responseType: 'blob',
        })
          .then(responseBlob => {
            const reader = new FileReader()

            reader.onload = () => {
              let response = { status: true, message: '' }

              try {
                response = JSON.parse(reader.result)
                // eslint-disable-next-line no-empty
              } catch (e) {}

              if (response.success === false) {
                commit(
                  'revise_request_error',
                  response.error === 'Период превышает 3 месяца'
                    ? `${response.error} или неверно выбраны даты`
                    : response.error,
                )
              } else {
                commit('revise_request_success', responseBlob.data)
              }
            }
            reader.readAsText(responseBlob.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
