import axios from '@axios'

export default {
  namespaced: true,
  state: {
    museum_objects: [],
    request_is_success: null,
    request_error_msg: null,
    selected_museum_object: {
      countries: [],
    },
  },
  getters: {
    getMuseumObjectsList: state => state.museum_objects,
    getRequestStatus: state => state.request_is_success,
    getRequestErrorMsg: state => state.request_error_msg,
    getSelectedMuseumObject: state => state.selected_museum_object,
  },
  mutations: {
    init_selected_museum_object(state) {
      state.selected_museum_object = {
        countries: [],
      }
    },
    set_on_request_success(state, payload) {
      state.museum_objects = payload
      state.request_is_success = true
      state.request_error_msg = null
    },
    set_on_request_error(state, error) {
      state.museum_objects = []
      state.request_is_success = false
      state.request_error_msg = error
    },
    set_selected_museum_object(state, id) {
      state.selected_museum_object = state.museum_objects.find(el => el.system_id === id)
    },
  },
  actions: {
    fetchAllMuseumObjects({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/v1/museums-objects',
        })
          .then(response => {
            if (response.status === 200) {
              commit('set_on_request_success', response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMuseumObjects({ commit }, museumData) {
        return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/v1/museums-objects/?museum_id=${museumData[0]}&type=${museumData[1]}`,
        })
          .then(response => {
            if (response.status === 200) {
              commit('set_on_request_success', response.data)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMuseumObjectBySystemId(museumId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/v1/museums-objects/?system_id=${museumId}`,
        })
          .then(response => {
            if (response.status === 200) {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setSelectedMuseumObject({ commit, getters }, id) {
      return new Promise(resolve => {
        commit('set_selected_museum_object', id)
        resolve(getters.getSelectedMuseumObject)
      })
    },
  },
}
