import axios from '@axios'

export default {
    namespaced: true,
    state: {
        /**
         * is_sending - отправляется ли в данный момент заявка
         * is_show_message - показывать ли сообщение о результате отправки
         * request_is_success - успешно ли обновлена заявка
         * request_success_msg - текст сообщения о успешном обновлении заявки
         * request_error_msg - текст сообщения об ошибке
         */
        is_sending: false,
        is_show_message: false,
        request_is_success: null,
        request_success_msg: null,
        request_error_msg: null,
    },
    getters: {
        isSending: state => state.is_sending,
        isShowMessage: state => state.is_show_message,
        successMsg: state => state.request_success_msg,
        errorMsg: state => state.request_error_msg,
        isSuccess: state => state.request_is_success,
    },
    mutations: {
        start_order_sending(state) {
            state.is_sending = true
        },
        set_on_request_success(state, id) {
            state.request_success_msg = `Заявка №${id} обновлена`
            state.request_is_success = true
            state.is_sending = false
            state.is_show_message = true
            state.request_error_msg = null
        },
        close_message(state) {
            state.is_show_message = false
        },
        set_on_request_error(state, error) {
            state.request_is_success = false
            state.is_sending = false
            state.is_show_message = true
            state.request_error_msg = error
        },
    },
    actions: {
        updateOrder({ commit }, selectedOrder) {
            commit('start_order_sending')

            return new Promise((resolve, reject) => {
                const data = JSON.stringify({
                    id: selectedOrder.id,
                    refno: selectedOrder.data.refno,
                    museum_id: selectedOrder.data.museum_id,
                    pass_date: selectedOrder.data.pass_date,
                    schedule_item_id: selectedOrder.data.schedule_item_id,
                    qty_common: selectedOrder.data.qty_common,
                    qty_privileged: selectedOrder.data.qty_privileged,
                    qty_child: selectedOrder.data.qty_child,
                    persons_per_guide: selectedOrder.info.persons_per_guide,
                    country_id: selectedOrder.data.country_id,
                    guide: selectedOrder.data.guide,
                    guide_phone: selectedOrder.data.guide_phone,
                    multicast_audio_system: selectedOrder.data.multicast_audio_system,
                    ag: selectedOrder.data.ag,
                    rgc: selectedOrder.data.rgc,
                    cgc: selectedOrder.data.cgc,
                    qty_guide: selectedOrder.data.qty_guide,
                    notes: selectedOrder.data.notes,
                    museum: selectedOrder.info.museum,
                    state: selectedOrder.state,
                })
                const postData = new FormData()
                postData.append('data', data)
                postData.append('action', 'put_request')
                axios({
                        method: 'post',
                        url: '/api/v1/actions/',
                        data: postData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data.success) {
                                commit('set_on_request_success', response.data.data.result.id)
                                resolve(response)
                            } else {
                                commit('set_on_request_error', response.data.error)
                                resolve(response)
                            }
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
