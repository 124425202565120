<template>
  <v-row>
    <v-col cols="12" md="8">
        <!-- <v-row> -->
            <!-- <v-col cols="12" md="12"> -->
                <v-card class="">
                    <v-card-title>
                    {{$t('О компании')}}
                    </v-card-title>
                    <v-card-text>
                    <p>ООО «АВАНГАРД ТРАВЕЛ ГРУПП»</p>
                    <ul style="list-style-type: none; padding-left: 0;">
                        <li>{{$t('ИНН:7841027155')}}</li>
                        <li>{{$t('КПП: 784101001')}}</li>
                        <li>{{$t('ОГРН: 1157847276003')}}</li>
                        <li>{{$t('ОКПО: 25885413')}}</li>
                        <br>
                        <li>{{$t('Расчетный счет: 40702810603260000188')}}</li>
                        <li>{{$t('Банк: Филиал "Центральный" Банка ВТБ (ПАО)')}}</li>
                        <li>{{$t('БИК: 044525411')}}</li>
                        <li>{{$t('Корр. счет: 30101810145250000411')}}</li>
                        <br>
                        <li>{{$t('Юридический адрес: 191186, Санкт-Петербург г, вн.тер. г. муниципальный округ Дворцовый округ, наб Канала Грибоедова, д. 5, литера Е, помещ. Ч.ПОМ. 19-Н ОФ.405')}}</li>
                        <br>
                        <li>{{$t('Email:')}}&nbsp;<a href="mailto:info@avangard-travel.ru">info@avangard-travel.ru</a></li>
                        <li>{{$t('Телефон:')}}&nbsp;<a href="tel:(812) 640-02-28">(812) 640-02-28</a></li>
                    </ul>
                    </v-card-text>
                </v-card>
            <!-- </v-col> -->
        <!-- </v-row> -->
    </v-col>
    <v-col cols="12" md="4" class="mx-0 my-0 px-0 py-0"></v-col>

    <v-col cols="12" md="4">
        <v-card class="">
            <v-card-title>{{$t('Договор')}}</v-card-title>
            <v-card-text>
            {{$t('Вы можете посмотреть договор с ООО "Авангард Травел Групп".')}}
            </v-card-text>
            <v-card-actions class="justify-start">
            <v-btn
                outlined
                color="primary"
                class="ps-4"
                href="https://docs.google.com/document/d/1wjbJ7E08Q9ODyImQzKomEcINfFmeb8xh0BUAyKpLAb4/edit?usp=sharing"
                target="_blank"
            >
                {{$t('Открыть')}}
                <v-icon
                dark
                right
                >
                {{ icons.mdiOpenInNew }}
                </v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="12" md="4">
        <v-card class="">
            <v-card-title>{{$t('Договор оферты')}}</v-card-title>
            <v-card-text>
                {{$t('Вы можете посмотреть договор оферты с ООО "Авангард Травел Групп".')}}
            </v-card-text>
            <v-card-actions class="justify-start">
            <v-btn
                outlined
                color="primary"
                class="ps-4"
                href="https://docs.google.com/document/d/1RPGeCtQLceYBkzDcONTLsxg9b5OcQgoOAhhRIIWpdak/edit?usp=sharing"
                target="_blank"
            >
            {{$t('Открыть')}}
                <v-icon
                dark
                right
                >
                {{ icons.mdiOpenInNew }}
                </v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="12" md="8">
        <v-card>
            <v-card-title>{{$t('Политика возврата')}}</v-card-title>
            <v-card-text>
                {{$t('Вы можете посмотреть политику возврата.')}}
            </v-card-text>
            <v-card-actions class="justify-start">
            <v-btn
                outlined
                color="primary"
                class="ps-4"
                href="https://docs.google.com/document/d/1f9DC0Q0sodXVGcytCTSD9fRVK8uhU6pCsxrhnxQuRtA/edit?usp=sharing"
                target="_blank"
            >
            {{$t('Открыть')}}
                <v-icon
                dark
                right
                >
                {{ icons.mdiOpenInNew }}
                </v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
  </v-row>
</template>


<script>
import { mdiOpenInNew } from '@mdi/js'

export default {
    setup() {
        return {
            icons: {
                mdiOpenInNew,
            },
        }
    },
}
</script>
